import { messageTypes } from "./types";
import { isDefined } from "../../auxiliar/formatValidators";

export const messageReducer = (state = [], action) => {
	switch (action.type) {
		case messageTypes.add:
			const duplicate = state.find((msg) => msg.msg === action.payload.msg);
			if (duplicate) return state;
			else return [...state, action.payload];
		case messageTypes.remove:
			return state.filter((msg) => msg.id !== action.payload.id);
		case messageTypes.clean:
			return [];
		default:
			return state;
	}
};

export const dispatchMessageSave = (dispatch, payload, time = 6000) => {
	if (!isDefined(payload.msg) || !isDefined(payload.type)) return;

	const id = Date.now();
	dispatch({
		type: messageTypes.add,
		payload: {
			id: id,
			...payload,
		},
	});

	setTimeout(() => dispatchMessageRemove(dispatch, id), time);
};

export const dispatchMessageRemove = (dispatch, id) => {
	dispatch({
		type: messageTypes.remove,
		payload: {
			id: id,
		},
	});
};

export const dispatchMessageClean = (dispatch) => {
	dispatch({
		type: messageTypes.clean,
	});
};
