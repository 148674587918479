import { useRef } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

import DisplayCashRegister from "../cashRegister/DisplayCashRegister";
import CustomForm from "../general/form/CustomForm";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { onClosePopup, onOpenPopup } from "../../auxiliar/auxFunctions";
import { compulsoryPaymentValidators } from "../../auxiliar/useFormValidators";
import { postCharge } from "../../actions/charge";

const RegisterChargePopup = ({
  cashRegisterData,
  refresh,
  trigger = <button className={"btn btn-primary btn-sm"}>Cobrar</button>,
}) => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const { width: windowWidth } = useScreenSize();
  const { id: clinicId } = useSelector((state) => state.activeClinic);

  const {
    register,
    handleSubmit,
    handleSubmitWithoutValidation,
    errors,
    isSubmitting,
  } = useFormWithDisable();

  const formConfig = {
    payment_method: {
      condition: true,
      disabled: false,
      type: "payment_method_selector",
      label: "Método de pago *",
      name: "payment_method",
      validators: {
        validate: (v) =>
          v !== "select" || "Elegir un método de pago es obligatorio",
      },
    },
    paid: {
      condition: true,
      disabled: false,
      name: "paid",
      label: "Cantidad *",
      type: "number",
      placeholder: "Ejemplo: 10",
      validators: compulsoryPaymentValidators(cashRegisterData.unpaid),
      minValue: 0,
    },
  };

  return (
    <Popup
      onClose={onClosePopup(refresh)}
      onOpen={onOpenPopup()}
      ref={ref}
      modal
      position="bottom center"
      lockScroll
      trigger={trigger}
      contentStyle={{ width: windowWidth > 768 ? "50%" : "90%" }}
    >
      <div className={"p-5 bg-base-100 rounded-lg lg:min-w-max"}>
        <DisplayCashRegister data={cashRegisterData} mode={'popup'} />

        <div className={"p-3 mx-auto"}>
          <h3 className="font-bold text-lg text-primary">Nuevo pago</h3>

          <form className="mt-5 mb-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <CustomForm
                formConfig={formConfig}
                errors={errors}
                register={register}
              />
            </div>

            <div className="mt-5 flex space-x-2 sm:space-x-4">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmit(
                  postCharge,
                  clinicId,
                  cashRegisterData?.cash_register_id,
                  closeTooltip
                )}
                disabled={isSubmitting}
              >
                Confirmar
              </button>

              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(closeTooltip)}
                disabled={isSubmitting}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
};

export default RegisterChargePopup;
