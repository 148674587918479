import axios from "axios";

export const getAllClassTypes = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/group_session_types/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getClassType = (classTypeId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(classTypeId)) {
			navigate(`/unknown?entity=class_type&id=${classTypeId}`);
			return;
		}

		return axios
			.get(`/api/managers/group_session_types/${classTypeId}`)
			.then((response) => {
				return {
					...response.data.content,
					price: Number(response.data.content.price).toFixed(2),
				};
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=class_type&id=${classTypeId}`);
			});
	};
};

export const postClassType = (data, clinicId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/group_session_types/", {
				...data,
				clinic_id: clinicId,
			})
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};

export const putClassType = (data, classTypeId, disableEdit) => {
	return async () => {
		return axios
			.put(`/api/managers/group_session_types/${classTypeId}`, {
				new_info: data,
			})
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteClassType = (classTypeId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/group_session_types/${classTypeId}`)
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};
