import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { dispatchActiveClinicSave } from "../../../store/reducers/activeClinicReducer";
import { handleSubmit } from "../../../auxiliar/auxFunctions";

const ClinicSelectorDropdown = () => {
  const activeClinic = useSelector((state) => state.activeClinic);
  const clinics = useSelector((state) => state.auth.clinics);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (clinics === undefined || Object.keys(clinics).length === 0) {
    return (
      <button
        className="btn btn-ghost my-0 p-0"
        onClick={handleSubmit(navigate, "/clinic/register")}
      >
        Crear Clínica
      </button>
    );
  }

  return (
    <div className="dropdown">
      <div tabIndex={0} role="button" className="btn btn-ghost my-0 p-0">
        <div tabIndex={0} role="button" className="btn btn-ghost">
          {activeClinic.name === undefined
            ? "Selecciona una Clínica"
            : activeClinic.name}
        </div>
      </div>
      <ul
        tabIndex={0}
        className="menu menu-sm dropdown-content mt-3 z-40 p-2 shadow bg-base-300 rounded-box w-52"
      >
        {Object.keys(clinics).map((key) => {
          const clinic = clinics[key];
          return (
            <li
              key={key}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/clinic/${key}`);
                dispatchActiveClinicSave(
                  dispatch,
                  key,
                  clinic.name,
                  clinic.status
                );
              }}
            >
              <a>{clinic.name}</a>
            </li>
          );
        })}
        <li /> {/* separator */}
        <li onClick={handleSubmit(navigate, "/clinic/register")}>
          <a>Crear Clínica</a>
        </li>
      </ul>
    </div>
  );
};

export default ClinicSelectorDropdown;
