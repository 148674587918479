import { Navigate } from "react-router-dom";

const Maintenance = () => {
    if (process.env.REACT_APP_MAINTENANCE_ONGOING === "false") {
        return <Navigate to={`/login`} replace />
    }

    return <div className="max-w-4xl m-10 md:my-20 p-10 mx-auto bg-base-100 text-center bg-base-200 shadow-xl rounded-lg">

        <p className="text-4xl my-5 text-primary">
            ¡Nuestra página está en el fisio!
        </p>

        <div className="flex flex-col gap-2 text-center items-center">
            <div>
                <img src={require("../../media/icon/icon_125.png")} alt={"Logo"} />
            </div>

            <h1 className="text-bold text-2xl tracking-tight">
                MiFisio Agenda
            </h1>
        </div>


        <p className="text-xl my-5 text">
            MiFisio Agenda está recibiendo una sesión de fisioterapia para mejorar su rendimiento.
            El equipo está trabajando para que esté de vuelta con una mejor versión de sí misma.
        </p>
        <p className="text-xl my-5 text">
            Este mantenimiento estaba planeado y todo está bajo control, pero para cualquier duda
            ponte en contacto con nosotros en {" "} <a className="link" href="mailto:soporte@mifisioagenda.com">
                soporte@mifisioagenda.com
            </a>
        </p>
        <p className="text-xl my-5 text">
            ¡Vuelve pronto para ver los resultados!
        </p>
    </div>
}

export default Maintenance;