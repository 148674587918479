import { loginTypes } from "./types";
import { dispatchActiveClinicClean } from "./activeClinicReducer";
import { dispatchCookiesClean } from "./cookiesReducer";

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case loginTypes.login:
			return action.payload;
		case loginTypes.logout:
			return {};
		default:
			return state;
	}
};

export const dispatchLogin = (dispatch, content) => {
	dispatch({
		type: loginTypes.login,
		payload: {
			id: content.id,
			displayName: content.name,
			user_type: content.user_type,
			clinics: content.clinics,
		},
	});
};

export const dispatchLogout = (dispatch) => {
	document.getElementById("overlay").style.display = "none";
	dispatchActiveClinicClean(dispatch);
	dispatchCookiesClean(dispatch);
	dispatch({
		type: loginTypes.logout,
	});
};
