import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormSelector from "./FormSelector";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { getAllPhysiotherapists, getPublicPhysiotherapists } from "../../../actions/physiotherapist";

const CustomPhysioSelector = ({ customKey, register, config, errors }) => {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const clinicId = useSelector((state) => state.activeClinic.id);
  const [physios, setPhysios] = useState({ content: {}, order: [], waiting: true });

  useEffect(() => {
    fetchData(
      dispatch,
      params.has("clinic") ? getPublicPhysiotherapists : getAllPhysiotherapists,
      {
        clinic_id: params.get("clinic") || clinicId,
        order: "name",
        per_page: 1000,
        page: 1,
        ...(config.defaultValue && !["select", "all"].includes(config.defaultValue) && { selected: config.defaultValue }),
      },
      setPhysios
    );
  }, []);

  const checkPhysioSelected = (value) => {
    if (config.allowAll || config.name.includes('-tmp')) return;
    if (!isDefined(value) || value === "select") {
      return "El fisioterapeuta es obligatorio";
    }
  };

  const options = [
    ...(config.allowAll
      ? [{ key: "all", label: "Todos" }]
      : !isDefined(config.defaultValue) || config.defaultValue === "select"
        ? [{ key: "select", label: "Selecciona un fisioterapeuta" }]
        : []),
    ...(physios?.order
      ? physios?.order?.map((id) => ({
        key: id.toString(),
        label: physios?.content?.[id]?.display_name,
      }))
      : []),
  ];

  if (physios.waiting)
    return <div key={customKey + '-tmp'}>
      <FormSelector
        customKey={customKey + '-tmp'}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled,
          type: "selector",
          name: config.name + '-tmp',
          label: config.label,
          info: config.info,
          options: [{ key: config.defaultValue || options[0]?.key, label: "Cargando fisioterapeutas..." }],
          defaultValue: "loading",
        }}
        errors={undefined}
      />
    </div>;

  else
    return (
      <FormSelector
        customKey={customKey}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled,
          type: "selector",
          name: config.name,
          label: config.label,
          info: config.info,
          options: options,
          defaultValue: config?.defaultValue?.toString() || (config.allowAll ? "all" : "select"),
          validators: { validate: checkPhysioSelected },
        }}
        errors={errors}
      />
    );
};

export default CustomPhysioSelector;
