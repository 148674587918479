import axios from "axios";

import { dispatchLogin, dispatchLogout } from "../store/reducers/authReducer";
import { dispatchActiveClinicSave } from "../store/reducers/activeClinicReducer";
import { dispatchMessageSave } from "../store/reducers/messageReducer";

export const registerManager = (data) => {
	let req_data = JSON.parse(JSON.stringify(data));
	delete req_data.password_rep;

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/register", req_data)
			.then((response) => {
				if (response.status === 201) {
					navigate("/login?variant=fromRegister");
				}
			})
			.catch(() => { });
	};
};

export const login = (data) => {
	return async (...args) => {
		const dispatch = args[0];
		const { navigate } = args[2];

		return axios
			.post("/api/managers/login", data)
			.then((response) => {
				dispatchLogin(dispatch, response.data.content);
				const clinics = response.data.content.clinics;
				const clinicKeys = Object.keys(clinics);

				if (clinicKeys.length > 0) {
					const clinicId = clinicKeys[0];
					const clinic = clinics[clinicId];
					navigate(`/clinic/${clinicId}`);

					dispatchActiveClinicSave(
						dispatch,
						clinicId,
						clinic.name,
						clinic.status
					);
				}
			})
			.catch((error) => {
				if (
					error?.response?.data?.msg ===
					"Comprueba tu bandeja de entrada y confirma tu correo electrónico para poder iniciar sesión." &&
					window.confirm(
						"Todavía no has validado tu correo electrónico, por lo que el inicio de sesión está bloqueado ¿quieres que te reenviemos el correo de confirmación?"
					)
				) {
					axios
						.post("/api/managers/resend_validation_email", data)
						.then((r) =>
							dispatchMessageSave(dispatch, {
								msg: "Correo de confirmación reenviado, comprueba tu bandeja de entrada.",
								type: "info",
							})
						)
						.catch(() => { });
				}
			});
	};
};

export const logout = () => {
	return async (dispatch) => {
		return axios
			.post("/api/managers/logout")
			.then(() => {
				dispatchLogout(dispatch);
			})
			.catch(() => { });
	};
};

export const requestNewPassword = (data) => {
	return async (...args) => {
		const dispatch = args[0];
		const { navigate } = args[2];

		return axios
			.post("/api/managers/request_new_password", data)
			.then(() => {
				navigate("/login");
				dispatchMessageSave(dispatch, {
					msg: "Correo con instrucciones enviado con éxito, comprueba tu bandeja de entrada",
					type: "info",
				});
			})
			.catch(() => { });
	};
};
