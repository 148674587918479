import CustomDayScheduleSelector from "./CustomDayScheduleSelector";

const CustomWeeklyScheduleSelector = ({ register, config, errors }) => {
    return (
        <>
            <h3 className={`text-primary text-xl mt-2 ${config.className}`}>Horario</h3>

            <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-7 gap-3">
                <CustomDayScheduleSelector
                    key={'monday'}
                    customKey={'monday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Lunes",
                        name: "monday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'tuesday'}
                    customKey={'tuesday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Martes",
                        name: "tuesday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'wednesday'}
                    customKey={'wednesday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Miércoles",
                        name: "wednesday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'thursday'}
                    customKey={'thursday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Jueves",
                        name: "thursday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'friday'}
                    customKey={'friday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Viernes",
                        name: "friday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'saturday'}
                    customKey={'saturday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Sábado",
                        name: "saturday",
                    }}
                    errors={errors}
                />
                <CustomDayScheduleSelector
                    key={'sunday'}
                    customKey={'sunday'}
                    register={register}
                    config={{
                        ...config,
                        label: "Domingo",
                        name: "sunday",
                    }}
                    errors={errors}
                />
            </div>
        </>
    )
}

export default CustomWeeklyScheduleSelector;