import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { handleSubmitWithDispatch } from "../../auxiliar/auxFunctions";
import {
  sendInvoiceEmail,
  getInvoice,
  downloadInvoice,
} from "../../actions/invoice";

const DisplayInvoice = ({ invoiceId }) => {
  const { register, fetchFormValues, errors } = useFormWithDisable();

  const formConfig = {
    concept: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Concepto",
      name: "concept",
    },
    recipient_name: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Pagador",
      name: "recipient_name",
    },
    issue_date: {
      condition: true,
      disabled: true,
      type: "date",
      label: "Fecha de emisión",
      name: "issue_date",
    },
    invoice_code: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Código de factura",
      name: "invoice_code",
    },
    total_paid: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Pagado",
      name: "total_paid",
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceId) fetchFormValues(getInvoice, invoiceId);
  }, [invoiceId]);

  return (
    <div className={"sm:container p-3 mx-auto my-5"}>
      <h2 className="text-primary text-2xl">Factura</h2>
      <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
        <CustomForm
          formConfig={formConfig}
          errors={errors}
          register={register}
        />
      </form>

      <div className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmitWithDispatch(
            dispatch,
            downloadInvoice,
            invoiceId
          )}
        >
          Descargar Factura
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmitWithDispatch(
            dispatch,
            sendInvoiceEmail,
            invoiceId
          )}
        >
          Enviar Factura{" "}
          <div
            className="tooltip flex items-center"
            data-tip={"La factura se enviará al correo asociado al paciente"}
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default DisplayInvoice;
