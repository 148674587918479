export const handleSubmit =
	(actionCreator, ...args) =>
		(e) => {
			e?.preventDefault();
			actionCreator(...args);
		};

export const handleSubmitWithDispatch =
	(dispatch, actionCreator, ...args) =>
		(e) => {
			e?.preventDefault();
			dispatch(actionCreator(...args));
		};

export const fetchData = async (dispatch, fetcher, filter, setValues) => {
	const defaultValues = await dispatch(fetcher(filter));
	setValues({
		...defaultValues,
		waiting: false,
	});
};

export const onClosePopup = (onClose) => () => {
	document.getElementById("overlay").style.display = "none";

	if (onClose) setTimeout(() => onClose(), 200);
};

export const onOpenPopup = () => () => {
	document.getElementById("overlay").style.display = "block";
};

export const getDynamicLink = (prefix, path, params) => {
	if (params) {
		const queryString = Array.from(params.entries())
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join('&');
		return `${prefix}/${path}?${queryString}`;
	}
	return `${prefix}/${path}`;
};

// Debounce function to avoid multiple requests
export const debounce = (func, timeout = 300) => {
	let timer;

	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => { func(...args) }, timeout);
	};
};