import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

import { getAllClinics, getClinicPaymentGatewayParams } from "../../../actions/clinics";
import { dispatchActiveClinicSave } from "../../../store/reducers/activeClinicReducer";
import { handleSubmit, fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const OverviewClinics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: managerId } = useSelector((state) => state.auth);
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);

  const [clinics, setClinics] = useState({ waiting: true });
  const [paymentData, setPaymentData] = useState({ waiting: true });

  useEffect(() => {
    fetchData(dispatch, getAllClinics, { manager_id: managerId }, setClinics);
  }, [dispatch, setClinics, managerId]);

  // Handles the payment gateway redirection
  useEffect(() => {
    if (!paymentData.waiting) {
      setTimeout(() => document.getElementById('paymentForm').submit(), 200);
    }
  }, [paymentData]);

  const handleClinicSelect = (clinicId, clinicName, clinicStatus) => {
    navigate(`/clinic/${clinicId}`);
    dispatchActiveClinicSave(dispatch, clinicId, clinicName, clinicStatus);
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl my-3">Clínicas</h2>

      {clinics.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(clinics.content) ||
        Object.keys(clinics.content).length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay clínicas registradas, añade una para empezar a trabajar con
          MiFisio Agenda.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th className="hidden xs:table-cell">Estado</th>
                <th className="hidden sm:table-cell">Pago</th>
                <th className="hidden md:table-cell">Plan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {Object.keys(clinics.content).map((clinicId) => {
                const clinic = clinics.content[clinicId];
                let payment = "Pagado";
                let infoPayment = "Pago mensual realizado";
                if (!clinic.paid) {
                  if (!clinic.active) {
                    payment = "Rechazado";
                    infoPayment =
                      "Pago mensual rechazado. Realiza el pago para activar la clínica";
                  } else {
                    payment = "Pendiente";
                    infoPayment =
                      "Pago mensual rechazado. Reintentaremos el pago en los próximos días";
                  }
                }

                return (
                  <tr key={clinicId} id={clinicId}>
                    <td>{clinic.name}</td>
                    <td className="hidden xs:table-cell">
                      {clinic.active ? "Activa" : "Inactiva"}
                    </td>

                    <td className="hidden sm:table-cell">
                      <div>
                        {payment}{" "}
                        <div className="tooltip" data-tip={infoPayment}>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="text-primary"
                          />
                        </div>
                        <button
                          className="tooltip btn btn-sm btn-ghost"
                          data-tip={
                            "Haz click aqui para abrir la pasarela de pago"
                          }
                          onClick={handleSubmit(
                            fetchData,
                            dispatch,
                            getClinicPaymentGatewayParams,
                            { clinic_id: clinicId },
                            setPaymentData
                          )}
                        >
                          <FontAwesomeIcon
                            icon={faCartShopping}
                            className="text-secondary"
                          />
                        </button>
                      </div>
                    </td>

                    <td className="hidden md:table-cell">
                      {clinic.payment_plan.type === "full"
                        ? "Completo"
                        : "Colegiado"}
                    </td>

                    {clinicId !== activeClinicId ? (
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={handleSubmit(
                            handleClinicSelect,
                            clinic.clinic_id.toString(),
                            clinic.name,
                            clinic.status
                          )}
                        >
                          Seleccionar
                        </button>
                      </td>
                    ) : (
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={handleSubmit(
                            navigate,
                            `/clinic/${clinicId}`
                          )}
                        >
                          Detalles
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <form className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmit(navigate, "/clinic/register")}
        >
          Registrar clínica
        </button>
      </form>

      <div className="col-12 container-fluid" hidden>
        <form name="form" id="paymentForm" action={paymentData.path} method="POST">
          <input name="Ds_SignatureVersion" value={paymentData?.params?.Ds_SignatureVersion} />
          <input name="Ds_MerchantParameters" value={paymentData?.params?.Ds_MerchantParameters} />
          <input name="Ds_Signature" value={paymentData?.params?.Ds_Signature} />
          <button className="btn btn-dark" value="Submit">Enviar formulario</button>
        </form>
      </div>
    </div>
  );
};

export default OverviewClinics;
