import { activeClinicTypes } from "./types";

export const activeClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case activeClinicTypes.save:
			return action.payload;
		case activeClinicTypes.clean:
			return {};
		default:
			return state;
	}
};

export const dispatchActiveClinicSave = (dispatch, id, name, status) => {
	dispatch({
		type: activeClinicTypes.save,
		payload: {
			id: id,
			name: name,
			status: status,
		},
	});
};

export const dispatchActiveClinicClean = (dispatch) => {
	dispatch({
		type: activeClinicTypes.clean,
	});
};
