import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import CustomForm from "../../../components/general/form/CustomForm";
import { checkMailFormat } from "../../../auxiliar/formatValidators";
import { login } from "../../../actions/auth";
import { resetPassword, validateManager } from "../../../actions/manager";

const Login = () => {
  const { register, handleSubmit, errors, isSubmitting } = useFormWithDisable();

  const dispatch = useDispatch();
  const params = useQueryParams();

  // Check if the user is trying to reset the password
  useEffect(() => {
    const code = params.get("reset_pwd_code");

    if (code)
      dispatch(resetPassword(code));
  }, [params])

    // Check if the user is trying to validate the email
    useEffect(() => {
      const code = params.get("validate_email_code");
      const manager = params.get("validate_email_manager");
  
      if (code && manager)
        dispatch(validateManager(manager, code));
    }, [params])

  const formConfig = {
    type: {
      condition: true,
      type: "selector",
      name: "type",
      label: "Tipo de usuario *",
      options: [
        { key: "manager", label: "Mánager" },
        { key: "clinic", label: "Clínica" },
      ],
      defaultValue: "manager",
      validators: {
        required: "El tipo de usuario es obligatorio",
      },
    },
    email: {
      condition: true,
      type: "text",
      label: "Correo Electrónico *",
      placeholder: "fisio@email.com",
      name: "email",
      validators: {
        required: "El correo es obligatorio",
        validate: checkMailFormat,
      },
    },
    password: {
      condition: true,
      type: "password",
      name: "password",
      placeholder: "********",
      label: "Contraseña *",
      validators: {
        required: "La contraseña es obligatoria",
      },
    },
  };

  const texts = {
    base: {
      title: "¡Bienvenido de nuevo!",
      paragraph:
        "Si tienes alguna sugerencia de mejora, no dudes en ponerte en contacto con nosotros.\n¡Estamos deseando escucharte!",
    },
    fromRegister: {
      title: "¡Bienvenido!",
      paragraph:
        "Gracias por registrarte en MiFisio. Confirma tu correo eléctronico siguiendo las instrucciones que has recibido por email.",
    },
  };

  return (
    <div className="flex my-10 lg:my-10 justify-center">
      <div className="bg-base-200 shadow-xl rounded-lg p-10 max-w-md">
        <h2 className="card-title justify-center text-primary">
          {texts[params.get("variant")]?.title || texts.base.title}
        </h2>

        <p className="text-center my-5">
          {texts[params.get("variant")]?.paragraph || texts.base.paragraph}
        </p>

        <form className="grid grid-cols-1 gap-2 items-center my-5">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />

          <div className="card-actions justify-center mt-3">
            <button
              className="btn btn-primary"
              onClick={handleSubmit(login)}
              disabled={isSubmitting}
              id="submit"
            >
              Iniciar Sesión
            </button>
          </div>

          <div className="mt-6 text-center">
            <Link to="/register" className="link">
              ¿Todavía no tienes cuenta?
            </Link>
          </div>
          <div className="text-center">
            <Link to="/forgotten-password" className="link">
              ¿Has olvidado tu contraseña?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
