import { useEffect } from "react";
import { Link } from "react-router-dom";

import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../form/CustomForm";
import { registerManager } from "../../../actions/auth";
import {
  checkMailFormat,
  checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";

const RegisterForm = () => {
  const { register, handleSubmit, watch, setValue, errors, isSubmitting } =
    useFormWithDisable();

  const password = watch("password");
  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.has("referral_code")) {
      setValue("referral_code", queryParams.get("referral_code"));
    }
    if (queryParams.has("promo_code")) {
      setValue("promo_code", queryParams.get("promo_code"));
    }
  }, [queryParams, setValue]);

  const formConfig = {
    name: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    surnames: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Apellidos *",
      placeholder: "Apellidos",
      name: "surnames",
      validators: { required: "Los apellidos son obligatorios" },
    },
    email: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Correo Electrónico *",
      placeholder: "fisio@email.com",
      name: "email",
      validators: {
        required: "El correo es obligatorio",
        validate: checkMailFormat,
      },
    },
    phone_number: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Número de teléfono",
      placeholder: "+34 XXX XXX XXX",
      name: "phone_number",
      validators: {
        validate: checkPhoneNumberFormat,
      },
    },
    password: {
      condition: true,
      disabled: false,
      type: "password",
      label: "Contraseña *",
      placeholder: "********",
      name: "password",
      info: "La contraseña debe tener al menos 8 caracteres",
      validators: {
        required: "La contraseña es obligatoria",
        minLength: {
          value: 8,
          message: "La contraseña debe tener al menos 8 caracteres",
        },
      },
    },
    password_rep: {
      condition: true,
      disabled: false,
      type: "password",
      label: "Repite la contraseña *",
      placeholder: "********",
      name: "password_rep",
      validators: {
        required: "La contraseña es obligatoria",
        minLength: {
          value: 8,
          message: "La contraseña debe tener al menos 8 caracteres",
        },
        validate: (value) =>
          value === password || "Las contraseñas no son iguales",
      },
    },
    promo_code: {
      condition: !queryParams.has("referral_code"),
      disabled: queryParams.has("promo_code"),
      type: "text",
      label: "Código promocional",
      placeholder: "ejemplo_2024",
      name: "promo_code",
    },
    referral_code: {
      condition: queryParams.has("referral_code"),
      disabled: true,
      type: "text",
      label: "Código de referido",
      placeholder: "ejemplo_2024",
      name: "referral_code",
    },
    dummy: {
      condition: true,
      type: "dummy",
    },
    terms_of_use: {
      condition: true,
      disabled: false,
      label: "He leído y acepto la Política de Privacidad",
      type: "checkbox",
      name: "terms_of_use",
      validators: { required: "Debes aceptar la política de privacidad" },
    },
  };

  return (
    <form className="my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <CustomForm
          formConfig={formConfig}
          errors={errors}
          register={register}
        />
      </div>

      <div className="card-actions justify-center mt-3">
        <button
          className="btn btn-primary"
          onClick={handleSubmit(registerManager)}
          disabled={isSubmitting}
          id="submit"
        >
          Registrar usuario
        </button>
      </div>

      <div className="mt-6 text-center">
        <Link to="/login" className="link">
          ¿Ya estás registrado?
        </Link>
      </div>
      <div className="mt-2 text-center">
        <Link to="/privacy-policy" target="_blank" className="link">
          Política de privacidad
        </Link>
      </div>
    </form>
  );
};

export default RegisterForm;
