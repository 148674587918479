import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import {
  getClassType,
  putClassType,
  deleteClassType,
} from "../../../actions/classTypes";
import {
  priceValidators,
  sessionLengthValidators,
} from "../../../auxiliar/useFormValidators";

const EditClassType = () => {
  const modalId = "delete-group-session-type-modal";
  const {
    register,
    handleSubmit,
    handleSubmitWithoutValidation,
    fetchFormValues,
    resetFetchedValues,
    openModal,
    errors,
    isSubmitting,
  } = useFormWithDisable(modalId);

  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { group_session_type_id: classTypeId } = useParams();

  useEffect(() => {
    if (classTypeId) fetchFormValues(getClassType, classTypeId);
  }, [classTypeId]);

  useEffect(() => {
    if (!isEditing && classTypeId) resetFetchedValues();
  }, [isEditing]);

  const formConfig = {
    name: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    number_participants: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Número de asistentes *",
      name: "number_participants",
    },
    session_duration: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Duración de la sesión *",
      info: "Duración de la sesión en minutos",
      name: "session_duration",
    },
    price: {
      condition: true,
      disabled: !isEditing,
      type: "number",
      label: "Precio de la sesión *",
      placeholder: "Ejemplo: 30",
      name: "price",
      info: "Precio de la sesión en euros",
      validators: priceValidators,
      minValue: 0,
    },
    color: {
      condition: true,
      disabled: !isEditing,
      type: "color_selector",
      label: "Color *",
      info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este tipo de sesión.",
      name: "color",
    },
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Editar Tipo de Clase</h2>
      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          {!isEditing ? (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, true)}
                disabled={isSubmitting}
              >
                Editar
              </button>
              <button
                className="btn btn-error btn-sm"
                onClick={openModal}
                disabled={isSubmitting}
              >
                Eliminar
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(navigate, -1)}
                disabled={isSubmitting}
              >
                Atrás
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmit(
                  putClassType,
                  classTypeId,
                  handleSubmitWithoutValidation(setIsEditing, false)
                )}
                disabled={isSubmitting}
              >
                Guardar
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                disabled={isSubmitting}
              >
                Cancelar
              </button>
            </>
          )}
        </div>
      </form>

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar todos los datos del tipo de clase?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithoutValidation(
          deleteClassType,
          classTypeId
        )}
      />
    </div>
  );
};

export default EditClassType;
