import { useEffect, useState } from "react";

import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { getPublicClinic } from "../../../actions/clinics";
import { isDefined } from "../../../auxiliar/formatValidators";

const DisplayClinicBookings = () => {
    const {
        register,
        fetchFormValues,
        watch,
        errors,
        setValue,
        originalValues
    } = useFormWithDisable();

    const queryParams = useQueryParams()
    const [clinicId, setClinicId] = useState()
    const name = watch("name")
    const logo = watch("logo")

    const formConfig = {
        name: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Nombre *",
            placeholder: "Nombre",
            name: "name",
        },
        email: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Correo Electrónico *",
            placeholder: "clinica@email.com",
            name: "email",
        },
        landline_phone: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Número de teléfono fijo",
            placeholder: "+34 XXX XXX XXX",
            name: "landline_phone",
        },
        mobile_phone: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Número de teléfono móvil",
            placeholder: "+34 XXX XXX XXX",
            name: "mobile_phone",
        },
        address: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Dirección *",
            placeholder: "Calle, número",
            name: "address",
        },
        town: {
            condition: true,
            disabled: true,
            type: "text",
            label: "Ciudad",
            placeholder: "Ciudad",
            name: "town",
        },
        schedule: {
            condition: true,
            disabled: true,
            type: "schedule",
            watch: watch,
            setValue: setValue,
            originalValues: originalValues?.schedule,
        },
    };

    useEffect(() => {
        if (queryParams)
            setClinicId(queryParams.get("clinic"))
    }, [queryParams])

    useEffect(() => {
        if (clinicId) fetchFormValues(getPublicClinic, { clinic_id: clinicId });
    }, [clinicId]);

    return <div className="sm:container p-3 mx-auto my-5">

        <h2 className="text-primary text-2xl">Clínica {name}</h2>

        <form className="mt-5 mb-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-5">
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />
            </div>
        </form>
        {
          isDefined(logo) &&
          <div className="p-5 flex items-center justify-center">
              <img className="object-cover h-48 mx-auto" src={logo} />
          </div>
        }
    </div>
}

export default DisplayClinicBookings