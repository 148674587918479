import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import {
  downloadInvoiceTemplate,
  putInvoiceTemplate,
  getInvoiceTemplate,
} from "../../actions/invoice";
import { maxLength } from "../../auxiliar/formatValidators";
import { useEffect } from "react";

const InvoiceTemplate = ({ clinicId, close }) => {
  const {
    register,
    handleSubmit,
    fetchFormValues,
    watch,
    errors,
    isSubmitting,
  } = useFormWithDisable();

  const vertical = watch("vertical_text");
  const gdpr = watch("gdpr_text");
  const footer = watch("footer");

  const formConfig = {
    vertical_text: {
      condition: true,
      disabled: false,
      name: "vertical_text",
      info: `Maximo 150 caracteres. Actualmente: ${vertical?.length || 0}/150`,
      label: "Texto vertical",
      type: "textarea",
      validators: {
        validate: maxLength(150),
      },
    },
    gdpr_text: {
      condition: true,
      disabled: false,
      name: "gdpr_text",
      info: `Maximo 1000 caracteres. Actualmente: ${gdpr?.length || 0}/1000`,
      label: "Texto principal",
      type: "textarea",
      validators: {
        validate: maxLength(1000),
      },
    },
    footer: {
      condition: true,
      disabled: false,
      name: "footer",
      info: `Maximo 200 caracteres. Actualmente: ${footer?.length || 0}/200`,
      label: "Pie de página",
      type: "textarea",
      validators: {
        validate: maxLength(200),
      },
    },
  };

  useEffect(() => {
    fetchFormValues(getInvoiceTemplate, clinicId);
  }, [clinicId]);

  return (
    <div className="mt-5 mb-12">
      <h3 className="text-primary text-xl">Plantilla para facturas</h3>

      <p className="lg:text-lg my-3">
        Puedes editar los textos que aparecen en las facturas generadas por la
        aplicación en el siguiente formulario. Se pueden editar tres campos:
        texto vertical, texto principal y pie de página.
      </p>

      <p className="lg:text-lg my-3">
        Para ver como quedaría la factura puedes descargar un ejemplo, lo que
        mostrará un PDF con los textos editados. No te olvides de guardar los
        cambios cuando estes contento con el resultado. Si quieres más
        información sobre como editar la plantilla puedes acceder al{" "}
        <a
          className="link"
          href={"/docs/invoice-template"}
          target={"_blank"}
        >
          siguiente tutorial
        </a>
        .
      </p>

      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 gap-5">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(putInvoiceTemplate, clinicId, close)}
            disabled={isSubmitting}
          >
            Confirmar
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(downloadInvoiceTemplate, clinicId)}
            disabled={isSubmitting}
          >
            Descargar Ejemplo
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={close}
            disabled={isSubmitting}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default InvoiceTemplate;
