import EditTask from "../../../components/task/EditTask";
import {useParams} from "react-router-dom";

const TaskCard = () => {
    const {task_id: taskId} = useParams();

    return (
        <div>
            <EditTask taskId={taskId}/>
        </div>
    );
}

export default TaskCard;