import axios from "axios";
import { pick } from "lodash";

export const postPatientAttendsClass = (data, update) => {
	return async () => {
		return axios
			.post(`/api/managers/patient_attends_group_sessions/`, data)
			.then(() => {
				update()
			}).catch(() => { })
	}
}

export const deletePatientFromClass = (classId, patientId, update, recSessions = false) => {
	return async () => {
		return axios.delete(`/api/managers/patient_attends_group_sessions/group_session/${classId}/patient/${patientId}?deleteRecurrent=${recSessions}`)
			.then(() => {
				update()
			}).catch(() => { })
	}
}


export const putPatientAttendsClass = (data, update) => {
	let paymentData = {}
	if (data.payment_type_new === "voucher") {
		paymentData = pick(data, ["acquired_voucher_id"]);
	} else {
		paymentData = pick(data, ["discount", "paid", "payment_method"]);
	}
	return async () => {
		return axios.put(
			`/api/managers/patient_attends_group_sessions/group_session/${data.group_session_id}/patient/${data.patient_id}`,
			{ new_info: paymentData }
		).then(() => {
			update()
		}).catch(() => { })
	}
}