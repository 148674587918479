import { useState } from "react";
import { useSelector } from "react-redux";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllClasses } from "../../../../actions/class";
import { isDefined } from "../../../../auxiliar/formatValidators";

const OverviewClassesInPatient = ({ acquiredVoucherId }) => {
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);
  const [classes, setClasses] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    clinic_id: activeClinicId,
    acquired_voucher_id: acquiredVoucherId,
    order: "date",
    per_page: 5,
    page: 1,
  });

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllClasses,
    setClasses,
    setFilter
  );

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Clases</h2>

      {classes.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(classes.order) ||
        !isDefined(classes.content) ||
        classes.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay clases registradas, añade el paciente a las clases desde la
          agenda.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Fecha</th>
                <th className="hidden xs:table-cell">Horario</th>
                <th className="hidden md:table-cell">Fisioterapeuta</th>
                <th className="hidden sm:table-cell">Tipo</th>
              </tr>
            </thead>

            <tbody>
              {classes.order.map((classId) => {
                const c = classes.content && classes.content[classId];

                return (
                  <tr
                    key={classId}
                    className={c.unpaid ? "bg-light-error" : ""}
                  >
                    <td>{c.patient_name}</td>
                    <td>{c.start_datetime.substring(0, 10)}</td>
                    <td className="hidden sm:table-cell">
                      {c.start_datetime.substring(11, 16)}
                      {"-"}
                      {c.finish_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden xs:table-cell sm:hidden">
                      {c.start_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden md:table-cell">
                      {c.physio_name}
                    </td>
                    <td className="hidden sm:table-cell">{c.type}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <form className="mt-5 flex justify-between">
        <PaginatorComponent
          pagination={classes.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </form>
    </div>
  );
};

export default OverviewClassesInPatient;
