import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import {
  getVoucher,
  putVoucher,
  deleteVoucher,
} from "../../../actions/voucher";
import {
  priceValidators,
  sessionsNumberValidators,
} from "../../../auxiliar/useFormValidators";

const EditVoucher = () => {
  const modalId = "delete-voucher-type-modal";
  const {
    register,
    handleSubmit,
    handleSubmitWithoutValidation,
    fetchFormValues,
    resetFetchedValues,
    openModal,
    watch,
    errors,
    isSubmitting,
  } = useFormWithDisable(modalId);

  const navigate = useNavigate();
  const type = watch("group_indiv");
  const [isEditing, setIsEditing] = useState(false);
  const { voucher_type_id: voucherTypeId } = useParams();

  useEffect(() => {
    if (voucherTypeId) fetchFormValues(getVoucher, voucherTypeId);
  }, [voucherTypeId]);

  useEffect(() => {
    if (!isEditing && voucherTypeId) resetFetchedValues();
  }, [isEditing]);

  const formConfig = {
    group_indiv: {
      condition: true,
      disabled: true,
      type: "selector",
      name: "group_indiv",
      label: "Tipo de bono *",
      options: [
        { key: "individual", label: "Sesiones individuales" },
        { key: "group", label: "Clases" },
      ],
      defaultValue: "individual",
      validators: { required: "El tipo de bono es obligatorio" },
    },
    session_type_id: {
      condition: type === "individual" || type === undefined,
      disabled: true,
      type: "session_type_selector",
      name: "session_type_id",
      label: "Tipo de sesión *",
    },
    group_session_type_id: {
      condition: type === "group",
      disabled: true,
      type: "group_session_type_selector",
      name: "group_session_type_id",
      label: "Tipo de clase *",
    },
    name: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    total_sessions: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Número de sesiones *",
      name: "total_sessions",
    },
    price: {
      condition: true,
      disabled: !isEditing,
      type: "number",
      label: "Precio del bono *",
      placeholder: "Ejemplo: 100",
      name: "price",
      info: "Precio del bono en euros",
      validators: priceValidators,
      minValue: 0,
    },
    months_validity: {
      condition: true,
      disabled: !isEditing,
      type: "number",
      label: "Meses de validez *",
      placeholder: "Ejemplo: 12",
      name: "months_validity",
      info: "Desde el día de la compra, cuando hayan pasado estos meses, el bono del paciente expirará.",
      minValue: 1,
      validators: {
        required: "Los meses de validez son obligatorios.",
        pattern: {
          value: /^\-{0,1}\d+?$/,
          message:
            "El número de meses de validez debe ser un número sin decimales",
        },
        validate: (v) =>
          Number(v) >= 0 || "Los meses de validez deben ser mayor que 0",
      },
    },
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Editar Tipo de Bono</h2>
      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          {!isEditing ? (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, true)}
                disabled={isSubmitting}
              >
                Editar
              </button>
              <button
                className="btn btn-error btn-sm"
                onClick={openModal}
                disabled={isSubmitting}
              >
                Eliminar
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(navigate, -1)}
                disabled={isSubmitting}
              >
                Atrás
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmit(
                  putVoucher,
                  voucherTypeId,
                  handleSubmitWithoutValidation(setIsEditing, false)
                )}
                disabled={isSubmitting}
              >
                Guardar
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                disabled={isSubmitting}
              >
                Cancelar
              </button>
            </>
          )}
        </div>
      </form>

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar todos los datos del tipo de bono?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithoutValidation(deleteVoucher, voucherTypeId)}
      />
    </div>
  );
};

export default EditVoucher;
