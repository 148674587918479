import axios from "axios";

export const getAllVouchers = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/voucher_types/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getVoucher = (voucherId, deleted = true) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(voucherId)) {
			navigate(`/unknown?entity=voucher&id=${voucherId}`);
			return;
		}

		return axios
			.get(`/api/managers/voucher_types/${voucherId}?exclude_deleted=${deleted}`)
			.then((response) => {
				return {
					...response.data.content,
					price: Number(response.data.content.price).toFixed(2),
					group_indiv: response.data.content.group_session_type_id
						? "group"
						: "individual",
				};
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=voucher&id=${voucherId}`);
			});
	};
};

export const postVoucher = (data, clinicId) => {
	if (data.session_type_id === "select") {
		delete data.session_type_id;
	} else if (data.group_session_type_id === "select") {
		delete data.group_session_type_id;
	}
	delete data.group_indiv

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/voucher_types/", { ...data, clinic_id: clinicId })
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};

export const putVoucher = (data, voucherId, disableEdit) => {
	return async () => {
		return axios
			.put(`/api/managers/voucher_types/${voucherId}`, { new_info: data })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteVoucher = (voucherId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/voucher_types/${voucherId}`)
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};
