import Hero from "../../../components/general/aboutUs/Hero";
import Partners from "../../../components/general/aboutUs/Partners";

const AboutUsBookings = () => {
    return (
        <div>
            <Hero variant="patient" />
            <Partners />
        </div>
    )
}

export default AboutUsBookings;