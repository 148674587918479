import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import PaymentPlanDisplay from "../../../components/clinic/PaymentPlanDisplay";
import ShowClinicBookings from "../../../components/clinic/ShowClinicBookings";
import UpdateClinicPassword from "../../../components/clinic/UpdateClinicPassword";
import InvoiceTemplate from "../../../components/clinic/InvoiceTemplate";
import EditLogo from "../../../components/clinic/EditLogo";
import ScheduleSelector from "../../../components/clinic/ScheduleSelector";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import {
  deleteClinic,
  putClinic,
  getClinic,
} from "../../../actions/clinics";
import {
  checkCIFFormat,
  checkMailFormat,
  checkPhoneNumberFormat,
  isDefined,
} from "../../../auxiliar/formatValidators";

const EditClinic = () => {
  const { clinic_id: clinicId } = useParams();
  const { width } = useScreenSize()
  const navigate = useNavigate();

  const modalId = "delete-clinic-modal";
  const {
    register,
    watch,
    handleSubmit,
    handleSubmitWithoutValidation,
    openModal,
    fetchFormValues,
    resetFetchedValues,
    errors,
    isSubmitting,
    originalValues
  } = useFormWithDisable(modalId);

  const calendarCode = watch("calendar_code");
  const logoUrl = watch("logo_url");
  const plan = watch("plan");
  const price = watch("price");
  const freeMonths = watch("free_months");
  const existingProfile = watch("has_password");
  const invoiceCode = watch("invoice_code");
  const invoiceNumber = watch("invoice_number");

  const invoiceInfo =
    invoiceCode && invoiceNumber
      ? `La primera factura generada será ${invoiceCode}-${invoiceNumber}`
      : undefined;

  const activeClinic = useSelector((state) => state.activeClinic);
  const userType = useSelector((state) => state.auth.user_type);

  const [isEditing, setIsEditing] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState(null);
  const [updateBoolean, setUpdateBoolean] = useState(false);

  const formConfig = {
    name: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    email: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Correo Electrónico *",
      placeholder: "clinica@email.com",
      name: "email",
      validators: {
        required: "El correo es obligatorio",
        validate: checkMailFormat,
      },
    },
    landline_phone: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Número de teléfono fijo",
      placeholder: "+34 XXX XXX XXX",
      name: "landline_phone",
      validators: { validate: checkPhoneNumberFormat },
    },
    mobile_phone: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Número de teléfono móvil",
      placeholder: "+34 XXX XXX XXX",
      name: "mobile_phone",
      validators: { validate: checkPhoneNumberFormat },
    },
    address: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Dirección *",
      placeholder: "Calle, número",
      name: "address",
    },
    town: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Ciudad",
      placeholder: "Ciudad",
      name: "town",
    },
    invoice_code: {
      condition: true,
      disabled: !isEditing,
      info: invoiceInfo,
      type: "text",
      label: "Código de factura *",
      placeholder: "EX",
      name: "invoice_code",
      validators: {
        required: "El código de factura es obligatorio",
        minLength: {
          value: 2,
          message: "El código de factura debe tener entre 2 y 5 caracteres",
        },
        maxLength: {
          value: 5,
          message: "El código de factura debe tener entre 2 y 5 caracteres",
        },
      },
    },
    invoice_number: {
      condition: true,
      disabled: !isEditing,
      info: invoiceInfo,
      type: "number",
      label: "Número de factura inical *",
      placeholder: "Ejemplo: 0",
      name: "invoice_number",
      minValue: 0,
      validators: {
        required: "El número de factura inicial es obligatorio",
        min: {
          value: 0,
          message: "El número de factura inicial no puede ser negativo",
        },
      },
    },
    cif: {
      condition: true,
      disabled: !isEditing,
      info: "El identificador fiscal es necasario para la generación de facturas",
      type: "text",
      label: "CIF *",
      placeholder: "00000000X",
      name: "cif",
      validators: { validate: checkCIFFormat },
    },
    dummy: {
      condition: true,
      type: "dummy",
    },
    online_booking: {
      condition: true,
      disabled: !isEditing,
      info: "Si activas las reservas online, los pacientes podrán reservar citas a través de la web",
      label: "Activar reservas online",
      type: "checkbox",
      name: "online_booking",
    },
    task_generation: {
      condition: true,
      disabled: !isEditing,
      info: "Si activas la creación de recordatorios se crearán tareas en los cumpleaños de los pacientes",
      label: "Activar creación de recordatorios",
      type: "checkbox",
      name: "task_generation",
    },
  };

  useEffect(() => {
    if (clinicId) fetchFormValues(getClinic, clinicId);
  }, [clinicId, updateBoolean, advancedOptions]);

  useEffect(() => {
    if (!isEditing && clinicId) resetFetchedValues();
  }, [isEditing]);

  useEffect(() => {
    setAdvancedOptions(null);
  }, [clinicId]);

  const afterEditClinic = () => {
    setUpdateBoolean(!updateBoolean);
    setIsEditing(false);
  };

  const handleClose = () => {
    setAdvancedOptions(null);
    setUpdateBoolean(!updateBoolean);
  }

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Clínica {activeClinic.name}</h2>

      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-5">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        {userType === "manager" && (
          <div className="mt-5 flex space-x-2 sm:space-x-4">
            {!isEditing ? (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(setIsEditing, true)}
                  disabled={isSubmitting}
                >
                  Editar
                </button>
                <div className="dropdown">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-primary btn-sm"
                  >
                    { width > 480 ? "Opciones avanzadas " : "Más "}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="text-base-100"
                    />
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-40 menu p-2 shadow bg-base-300 rounded-box w-52"
                  >
                    <li
                      onClick={handleSubmitWithoutValidation(
                        setAdvancedOptions,
                        "password"
                      )}
                    >
                      <a>
                        {existingProfile
                          ? "Cambiar contraseña"
                          : "Crear contraseña"}
                      </a>
                    </li>
                    <li
                      onClick={handleSubmitWithoutValidation(
                        setAdvancedOptions,
                        "schedule"
                      )}
                    >
                      <a>Editar horario</a>
                    </li>
                    <li
                      onClick={handleSubmitWithoutValidation(
                        setAdvancedOptions,
                        "logo"
                      )}
                    >
                      <a>Editar logo</a>
                    </li>
                    <li
                      onClick={handleSubmitWithoutValidation(
                        setAdvancedOptions,
                        "bill"
                      )}
                    >
                      <a>Editar plantilla de facturas</a>
                    </li>
                    <li
                      onClick={handleSubmitWithoutValidation(
                        setAdvancedOptions,
                        null
                      )}
                    >
                      <a>Cerrar</a>
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-error btn-sm"
                  onClick={openModal}
                  disabled={isSubmitting}
                >
                  Eliminar
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(navigate, -1)}
                  disabled={isSubmitting}
                >
                  Atrás
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmit(
                    putClinic,
                    activeClinic.id,
                    handleSubmitWithoutValidation(afterEditClinic)
                  )}
                  disabled={isSubmitting}
                >
                  Guardar
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                  disabled={isSubmitting}
                >
                  Cancelar
                </button>
              </>
            )}
          </div>
        )}
      </form>

      {advancedOptions === "password" && (
        <UpdateClinicPassword
          existingProfile={existingProfile}
          clinicId={clinicId}
          close={handleClose}
        />
      )}

      {advancedOptions === "logo" && (
        <EditLogo
          originalLogoURL={logoUrl}
          clinicId={clinicId}
          close={handleClose}
        />
      )}

      {advancedOptions === "schedule" && (
        <ScheduleSelector
          schedule={originalValues.schedule}
          clinicId={clinicId}
          close={handleSubmitWithoutValidation(setAdvancedOptions, null)}
        />
      )}

      {advancedOptions === "bill" && (
        <InvoiceTemplate
          clinicId={clinicId}
          close={handleClose}
        />
      )}

      {isDefined(plan) && isDefined(freeMonths) && (
        <PaymentPlanDisplay plan={plan} freeMonths={freeMonths} price={price} />
      )}

      {isDefined(calendarCode) && <ShowClinicBookings code={calendarCode} />}

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar todos los datos de la clínica?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithoutValidation(deleteClinic, activeClinic.id)}
      />
    </div>
  );
};

export default EditClinic;
