import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import CustomForm from "../general/form/CustomForm";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import { handleSubmit } from "../../auxiliar/auxFunctions";
import { getAllAcquiredVoucher } from "../../actions/acquiredVoucher";
import { isDefined } from "../../auxiliar/formatValidators";

const OverviewAcquiredVoucherInPatient = ({ refreshBool }) => {
  const { register, watch, errors } = useFormWithDisable();
  const writtenFilter = {
    remaining_sessions: watch("remaining_sessions") || false,
    not_expired: watch("not_expired") || false,
  };

  const navigate = useNavigate();
  const { patient_id: patientId } = useParams();
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);
  const [vouchers, setVouchers] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    per_page: 5,
    page: 1,
    clinic_id: activeClinicId,
    patient_id: patientId,
    order: "remaining_session-due_date",
  });

  const disabled = usePaginationFilterEffects(filter, writtenFilter, getAllAcquiredVoucher, setVouchers, setFilter, refreshBool);

  const formConfig = {
    remaining_sessions: {
      condition: true,
      disabled: false,
      label: "Sesiones restantes",
      info: "Márcalo para mostrar solo los bonos con sesiones restantes",
      type: "checkbox",
      name: "remaining_sessions",
    },
    not_expired: {
      condition: true,
      disabled: false,
      label: "No caducados",
      info: "Márcalo para mostrar solo los bonos no caducados",
      type: "checkbox",
      name: "not_expired",
    },
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Bonos adquiridos</h2>

      <form className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>
      </form>

      {vouchers.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(vouchers.order) ||
        !isDefined(vouchers.content) ||
        vouchers.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay clases registradas, añade el paciente a las clases desde la
          agenda.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th className="hidden sm:table-cell">Nombre</th>
                <th>Tipo</th>
                <th className="sm:hidden">Sesiones</th>
                <th className="hidden sm:table-cell">Sesiones restantes</th>
                <th className="hidden sm:table-cell">Caducidad</th>
                <th />
                <th />
              </tr>
            </thead>

            <tbody>
              {vouchers.order.map((voucherId) => {
                const voucher = vouchers.content && vouchers.content[voucherId];

                return (
                  <tr
                    key={voucherId}
                    className={voucher.unpaid > 0 ? "bg-light-error" : ""}
                  >
                    <td className="hidden sm:table-cell">{voucher.name}</td>
                    <td>{voucher.session_type}</td>
                    <td>{voucher.remaining_sessions}</td>
                    <td className="hidden sm:table-cell">
                      {voucher.due_date.substring(0, 10)}
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit(
                          navigate,
                          `/acquired-voucher/${voucherId}?patient=${patientId}`
                        )}
                      >
                        Detalles
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <form className="mt-5 flex justify-between">
        <div className="flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(
              navigate,
              `/acquired-voucher/register?patient=${patientId}`
            )}
          >
            Nuevo Bono
          </button>
        </div>

        <PaginatorComponent
          pagination={vouchers.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </form>
    </div>
  );
};

export default OverviewAcquiredVoucherInPatient;
