import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormTextarea = ({ customKey, register, config, errors }) => {
  return (
    <div key={customKey} className="form-control col-span-1 md:col-span-2">
      {config.label && (
        <label htmlFor={config.name} className="label-text">
          {config.label}{" "}
          {config.info && (
            <div className="tooltip" data-tip={config.info}>
              <FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
            </div>
          )}
        </label>
      )}

      <div className={`${errors && "tooltip"}`} data-tip={errors?.message}>
        <textarea
          className={`textarea text-left w-full ${
            errors ? "textarea-error" : "textarea-primary"
          } disabled:bg-disabled-bg disabled:text-disabled-text placeholder-disabled-text`}
          type={config.type}
          id={config.name}
          defaultValue={config.defaultValue}
          placeholder={config.placeholder}
          disabled={config.disabled}
          {...register(config.name, config.validators)}
        />
      </div>
    </div>
  );
};

export default FormTextarea;
