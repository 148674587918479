import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import RegisterChargePopup from "./RegisterChargePopUp";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllCharges } from "../../actions/charge";
import { isDefined } from "../../auxiliar/formatValidators";

const OverviewCharges = ({ cashRegisterId, cashRegisterData, boolRefresh, refresh }) => {
  const dispatch = useDispatch();

  const activeClinicId = useSelector((state) => state.activeClinic.id);
  const [charges, setCharges] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    cash_register_id: cashRegisterId,
    clinic_id: activeClinicId,
    order: "charge_date",
    per_page: 5,
    page: 1,
  });

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllCharges,
    setCharges,
    setFilter,
    boolRefresh
  );

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Pagos Realizados</h2>

      {charges.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(charges.order) ||
        !isDefined(charges.content) ||
        charges.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          El paciente todavía no ha realizado ningún pago.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Método de pago</th>
              </tr>
            </thead>

            <tbody>
              {charges.order.map((id) => {
                const charge = charges.content && charges.content[id];

                return (
                  <tr key={id}>
                    <td>{charge.charge_date}</td>
                    <td>{parseFloat(charge.paid).toFixed(2)}</td>
                    <td>{charge.payment_method}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-5 flex justify-between">
        {cashRegisterData?.unpaid > 0 && (
          <div className="flex space-x-2 sm:space-x-4">
            <RegisterChargePopup
              cashRegisterData={cashRegisterData}
              refresh={refresh}
            />
          </div>
        )}

        <PaginatorComponent
          pagination={charges.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OverviewCharges;
