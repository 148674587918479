import { useEffect } from "react";

import CustomForm from "../../../general/form/CustomForm";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { getVoucher } from "../../../../actions/voucher";

const VoucherDisplay = ({ voucherTypeId }) => {
  const { register, watch, fetchFormValues, errors } = useFormWithDisable();
  const type = watch("group_indiv");
  const groupSessionTypeId = watch("group_session_type_id");
  const sessionTypeId = watch("session_type_id");

  const formConfig = {
    group_indiv: {
      condition: true,
      disabled: true,
      type: "selector",
      name: "group_indiv",
      label: "Tipo de bono *",
      options: [
        { key: "individual", label: "Sesiones individuales" },
        { key: "group", label: "Clases" },
      ],
      defaultValue: "individual",
    },
    session_type_id: {
      condition: type === "individual" || type === undefined,
      disabled: true,
      type: "session_type_selector",
      name: "session_type_id",
      label: "Tipo de sesión *",
      defaultValue: sessionTypeId || "select",
    },
    group_session_type_id: {
      condition: type === "group" || type === undefined,
      disabled: true,
      type: "group_session_type_selector",
      name: "group_session_type_id",
      label: "Tipo de clase *",
      defaultValue: groupSessionTypeId || "select",
    },
    name: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "display_name",
    },
    price: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Precio del bono *",
      placeholder: "Ejemplo: 100",
      name: "price",
      info: "Precio del bono en euros",
      minValue: 0,
    },
    total_sessions: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Número de sesiones *",
      placeholder: "10",
      name: "total_sessions",
      minValue: 1,
    },
    months_validity: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Meses de validez *",
      placeholder: "Ejemplo: 12",
      name: "months_validity",
      info: "Desde el día de la compra, cuando hayan pasado estos meses, el bono del paciente expirará.",
      minValue: 1,
    },
  };

  useEffect(() => {
    if (voucherTypeId) fetchFormValues(getVoucher, voucherTypeId, false);
  }, [voucherTypeId]);

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Datos del Tipo de bono</h2>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>
      </form>
    </div>
  );
};

export default VoucherDisplay;
