import RegisterForm from '../../../components/general/landingPage/RegisterForm';


const Register = () => {
    return <div className='flex my-10 lg:my-10 justify-center'>
        <div className="bg-base-200 shadow-xl rounded-lg p-10">
            <h2 className="card-title justify-center text-primary">¡Bienvenido!</h2>
            <RegisterForm />
        </div >
    </div >
}

export default Register;