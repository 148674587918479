// The list of your testimonials. It needs 3 items to fill the row.
const list = [
  {
    // REQUIRED
    name: "Diego",
    // REQUIRED
    text: "Muy sencillo e intuitivo, me ha ayudado a ahorrar mucho tiempo en la gestión de mi clínica. El precio es muy asequible y el valor añadido es brutal.",
  },
  {
    name: "Juan",
    text: "Al principio era un poco escéptico, siempre había llevado la gestión en papel. Un día me decidí a probar MiFisio Agenda y al momento me dí cuenta de las ventajas, me hace la vida más fácil.",
  },
  {
    name: "María",
    text: "Sencillo, rápido y eficaz. Sin duda el programa de gestión más moderno que he probado, con procesos muy sencillos y desde donde quieras.",
  }, 
];

// A single testimonial, to be rendered in  a list
const Testimonial = ({ i }) => {
  const testimonial = list[i];

  if (!testimonial) return null;

  return (
    <li key={i}>
      <figure className="relative max-w-lg h-full p-6 md:p-10 bg-base-200 rounded-2xl max-md:text-sm flex flex-col">
        <blockquote className="relative flex-1">
          <p className="text-base-content/80 leading-relaxed">
            {testimonial.text}
          </p>
        </blockquote>
        <figcaption className="relative flex items-center justify-start gap-4 pt-4 mt-4 md:gap-8 md:pt-8 md:mt-8 border-t border-base-content/5">
          <div className="w-full flex items-center justify-between gap-2">
            <div>
              <div className="font-medium text-base-content md:mb-0.5">
                {testimonial.name}
              </div>
              {testimonial.username && (
                <div className="mt-0.5 text-sm text-base-content/80">
                  @{testimonial.username}
                </div>
              )}
            </div>

            <div className="overflow-hidden rounded-full bg-base-100 shrink-0">
              {testimonial.img ? (
                <img
                  className="w-10 h-10 md:w-12 md:h-12 rounded-full object-cover"
                  src={list[i].img}
                  alt={`${list[i].name}'s testimonial for MiFisio`}
                  width={48}
                  height={48}
                />
              ) : (
                <span className="w-10 h-10 md:w-12 md:h-12 rounded-full flex justify-center items-center text-lg font-medium bg-base-100">
                  {testimonial.name.charAt(0)}
                </span>
              )}
            </div>
          </div>
        </figcaption>
      </figure>
    </li>
  );
};

const Testimonials = () => {
  return (
    <section id="testimonials">
      <div className="py-24 px-8 max-w-7xl mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <div className="mb-8">
            <h2 className="sm:text-5xl text-4xl font-extrabold text-base-content text-primary">
              +100 Fisioterapeutas se han digitalizado con MiFisio
            </h2>
          </div>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-base-content/80">
            No tienes que confiar en nosotros, echa un vistazo a lo que piensan tus compañeros
          </p>
        </div>

        <ul
          role="list"
          className="flex flex-col items-center lg:flex-row lg:items-stretch gap-6 lg:gap-8"
        >
          {[...Array(3)].map((e, i) => (
            <Testimonial key={i} i={i} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Testimonials;