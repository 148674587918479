import { useEffect } from "react";

import FormInput from "./FormInput";
import FormCheckbox from "./FormCheckbox";
import { timeOrder } from "../../../auxiliar/formatValidators";

const CustomDayScheduleSelector = ({ customKey, register, config, errors }) => {
  const start = config.watch(`schedule.${customKey}_schedule_m`);
  const end = config.watch(`schedule.${customKey}_schedule_e`);
  const closed = config.watch(`schedule.${customKey}_schedule_c`);

  // sets initial values
  useEffect(() => {
    config.setValue(`schedule.${customKey}_schedule_m`, config?.originalValues?.[`${customKey}_schedule_m`]);
    config.setValue(`schedule.${customKey}_schedule_e`, config?.originalValues?.[`${customKey}_schedule_e`]);
    config.setValue(`schedule.${customKey}_schedule_c`, config?.originalValues?.[`${customKey}_schedule_c`]);
  }, []);

  // resets values if closed
  useEffect(() => {
    if (closed) {
      config.setValue(`schedule.${customKey}_schedule_m`, "");
      config.setValue(`schedule.${customKey}_schedule_e`, "");
    } else if (config?.originalValues) {
      config.setValue(`schedule.${customKey}_schedule_m`, config?.originalValues?.[`${customKey}_schedule_m`]);
      config.setValue(`schedule.${customKey}_schedule_e`, config?.originalValues?.[`${customKey}_schedule_e`]);
    }
  }, [closed]);

  return (
    <div>
      <h6 className="label-text text-lg">  {config.label} </h6>

      <FormInput
        key={`${customKey}_schedule_m`}
        customKey={`${customKey}_schedule_m`}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled || closed,
          type: "time",
          label: "Hora de inicio",
          name: `schedule.${customKey}_schedule_m`,
          validators: {
            validate: (v) => timeOrder(v, end)
          },
        }}
        errors={errors.schedule ? errors.schedule[`${customKey}_schedule_m`] : undefined}
      />
      <FormInput
        key={`${customKey}_schedule_e`}
        customKey={`${customKey}_schedule_e`}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled || closed,
          type: "time",
          label: "Hora de fin",
          name: `schedule.${customKey}_schedule_e`,
          validators: {
            validate: (v) => timeOrder(start, v)
          }
        }}
        errors={errors.schedule ? errors.schedule[`${customKey}_schedule_e`] : undefined}
      />
      <div className="flex items-center mt-1">
        <FormCheckbox
          key={`${customKey}_schedule_c`}
          customKey={`${customKey}_schedule_c`}
          register={register}
          config={{
            condition: config.condition,
            disabled: config.disabled,
            type: "checkbox",
            label: "Cerrado",
            name: `schedule.${customKey}_schedule_c`,
          }}
          errors={errors[`${customKey}_schedule_c`]}
        />
      </div>
    </div>
  )

}
export default CustomDayScheduleSelector;