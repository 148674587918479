import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { putClinic } from "../../actions/clinics";

const ScheduleSelector = ({
  schedule,
  clinicId,
  close
}) => {
  const {
    register,
    handleSubmit,
    handleSubmitWithoutValidation,
    errors,
    isSubmitting,
    watch,
    setValue,
  } = useFormWithDisable();


  const formConfig = {
    schedule: {
      condition: true,
      disabled: false,
      type: "schedule",
      className: "text-2xl",
      watch: watch,
      setValue: setValue,
      originalValues: schedule,
    },
  }

  return (
    <div className="mt-5 mb-12">
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(putClinic, clinicId, close)}
            disabled={isSubmitting}
          >
            Confirmar
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmitWithoutValidation(close)}
            disabled={isSubmitting}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>)
};

export default ScheduleSelector;
