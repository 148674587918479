import RegisterForm from './RegisterForm';


const Hero = () => {
    return <section className="max-w-7xl mx-auto bg-base-100 flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-10">
        <div className="flex flex-col gap-10 lg:gap-14 text-center items-center">
            <div>
                <img
                    src={require("./../../../media/icon/icon_125.png")}
                    alt={'Logo'}
                />
            </div>
            <h1 className="font-extrabold text-4xl lg:text-6xl tracking-tight text-secondary">
                De Fisios, para Fisios
            </h1>
            <p className="text-lg opacity-80 leading-relaxed">
                Déjanos encargarnos de la gestión de tu clínica y céntrate en lo que realmente importa: tus pacientes.
            </p>
        </div>
        <div className="lg:w-full">
            <div className='flex my-10 lg:my-20 justify-center'>
                <div className="bg-base-200 shadow-xl rounded-lg p-10">
                    <h2 className="card-title justify-center text-primary">¡No esperes más! Registrate aquí</h2>
                    <RegisterForm />
                </div >
            </div>
        </div>
    </section>
}

export default Hero;