import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import NotAllowed from "../pages/public/NotAllowed";
import RegisterClinic from "../pages/manager/clinic/RegisterClinic";
import { dispatchMessageSave } from "../store/reducers/messageReducer";


const ProtectedRoute = ({ redirectPath = "/login", requiredPermissions, children }) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()

    if (process.env.REACT_APP_MAINTENANCE_ONGOING === "true") {
        return <Navigate to={`/maintenance`} replace />
    }

    if (auth.user_type === "manager" && Object.keys(auth.clinics).length === 0) {
        dispatchMessageSave(dispatch, {
            msg: "¡Bienvenido a MiFisio! Registra una clínica para continuar.",
            type: "info",
        })
        return <RegisterClinic />
    }

    else if (requiredPermissions.includes(auth.user_type))
        return children

    else if (auth.user_type === "clinic")
        return <NotAllowed />

    return <Navigate to={redirectPath} replace />
}

export default ProtectedRoute;
