import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { backendHealthCheck, databaseHealthCheck } from "../../actions/healthCheck";


const HealthCheck = () => {
    const dispatch = useDispatch();

    const [activeFrontent, setActiveFrontend] = useState(false)
    const [activeBackend, setActiveBackend] = useState(false)
    const [activeDatabase, setActiveDataBase] = useState(false)

    useEffect(() => {
        setActiveFrontend(true)
        dispatch(backendHealthCheck(setActiveBackend))
        dispatch(databaseHealthCheck(setActiveDataBase))
    }, [dispatch, setActiveDataBase, setActiveBackend])

    return (
        <section className="max-w-7xl mx-auto bg-base-100 grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-10">
            <div className="flex flex-col gap-10 lg:gap-14 text-center items-center">
                <h1 className="font-extrabold text-4xl lg:text-3xl tracking-tight text-primary">
                    Estado de la Aplicación
                </h1>
                <div>
                    <img src={require("../../media/icon/icon_125.png")} alt={"Logo"} />
                </div>
            </div>
            <div className="md:col-span-2">
                <div className="flex my-5 md:my-20 justify-center">
                    <div className="bg-base-200 shadow-xl rounded-lg p-10">
                        <p className="text-primary text-2xl text-center my-5">
                            Contexto
                        </p>

                        <p className="text-center text-base lg:text-lg my-3">
                            Esta página muestra el estado de los diferentes componentes de la aplicación. Para un correcto funcionamiento,
                            todos ellos deben estar activos (marcados con un círculo verde). El objetivo de esta página es facilitar que cualquier
                            persona pueda comprobar si la aplicación está funcionando correctamente en cualquier momento.
                        </p>

                        <p className="text-primary text-2xl text-center mt-10 mb-5">
                            Componentes de la Aplicación
                        </p>

                        <div className="flex justify-center items-center text-base lg:text-lg my-3">
                            <span>
                                <span className="my-3">
                                    <div className="tooltip" data-tip={"La interfaz gráfica a la que accedes como usuario"}>
                                        <FontAwesomeIcon icon={faCircle} className={!activeFrontent ? "text-error" : "text-secondary"} />
                                    </div> Interfaz Gráfica
                                </span> <br/>
                                <span className="my-3">
                                    <div className="tooltip" data-tip={"La interfaz gráfica a la que accedes como usuario"}>
                                        <FontAwesomeIcon icon={faCircle} className={!activeBackend ? "text-error" : "text-secondary"} />
                                    </div> Servidor
                                </span><br/>
                                <span className="my-3">
                                    <div className="tooltip" data-tip={"La base de datos donde se guarda la informacion"}>
                                        <FontAwesomeIcon icon={faCircle} className={!activeDatabase ? "text-error" : "text-secondary"} />
                                    </div> Base de Datos
                                </span><br/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HealthCheck;