import axios from "axios";

export const getAllBookings = (params) => {
	if (["select", "all"].includes(params.employee_id)) delete params.employee_id;
	delete params.initial_date;

	return async () => {
		return axios
			.get(`/api/managers/calendar/`, { params: params })
			.then((response) => {
				return response?.data?.content || { sessions: [] }
			})
			.catch(() => { });
	};
};

export const getPublicBookings = (params) => {
	return async () => {
		if (["select", "all"].includes(params.employee_id)) delete params.employee_id;
		delete params.display_finished
		delete params.initial_date

		return axios
			.get(`/api/anonymous/calendar`, { params: params })
			.then((response) => {
				return response?.data?.content || { sessions: [] }
			})
			.catch(() => { });
	};
};
