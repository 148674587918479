import { Link } from 'react-router-dom';

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from '../../../components/general/form/CustomForm';
import { requestNewPassword } from "../../../actions/auth";
import { checkMailFormat } from "../../../auxiliar/formatValidators";

const ForgottenPassword = () => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting
    } = useFormWithDisable();

    const formConfig = {
        email: {
            condition: true,
            type: "text",
            label: "Correo Electrónico *",
            placeholder: "fisio@email.com",
            name: "email",
            validators: {
                required: "El correo es obligatorio",
                validate: checkMailFormat
            },
        },
    }

    return <div className='flex my-10 lg:my-20 justify-center'>
        <div className="bg-base-200 shadow-xl rounded-lg p-10">
            <h2 className="card-title justify-center text-primary">¿Contraseña olvidada?</h2>

            <p className="max-w-xs text-center">
                ¡No te preocupes, estamos aqui para ayudarte! Introduce tu correo eléctronico y
                recibirás un mensaje en los próximos minutos con las instrucciones para restablecerla.
            </p>

            <p className="max-w-xs text-center mt-3">
                Si la cuenta que utilizas es un perfil de clínica, por favor, contacta con tu mánager.
            </p>

            <form className="grid grid-cols-1 gap-2 items-center my-5">
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />

                <div className="card-actions justify-center mt-3">
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit(requestNewPassword)}
                        disabled={isSubmitting}
                        id='submit'
                    >
                        Restablecer contraseña
                    </button>
                </div>

                <div className="mt-6 text-center">
                    <Link to="/register" className="link">¿Todavía no tienes cuenta?</Link>
                </div>
                <div className="text-center">
                    <Link to="/login" className="link">¿Has recordado tu contraseña?</Link>
                </div>
            </form>
        </div>
    </div>
}

export default ForgottenPassword;