import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import UpdateManagerPassword from "../../../components/manager/UpdateManagerPassword";
import ShowManagerReferral from "../../../components/manager/ShowManagerReferral";
import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import {
  checkMailFormat,
  checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";
import {
  deleteManager,
  getManager,
  putManager,
} from "../../../actions/manager";

const ManagerProfile = () => {
  const modalId = "delete-manager-modal";
  const {
    register,
    watch,
    handleSubmit,
    handleSubmitWithoutValidation,
    openModal,
    fetchFormValues,
    resetFetchedValues,
    errors,
    isSubmitting,
  } = useFormWithDisable(modalId);

  const [isEditing, setIsEditing] = useState(false);
  const [passwordEditing, setPasswordEditing] = useState(false);
  const id = useSelector((state) => state.auth.id);
  const code = watch("manager_code");

  const formConfig = {
    name: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    surnames: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Apellidos *",
      placeholder: "Apellidos",
      name: "surnames",
      validators: { required: "Los apellidos son obligatorios" },
    },
    email: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Correo Electrónico *",
      placeholder: "fisio@email.com",
      name: "email",
      validators: {
        required: "El correo es obligatorio",
        validate: checkMailFormat,
      },
    },
    phone_number: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Número de teléfono",
      placeholder: "+34 XXX XXX XXX",
      name: "phone_number",
      validators: {
        validate: checkPhoneNumberFormat,
      },
    },
    association_code: {
      condition: true,
      disabled: !isEditing,
      type: "text",
      label: "Código de colegio o asociación",
      placeholder: "ejemplo_2024",
      name: "association_code",
    },
    entry_date: {
      condition: true,
      disabled: true,
      type: "date",
      label: "Fecha de registro",
      name: "entry_date",
    },
  };

  useEffect(() => {
    if (id) fetchFormValues(getManager, id);
  }, [id]);

  useEffect(() => {
    if (!isEditing && id) resetFetchedValues();
  }, [isEditing]);

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Perfil</h2>
      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          {!isEditing ? (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, true)}
              >
                Editar
              </button>
              <button
                className="btn btn-error btn-sm text-right"
                onClick={openModal}
                disabled={isSubmitting}
              >
                Eliminar
              </button>
              {!passwordEditing && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(
                    setPasswordEditing,
                    true
                  )}
                >
                  Editar constraseña
                </button>
              )}
            </>
          ) : (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmit(
                  putManager,
                  id,
                  handleSubmitWithoutValidation(setIsEditing, false)
                )}
                disabled={isSubmitting}
              >
                Guardar
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                disabled={isSubmitting}
              >
                Cancelar
              </button>
            </>
          )}
        </div>
      </form>

      {passwordEditing && (
        <UpdateManagerPassword
          id={id}
          close={handleSubmitWithoutValidation(setPasswordEditing, false)}
        />
      )}

      <ShowManagerReferral code={code} />

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar la cuenta del gerente?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithoutValidation(deleteManager, id)}
      />
    </div>
  );
};

export default ManagerProfile;
