import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Kalend, { CalendarView } from "kalend";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import RegisterUnconfirmedSessionPopup from "../../../components/services/session/popupUnconfirmedSession/RegisterUnconfirmedSessionPopup";
import CustomForm from "../../../components/general/form/CustomForm";
import SmallFooter from "../../../components/general/footer/SmallFooter";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { getPublicBookings } from "../../../actions/calendar";
import { getPublicPhysiotherapists } from "../../../actions/physiotherapist";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined, checkDateOrRange } from "../../../auxiliar/formatValidators";

const CalendarBookings = () => {
    const openTooltipRegister = useRef();
    const openTooltipEdit = useRef();
    const kalendRef = useRef();
    const smallCalendarRef = useRef();

    const screenSize = useScreenSize();
    const params = useQueryParams();

    const { control, register, watch, setValue, errors } = useFormWithDisable();

    const formConfig = {
        view: {
            condition: true,
            disabled: false,
            type: "selector",
            label: "Vista",
            name: "view",
            defaultValue: CalendarView.WEEK,
            options: [
                { key: CalendarView.DAY, label: "Día" },
                { key: CalendarView.THREE_DAYS, label: "Tres días" },
                { key: CalendarView.WEEK, label: "Semana" },
                { key: CalendarView.MONTH, label: "Mes" },
                { key: CalendarView.AGENDA, label: "Agenda" },
            ],
        },
        employee_id: {
            condition: true,
            disabled: false,
            allowAll: true,
            type: "physio_selector",
            label: "Fisioterapeuta",
            name: "employee_id",
        },
    };

    const view = watch("view") || CalendarView.WEEK;
    const employee = watch("employee_id") || "all";

    const [kalendState, setKalendState] = useState({});
    const [datePickerState, setDatePickerState] = useState({
        date: new Date(),
        auxDate: new Date(),
        updated: false,
    });

    const dispatch = useDispatch();
    const isLight = useSelector((state) => state.themeReducer.lightTheme);
    const activeClinicId = params.get("clinic");

    const [boolRefresh, setBoolRefresh] = useState(false);
    const [events, setEvents] = useState({
        sessions: [],
        waiting: true,
    });

    const fetchEvents = async () => {
        if (
            kalendState.initial_date &&
            kalendState.starting_date &&
            kalendState.finishing_date
        )
            fetchData(
                dispatch,
                getPublicBookings,
                {
                    ...kalendState,
                    clinic_id: activeClinicId,
                    employee_id: employee,
                    display_finished: false,
                    per_page: 1000,
                },
                (events) => {
                    setEvents({ sessions: [], waiting: true });
                    setTimeout(() => setEvents(events), 10);
                }
            );
    };

    const [physios, setPhysios] = useState({ content: {}, order: [] });
    useEffect(() => {
        if (screenSize.width < 640)
            fetchData(
                dispatch,
                getPublicPhysiotherapists,
                {
                    clinic_id: activeClinicId,
                    order: "name",
                    per_page: 1000,
                    page: 1,
                },
                setPhysios
            );
    }, [screenSize]);

    /* 
      Beautify the scroll of calendar
  
      TODO: Delete this from here and put it in CSS. 
      It is not done yet because the kalend library has its own css
    */
    useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById("Kalend__timetable");

            if (element) {
                element.classList.add(
                    "scrollbar-thumb-rounded-full",
                    "scrollbar-track-rounded-full",
                    "scrollbar",
                    "scrollbar-thin",
                    "scrollbar-thumb-base-300",
                    "scrollbar-track-base-100"
                );
            }
        }, 100);
    }, []);

    // Update state with selector (only necessary because the view is displayed)
    useEffect(() => {
        if (view !== kalendState.view) {
            setKalendState({ ...kalendState, view: view });
        }
    }, [view]);

    /* 
      If date changes:
        1. Check if it was small calendar change. If it was:
          1. Check date and move forward/backward one time
          2. State of main calend will trigger this again until the current date is reached
          3. Update actual state and set updated to false to go back to listen to state changes
          4. Request new data
        
        2. If it was a state calendar change
          1. Set small calendar date
          2. Request new data
      
      View will trigger date change, so no need to check for view change.  
    */
    useEffect(() => {
        let date = datePickerState.auxDate.getTime();
        if (isDefined(datePickerState.auxDate.length)) {
            date = (datePickerState.auxDate[0].getTime() + datePickerState.auxDate[1].getTime()) / 2;
        }

        if (
            datePickerState.updated &&
            date > new Date(kalendState.finishing_date).getTime()
        ) {
            kalendRef?.current?.navigateForward();
        } else if (
            datePickerState.updated &&
            date < new Date(kalendState.starting_date).getTime()
        ) {
            kalendRef?.current?.navigateBackwards();
        } else {
            setDatePickerState({
                ...datePickerState,
                updated: false,
                date:
                    kalendState.view === "day"
                        ? new Date(kalendState.starting_date)
                        : [
                            new Date(kalendState.starting_date),
                            new Date(kalendState.finishing_date),
                        ],
            });

            fetchEvents();
        }
    }, [
        datePickerState.auxDate,
        kalendState.initial_date,
        kalendState.starting_date,
        kalendState.finishing_date,
        boolRefresh,
    ]);

    useEffect(() => {
        fetchEvents();
    }, [employee]);

    const refresh = () => {
        setBoolRefresh(!boolRefresh);
    };

    const onEventClick = (data) => {
        if (data.type !== "block") openTooltipEdit.current(data);
    };

    const onEventDragFinish = (prev, current, data, resetPosition) => {
        if (current.type !== "block") openTooltipEdit.current(current);
    };

    const onNewEventClick = (data) => {
        // reload employee value, otherwise it will be the selected on mount
        const employee = watch("employee_id") || kalendState?.employee_id || "select";

        openTooltipRegister.current({
            ...data,
            employee_id: employee,
        });
    };

    const onDatePick = (date, e) => {
        setDatePickerState({
            ...datePickerState,
            auxDate: date,
            updated: true,
        });
    };

    const onStateChange = (state) => {
        const newView =
            state?.calendarDays?.length === 1 ? CalendarView.DAY : state.selectedView;
        setValue("view", newView);
        setKalendState({
            view: newView,
            starting_date: state.range.rangeFrom,
            finishing_date: state.range.rangeTo,
            initial_date: state.selectedDate,
        });
    };

    const toToday = () => {
        kalendRef?.current?.navigateToTodayDate();
        smallCalendarRef.current?.setActiveStartDate(new Date());
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col xl:flex-row xl:grid xl:grid-cols-5 gap-4 flex-grow overflow-auto">
                <div className="xl:col-span-1 px-5 xl:flex xl:flex-col xl:justify-between xl:h-full">
                    <div className="pt-3 mt-5">
                        <div className="flex justify-between">
                            <h2 className="text-primary text-2xl">Agenda</h2>

                            <div className="dropdown dropdown-end sm:hidden">
                                <div
                                    tabIndex={0}
                                    role="button"
                                    className="btn btn-primary btn-sm z-30"
                                >
                                    Filtros{" "}
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        className="text-base-100"
                                    />
                                </div>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content z-30 menu p-2 shadow bg-base-300 rounded-box w-52"
                                >
                                    <li>
                                        <details>
                                            <summary>Vista</summary>
                                            <ul>
                                                <li onClick={() => setValue("view", CalendarView.DAY)}>
                                                    <a>Día</a>
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        setValue("view", CalendarView.THREE_DAYS)
                                                    }
                                                >
                                                    <a>Tres días</a>
                                                </li>
                                                <li onClick={() => setValue("view", CalendarView.WEEK)}>
                                                    <a>Semana</a>
                                                </li>
                                                <li
                                                    onClick={() => setValue("view", CalendarView.MONTH)}
                                                >
                                                    <a>Mes</a>
                                                </li>
                                                <li
                                                    onClick={() => setValue("view", CalendarView.AGENDA)}
                                                >
                                                    <a>Agenda</a>
                                                </li>
                                            </ul>
                                        </details>
                                    </li>
                                    <li>
                                        <details>
                                            <summary>Fisioterapeuta</summary>
                                            <ul>
                                                <li>
                                                    <a onClick={() => setValue("employee_id", "select")}>
                                                        Todos
                                                    </a>
                                                </li>
                                                {physios &&
                                                    isDefined(physios.order) &&
                                                    isDefined(physios.content) &&
                                                    physios.order.map((physioId) => {
                                                        const physio =
                                                            physios.content && physios.content[physioId];
                                                        return (
                                                            <li key={physio.employee_id}>
                                                                <a
                                                                    onClick={() =>
                                                                        setValue("employee_id", physio.employee_id)
                                                                    }
                                                                >
                                                                    {physio.name} {physio.surnames}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </details>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex justify-between my-3 xl:my-8 items-center">
                            <span className="flex items-center justify-center self-stretch">
                                {!isDefined(datePickerState.date.length)
                                    ? new Date(datePickerState.date.getTime()).toLocaleDateString("es-ES", {
                                        year: "numeric",
                                        month: "long",
                                    })
                                    : new Date(
                                        (datePickerState.date[0].getTime() + datePickerState.date[1].getTime()) / 2
                                    ).toLocaleDateString("es-ES", {
                                        year: "numeric",
                                        month: "long",
                                    })}
                            </span>

                            <div className={"flex justify-end"}>
                                <button
                                    className={"btn btn-primary btn-sm"}
                                    onClick={kalendRef?.current?.navigateBackwards}
                                >
                                    «
                                </button>
                                <button
                                    className={"btn btn-primary btn-sm mx-1"}
                                    onClick={() => toToday()}
                                >
                                    Hoy
                                </button>
                                <button
                                    className={"btn btn-primary btn-sm"}
                                    onClick={kalendRef?.current?.navigateForward}
                                >
                                    »
                                </button>
                            </div>
                        </div>

                        <form className="my-3 xl:my-8 hidden sm:block">
                            <div className="grid sm:grid-cols-2 xl:grid-cols-1 gap-4">
                                <CustomForm
                                    formConfig={formConfig}
                                    errors={errors}
                                    control={control}
                                    register={register}
                                />
                            </div>
                        </form>

                        {
                            (["date", "range"].includes(checkDateOrRange(datePickerState.date))) &&
                            <div className="hidden xl:block">
                                <Calendar
                                    ref={smallCalendarRef}
                                    calendarType={"iso8601"}
                                    onClickDay={onDatePick}
                                    minDetail={"month"}
                                    value={datePickerState.date}
                                    showNeighboringMonth={false}
                                    showFixedNumberOfWeeks={false}
                                    next2Label={null}
                                    prev2Label={null}
                                />
                            </div>
                        }
                    </div>

                    <div className="hidden xl:block">
                        <SmallFooter />
                    </div>
                </div>

                <div className="xl:col-span-4 flex-grow pt-5">
                    <Kalend
                        isDark={!isLight}
                        kalendRef={kalendRef}
                        colors={{
                            light: {
                                primaryColor: "oklch(var(--p))",
                            },
                            dark: {
                                primaryColor: "oklch(var(--p))",
                            },
                        }}
                        language={"es"}
                        hourHeight={120}
                        autoScroll={true}
                        showTimeLine={true}
                        newEventText={"Nueva sesión"}
                        disabledViews={[]}
                        selectedView={kalendState.view}
                        initialView={CalendarView.WEEK}
                        initialDate={
                            kalendState.initial_date
                                ? new Date(kalendState.initial_date).toISOString()
                                : new Date().toISOString()
                        }
                        events={events.sessions}
                        onEventDragFinish={onEventDragFinish}
                        onNewEventClick={onNewEventClick}
                        onEventClick={onEventClick}
                        onStateChange={onStateChange}
                        draggingDisabledConditions={{ resize: "disabled" }}
                        resizeDisabledConditions={{ resize: "disabled" }}
                    />
                </div>
            </div>

            <RegisterUnconfirmedSessionPopup childFunc={openTooltipRegister} refresh={refresh} />
        </div>
    );
};

export default CalendarBookings;