import axios from "axios";

export const getAllCharges = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/charges/`, { params: params })
			.then((response) => {
				return { ...response.data, waiting: false };
			})
			.catch(() => { });
	};
};

export const postCharge = (data, clinicId, cashRegisterId, closeTooltip) => {
	return async () => {
		return axios
			.post("/api/managers/charges/", {
				...data,
				cash_register_id: cashRegisterId,
				clinic_id: clinicId.toString(),
			})
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};
