import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import { postTask } from "../../../actions/task";

const RegisterTask = () => {
    const {
        register,
        handleSubmit,
        handleSubmitWithoutValidation,
        watch,
        errors,
        isSubmitting,
        setValue
    } = useFormWithDisable();

    const navigate = useNavigate();
    const description = watch("description") || "";
    const patient = watch("add_patient") || false;
    const { id: activeClinicId } = useSelector((state) => state.activeClinic);

    useEffect(() => {
        setValue("clinic_id", activeClinicId)
    }, [activeClinicId]);

    const formConfig = {
        topic: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Tema *",
            placeholder: "Tema",
            name: "topic",
            validators: { required: "El tema es obligatorio" },
        },
        date: {
            condition: true,
            disabled: false,
            type: "date",
            label: "Fecha *",
            name: "date",
            validators: { required: "La fecha es obligatoria" },
        },
        priority: {
            condition: true,
            disabled: false,
            type: "number",
            label: "Prioridad *",
            placeholder: "Ejemplo: 5",
            info: "La prioridad es un número entero que indica la importancia de la tarea, te permite ordenarlas una vez creadas.",
            minValue: 0, 
            name: "priority",
            validators: { required: "La prioridad es obligatoria" },
        },
        color: {
            condition: true,
            disabled: false,
            type: "color_selector",
            label: "Color *",
            info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este fisioterapeuta.",
            name: "color",
        },
        description: {
            condition: true,
            disabled: false,
            type: "textarea",
            label: `Descripción de la tarea (${description?.length || 0}/1000)`,
            placeholder: "...",
            name: "description",
            validators: {
                maxLength: {
                    value: 1000,
                    message: "La descripción es demasiado larga",
                },
            },
        },
        finished: {
            condition: true,
            disabled: false,
            info: "Indica si la tarea ya está finalizada.",
            label: "Finalizada",
            type: "checkbox",
            name: "finished",
        },
        add_patient: {
            condition: true,
            disabled: false,
            info: "La tarea está relacionada con un paciente.",
            label: "Paciente asociado",
            type: "checkbox",
            name: "add_patient",
        },
        patient_selector: {
            condition: patient,
            disabled: false,
            type: "patient_selector",
            watch: watch,
            filter: {
                clinic_id: activeClinicId,
                per_page: 5,
                order: "name",
            },
            setValue: setValue,
            onClientSelect: (data) => {
                setValue("patient_id", data.patient_id);
                setValue("patient_name", data.name);
                setValue("patient_surnames", data.surnames);
                setValue("patient_email", data.email);
                setValue("patient_phone_number", data.phone_number);
            },
        }
    };

    return (
        <div className="sm:container p-3 mx-auto my-5">
            <h2 className="text-primary text-2xl">Registro de Tarea</h2>
            <form className="mt-5 mb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>

                <div className="mt-5 flex space-x-2 sm:space-x-4">
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit(postTask)}
                        disabled={isSubmitting}
                    >
                        Confirmar
                    </button>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmitWithoutValidation(
                            navigate,
                            "/task"
                        )}
                        disabled={isSubmitting}
                    >
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    );
}

export default RegisterTask;