import { useEffect } from "react";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { handleSubmit } from "../../auxiliar/auxFunctions";
import { getSession } from "../../actions/session";

const DisplaySessionInCashRegister = ({
  sessionId,
  openTooltipEdit,
  boolRefresh,
}) => {
  const { register, fetchFormValues, watch, errors } = useFormWithDisable();
  const session = watch();
  const formConfig = {
    session_type_id: {
      condition: true,
      disabled: true,
      type: "session_type_selector",
      label: "Tipo de sesión",
      name: "session_type_id",
    },
    employee_id: {
      condition: true,
      disabled: true,
      type: "physio_selector",
      label: "Fisioterapeuta",
      name: "employee_id",
    },
    date: {
      condition: true,
      disabled: true,
      type: "date",
      label: "Fecha",
      name: "date",
    },
  };

  useEffect(() => {
    if (sessionId) fetchFormValues(getSession, sessionId);
  }, [sessionId, boolRefresh]);

  return (
    <div className={"sm:container p-3 mx-auto my-5"}>
      <h2 className="text-primary text-2xl">Sesión</h2>
      <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
        <CustomForm
          formConfig={formConfig}
          errors={errors}
          register={register}
        />
      </form>

      <div className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmit(openTooltipEdit.current, {
            ...session,
            event_type: "individual",
            startAt: session.start_datetime,
            endAt: session.finish_datetime,
            id: session.session_id,
          })}
        >
          Editar
        </button>
      </div>
    </div>
  );
};

export default DisplaySessionInCashRegister;
