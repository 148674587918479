import Hero from '../../components/general/aboutUs/Hero';
import MissionVision from '../../components/general/aboutUs/MissionVision';
import Values from '../../components/general/aboutUs/Values';
import Partners from "../../components/general/aboutUs/Partners";

const AboutUs = () => {
    return <div>
        <Hero variant='physio' />
        <MissionVision />
        <Values />
        <Partners />
    </div>
}

export default AboutUs;