import { useRef, useState } from "react";

import EditPatient from "../../../components/patient/EditPatient";
import OverviewSessionsInPatient from "../../../components/patient/OverviewSessionsInPatient";
import OverviewClassesInPatient from "../../../components/patient/OverviewClassesInPatient";
import OverviewAcquiredVoucherInPatient from "../../../components/patient/OverviewAcquiredVoucherInPatient";
import OverviewCashRegistersInPatient from "../../../components/patient/OverviewCashRegistersInPatient";
import OverviewDocuments from "../../../components/patient/document/OverviewDocuments";
import RegisterSessionPopup from "../../../components/services/session/popupRegisterSession/RegisterSessionPopup";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";

const PatientCard = () => {
	const openTooltipRegister = useRef();
	const openTooltipEdit = useRef();
	const [refreshBool, setRefreshBool] = useState(false);
	const refresh = () => setRefreshBool(!refreshBool);

	return (
		<div>
			<EditPatient />

			<OverviewDocuments />

			<OverviewSessionsInPatient
				refreshBool={refreshBool}
				openTooltipRegister={openTooltipRegister}
				openTooltipEdit={openTooltipEdit}
			/>

			<OverviewClassesInPatient
				refreshBool={refreshBool}
				openTooltipEdit={openTooltipEdit}
			/>

			<OverviewAcquiredVoucherInPatient refreshBool={refreshBool} />

			<OverviewCashRegistersInPatient
				refresh={refresh}
				refreshBool={refreshBool}
			/>

			<RegisterSessionPopup childFunc={openTooltipRegister} refresh={refresh} />

			<EditSessionPopup childFunc={openTooltipEdit} refresh={refresh} />
		</div>
	);
};

export default PatientCard;
