import axios from "axios";

import { isDefined } from "../auxiliar/formatValidators";

export const getAllCashRegisters = (params) => {
	const { reqParams, validParams } = cleanParams(params);

	return async () => {
		if (!validParams) return Promise.resolve({ waiting: true });

		return axios
			.get(`/api/managers/cash_registers/`, { params: reqParams })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getCashRegister = (cashRegisterId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.get(`/api/managers/cash_registers/${cashRegisterId}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status))
					navigate(`/unknown?entity=cash_register&id=${cashRegisterId}`);
			});
	};
};

export const downloadCashRegisters = (params) => {
	const { reqParams, validParams } = cleanParams(params);

	return async () => {
		if (!validParams) return Promise.resolve({ waiting: true });

		axios
			.get("/api/managers/data_export/cash_registers", {
				params: reqParams,
				responseType: "blob",
			})
			.then((response) => {
				const href = URL.createObjectURL(response.data);

				// create "a" HTML element with href to file & click
				const link = document.createElement("a");
				link.href = href;
				link.setAttribute("download", "Albaranes.xlsx");
				document.body.appendChild(link);
				link.click();

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			});
	};
};

const cleanParams = (params) => {
	let reqParams = Object.assign({}, params);

	if (!isDefined(reqParams.patient_surnames)) delete reqParams.patient_surnames;
	if (!isDefined(reqParams.patient_name)) delete reqParams.patient_name;
	if (!isDefined(reqParams.payment_method) || ["select", "all"].includes(reqParams.payment_method))
		delete reqParams.payment_method;
	if (!isDefined(reqParams.employee_id) || ["select", "all"].includes(reqParams.employee_id))
		delete reqParams.employee_id;
	if (!isDefined(reqParams.duration)) delete reqParams.duration;
	if (!isDefined(reqParams.finishing_date)) delete reqParams.finishing_date;
	if (!isDefined(reqParams.starting_date)) delete reqParams.starting_date;

	const validParams =
		!isDefined(reqParams.temporality) ||
		reqParams.temporality === "all" ||
		reqParams.temporality === "custom" ||
		(reqParams.temporality === "current" && isDefined(reqParams.period)) ||
		(reqParams.temporality === "future" && isDefined(reqParams.period)) ||
		(reqParams.temporality === "past" && isDefined(reqParams.period));

	return { reqParams, validParams };
};
