import { useEffect, useState } from "react";

function useScreenSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	let isMounted = true;

	function handleResize() {
		if (isMounted) {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();

		return () => {
			isMounted = false;
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return windowSize;
}

export default useScreenSize;
