import { themeTypes } from "./types";

export const themeReducer = (state = {}, action) => {
	switch (action.type) {
		case themeTypes.save:
			return action.payload;
		case themeTypes.clean:
			return {};
		default:
			return state;
	}
};

export const dispatchThemeSave = (dispatch, isLight) => {
	dispatch({
		type: themeTypes.save,
		payload: {
			lightTheme: isLight,
		},
	});
};

export const dispatchThemeClean = (dispatch) => {
	dispatch({
		type: themeTypes.clean,
	});
};
