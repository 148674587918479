import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { handleSubmit } from "../../auxiliar/auxFunctions";
import { getAcquiredVoucher } from "../../actions/acquiredVoucher";

const DisplayAcquiredVoucherInCashRegister = ({ acquiredVoucherId }) => {
  const { register, fetchFormValues, errors } = useFormWithDisable();
  const navigate = useNavigate();
  const formConfig = {
    voucher_type_id: {
      condition: true,
      disabled: true,
      type: "voucher_selector",
      label: "Tipo",
      name: "voucher_type_id",
    },
    remaining_sessions: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Sesiones restantes",
      name: "remaining_sessions",
    },
    due_date: {
      condition: true,
      disabled: true,
      type: "date",
      label: "Fecha de expiración",
      name: "due_date",
    },
  };

  useEffect(() => {
    if (acquiredVoucherId)
      fetchFormValues(getAcquiredVoucher, acquiredVoucherId);
  }, [acquiredVoucherId]);

  return (
    <div className={"sm:container p-3 mx-auto my-5"}>
      <h2 className="text-primary text-2xl">Bono Adquirido</h2>
      <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
        <CustomForm
          formConfig={formConfig}
          errors={errors}
          register={register}
        />
      </form>

      <div className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmit(
            navigate,
            `/acquired-voucher/${acquiredVoucherId}`
          )}
        >
          Detalles
        </button>
      </div>
    </div>
  );
};

export default DisplayAcquiredVoucherInCashRegister;
