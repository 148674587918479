import axios from "axios";
import { pick } from "lodash";

export const postPatientAuthorizedAcquiredVoucher = (
	data,
	clinicId,
	acquiredVoucherId,
	onSuccessRequest
) => {
	return async () => {
		return axios
			.post("/api/managers/patient_authorized_acquired_voucher/", {
				patient_id: data.patient_id,
				acquired_voucher_id: acquiredVoucherId,
				clinic_id: clinicId,
				...(data.patient_id === "new" && { new_patient_info: pick(data, ["name", "surnames", "email", "phone_number"]) }),
			})
			.then(() => {
				onSuccessRequest();
			})
			.catch(() => { });
	};
};

export const deletePatientAuthorizedAcquiredVoucher = (
	patientId,
	acquiredVoucherId,
	modalId,
	refresh
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/patient_authorized_acquired_voucher/acquired_voucher/${acquiredVoucherId}/patient/${patientId}`
			)
			.then(() => {
				document.getElementById(modalId).close();
				refresh();
			})
			.catch(() => { });
	};
};
