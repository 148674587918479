import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { getPatient } from "../../actions/patient";
import { handleSubmit } from "../../auxiliar/auxFunctions";

const DisplayPatientInCashRegister = ({ patientId }) => {
  const navigate = useNavigate();

  const { register, fetchFormValues, errors } = useFormWithDisable();

  const formConfig = {
    name: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
    },
    surnames: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Apellidos *",
      placeholder: "Apellidos",
      name: "surnames",
    },
    email: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Correo Electrónico",
      placeholder: "paciente@email.com",
      name: "email",
    },
    phone_number: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Número de teléfono",
      placeholder: "+34 XXX XXX XXX",
      name: "phone_number",
    },
  };

  useEffect(() => {
    if (patientId) fetchFormValues(getPatient, patientId);
  }, [patientId]);

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Paciente</h2>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>
      </form>

      <div className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmit(navigate, `/patient/${patientId}`)}
        >
          Detalles
        </button>
      </div>
    </div>
  );
};

export default DisplayPatientInCashRegister;
