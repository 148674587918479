import { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import { handleSubmit } from "../../auxiliar/auxFunctions";
import { getAllSessions } from "../../actions/session";
import { isDefined } from "../../auxiliar/formatValidators";

const OverviewSessionsInPatient = ({
  refreshBool,
  openTooltipRegister,
  openTooltipEdit,
}) => {
  const { patient_id: patientId } = useParams();
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);
  const [sessions, setSessions] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    per_page: 5,
    page: 1,
    clinic_id: activeClinicId,
    patient_id: patientId,
    order: "date",
  });

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllSessions,
    setSessions,
    setFilter,
    refreshBool
  );

  const onEditSessionClick = (session) => {
    openTooltipEdit.current({
      ...session,
      id: session.session_id,
      event_type: "individual",
      startAt: session.start_datetime,
      endAt: session.finish_datetime,
    });
  };

  const onNewSessionClick = () => {
    const date = new Date();
    openTooltipRegister.current(
      {
        patient_id: patientId,
        startAt: format(date, "yyyy-MM-dd'T'HH:mm").toString(),
        endAt: format(
          date.setTime(date.getTime() + 60 * 60 * 1000),
          "yyyy-MM-dd'T'HH:mm"
        ).toString(),
      },
      true
    );
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Sesiones</h2>

      {sessions.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(sessions.order) ||
        !isDefined(sessions.content) ||
        sessions.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay sesiones registradas, añade la primera haciendo click en el
          botón de abajo.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th className="hidden xs:table-cell">Horario</th>
                <th className="hidden md:table-cell">Fisioterapeuta</th>
                <th className="hidden sm:table-cell">Tipo</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {sessions.order.map((sessionId) => {
                const session = sessions.content && sessions.content[sessionId];

                return (
                  <tr
                    key={sessionId}
                    className={session.unpaid ? "bg-light-error" : ""}
                  >
                    <td>{session.start_datetime.substring(0, 10)}</td>
                    <td className="hidden sm:table-cell">
                      {session.start_datetime.substring(11, 16)}
                      {"-"}
                      {session.finish_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden xs:table-cell sm:hidden">
                      {session.start_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden md:table-cell">
                      {session.physio_name}
                    </td>
                    <td className="hidden sm:table-cell">{session.type}</td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit(onEditSessionClick, session)}
                      >
                        Detalles
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <form className="mt-5 flex justify-between">
        <div className="flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(onNewSessionClick)}
          >
            Nueva Sesión
          </button>
        </div>

        <PaginatorComponent
          pagination={sessions.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </form>
    </div>
  );
};

export default OverviewSessionsInPatient;
