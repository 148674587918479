import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <main className="max-w-3xl my-5 md:mx-auto">
      <div className="p-5">
        <h1 className="text-3xl pb-6 font-bold">
          Política de Privacidad de MiFisio Agenda
        </h1>
        <p className="whitespace-pre-wrap pb-4">
          En este documento se incluye la política de privacidad. También le
          recomendamos leer la{" "}
          <Link to="/cookies-policy" className="link">
            política de cookies
          </Link>{" "}
          y el{" "}
          <Link to="/legal" className="link">
            aviso legal
          </Link>
          .
        </p>
        <p className="whitespace-pre-wrap pb-4">
          La presente Política de Privacidad ha sido desarrollada teniendo en
          cuenta lo dispuesto por la Ley Orgánica de Protección de Datos de
          carácter personal en vigor, así como por el Reglamento 2016/679 del
          Parlamento Europeo y del consejo del 27 de abril de 2016 relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de datos personales y a la circulación de estos datos, en adelante el
          RGPD.
        </p>
        <p className="whitespace-pre-wrap pb-6">
          Esta Política de Privacidad tiene por objeto poner en conocimiento de
          los titulares de los datos personales, respecto de los cuales se está
          recabando información, los aspectos específicos relativos al
          tratamiento sus datos, entre otras cosas, las finalidades de los
          tratamientos, los datos de contacto para ejercer los derechos que le
          asisten, los plazos de conservación de la información y las medidas de
          seguridad entre otras cosas.
        </p>

        <h2 className="text-xl pb-4 font-bold">Responsable del Tratamiento</h2>
        <p className="whitespace-pre-wrap pb-4">
          En términos de protección de datos Mfisio Schedula S.L., debe ser
          considerado Responsable del Tratamiento, en relación a los
          ficheros/tratamientos identificados en la presente política,
          concretamente en el apartado Tratamientos de datos.
        </p>
        <p className="whitespace-pre-wrap">
          A continuación, se indican los datos identificativos del titular del
          presente sitio web:
        </p>
        <ul className="list-disc pb-6 mx-8">
          <li>Responsable del Tratamiento: Mfisio Schedula S.L.</li>
          <li>
            Dirección postal: C/ Emilio Ferrari, 10. Bajo izq., 28017, Madrid,
            (Madrid).
          </li>
          <li>Dirección electrónica: info@mifisioagenda.com</li>
        </ul>

        <h2 className="text-xl pb-4 font-bold">Tratamientos de datos</h2>
        <p className="whitespace-pre-wrap pb-4">
          Los datos de carácter personal que se soliciten, en su caso,
          consistirán únicamente en aquellos estrictamente imprescindibles para
          identificar y atender la solicitud realizada por el titular de los
          mismos, en adelante el interesado. Dicha información será tratada de
          forma leal, lícita y transparente en relación con el interesado. Por
          otra parte, los datos personales serán recogidos para finalidades
          determinadas explícitas y legítimas, no siendo tratados ulteriormente
          de manera incompatible con dichos fines.
        </p>
        <p className="whitespace-pre-wrap pb-4">
          Los datos recogidos de cada interesado serán adecuados, pertinentes y
          no excesivos en relación a las finalidades correspondientes para cada
          caso, y serán actualizados siempre que sea necesario.
        </p>
        <p className="whitespace-pre-wrap pb-2">
          El titular de los datos será informado, con carácter previo a la
          recogida de sus datos, de los extremos generales regulados en esta
          política a fin de que pueda prestar el consentimiento expreso, preciso
          e inequívoco para el tratamiento de sus datos, conforme a los
          siguientes aspectos:
        </p>
        <ul className="list-disc pb-6 mx-8">
          <li>
            <span className="font-bold">Finalidades del tratamiento</span>: Las
            finalidades explícitas para las cuales se llevan a cabo cada uno de
            los tratamientos vienen recogidas en las cláusulas informativas
            incorporadas en cada una de las vías de toma de datos (formularios
            web, formularios en papel, locuciones o carteles y notas
            informativas). No obstante, los datos de carácter personal del
            interesado serán tratados con la exclusiva finalidad de
            proporcionarles una respuesta efectiva y atender las solicitudes
            practicadas por el usuario, especificadas junto a la opción,
            servicio, formulario o sistema de toma de datos que el titular
            utilice.
          </li>
          <li>
            <span className="font-bold">Legitimación</span>: Por regla general,
            previo al tratamiento de los datos personales, Mfisio Schedula S.L.
            obtiene consentimiento expreso e inequívoco del titular de los
            mismos, mediante la incorporación de cláusulas de consentimiento
            informado en los diferentes sistemas de recogida de información. No
            obstante, en caso de que no se requiera el consentimiento del
            interesado, la base legitimadora del tratamiento en la cual se
            ampara Mfisio Schedula S.L. es la existencia de una ley o norma
            específica que autorice o exija el tratamiento de los datos del
            interesado.
          </li>
          <li>
            <span className="font-bold">Destinatarios</span>: Por regla general,
            Mfisio Schedula S.L. no procede a la cesión o comunicación de los
            datos a terceras entidades, salvo las requeridas legalmente, no
            obstante, en caso de que fuera necesario, dichas cesiones o
            comunicaciones de datos se informan al interesado a través de las
            cláusulas de consentimiento informado contenidas en las diferentes
            vías de recogida de datos personales.
          </li>
          <li>
            <span className="font-bold">Procedencia</span>: Por regla general,
            los datos personales se recogen siempre directamente del interesado,
            no obstante, en determinadas excepciones, los datos pueden ser
            recogidos a través de terceras personas, entidades o servicios
            diferentes del interesado. En este sentido, este extremo será
            trasladado al interesado a través de las cláusulas de consentimiento
            informado contenidas en las diferentes vías de recogida de
            información y dentro de un plazo razonable, una vez obtenidos los
            datos, y a más tardar dentro de un mes.
          </li>
          <li>
            <span className="font-bold">Plazos de conservación</span>: La
            información recabada del interesado será conservada mientras sea
            necesaria para cumplir con la finalidad para la cual fueron
            recabados los datos personales, de forma que, una vez cumplida la
            finalidad los datos serán cancelados. Dicha cancelación dará lugar
            al bloqueo de los datos conservándose únicamente a disposición de
            las AAPP, Jueces y Tribunales, para atender las posibles
            responsabilidades nacidas del tratamiento, durante el plazo de
            prescripción de éstas, cumplido el citado plazo se procederá a la
            destrucción de la información. A título informativo, a continuación,
            se recogen los plazos legales de conservación de la información en
            relación a diferentes materias:
            <div className="overflow-x-auto">
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Documento</th>
                    <th className="px-4 py-2">Plazo</th>
                    <th className="px-4 py-2">Referencia legal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-4 py-2">
                      Documentación de carácter laboral o relacionada con la
                      seguridad social
                    </td>
                    <td className="border px-4 py-2 whitespace-nowrap">4 años</td>
                    <td className="border px-4 py-2">
                      Art. 21 del Real Decreto Legislativo 5/2000, de 4 de agosto,
                      por el que se aprueba el texto refundido de la Ley sobre
                      Infracciones y Sanciones en el Orden Social
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">
                      Documentación contable y fiscal a efectos mercantiles
                    </td>
                    <td className="border px-4 py-2 whitespace-nowrap">6 años</td>
                    <td className="border px-4 py-2">Art. 30 Código Comercio</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">
                      Documentación contable y fiscal a efectos fiscales
                    </td>
                    <td className="border px-4 py-2 whitespace-nowrap">4 años</td>
                    <td className="border px-4 py-2">
                      Artículos 66 a 70 Ley General Tributaria
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">
                      Control de accesos a edificios
                    </td>
                    <td className="border px-4 py-2 whitespace-nowrap">1 mes</td>
                    <td className="border px-4 py-2">
                      Guía sobre el uso de videocámaras para seguridad y otras
                      finalidades de la AEPD
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">Videovigilancia</td>
                    <td className="border px-4 py-2 whitespace-nowrap">1 mes</td>
                    <td className="border px-4 py-2">
                      Guía sobre el uso de videocámaras para seguridad y otras
                      finalidades de la AEPD. Ley Orgánica 4/1997, de 4 de agosto,
                      por la que se regula la utilización de videocámaras por las
                      fuerzas y cuerpos de seguridad en lugares públicos. Ley
                      Orgánica 4/2015, de 30 de marzo, de protección de la
                      seguridad ciudadana
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>

        <h2 className="text-xl pb-4 font-bold">Datos de navegación</h2>
        <p className="whitespace-pre-wrap pb-4">
          En relación a los datos de navegación que se puedan tratar a través
          del sitio web, en caso de que se recojan datos sometidos a la
          normativa, se recomienda consultar la Política de Cookies publicada en
          nuestro sitio web.
        </p>

        <h2 className="text-xl pb-4 font-bold">Derechos de los interesados</h2>
        <p className="whitespace-pre-wrap pb-4">
          La normativa en materia de protección de datos otorga una serie de
          derechos a los interesados o titulares de los datos, usuarios del
          sitio web o usuarios de los perfiles de las redes sociales de Mfisio
          Schedula S.L.
        </p>

        <p className="whitespace-pre-wrap pb-2">
          Estos derechos que asisten a las personas interesadas son los
          siguientes:
        </p>
        <ul className="list-disc pb-6 mx-8">
          <li>
            <span className="font-bold">Derecho de acceso</span>: Derecho a
            obtener información sobre si sus propios datos están siendo objeto
            de tratamiento, la finalidad del tratamiento que se esté realizando,
            las categorías de datos que se trate, los destinatarios o categorías
            de destinatarios, el plazo de conservación y el origen de dichos
            datos.
          </li>
          <li>
            <span className="font-bold">Derecho de rectificación</span>: Derecho
            a obtener la rectificación de los datos personales inexactos o
            incompletos.
          </li>
          <li>
            <span className="font-bold">Derecho de supresión</span>: derecho a
            obtener la supresión de los datos en los siguientes supuestos:
            <ul className="list-disc mx-8">
              <li>
                Cuando los datos ya no sean necesarios para la finalidad para la
                cual fueron recabados.
              </li>
              <li>Cuando el titular de los mismos retire el consentimiento.</li>
              <li>Cuando el interesado se oponga al tratamiento.</li>
              <li>
                Cuando deban suprimirse en cumplimiento de una obligación legal.
              </li>
              <li>
                Cuando los datos se hayan obtenido en virtud de un servicio de
                sociedad de la información en base a lo dispuesto en el art. 8
                apdo. 1 del Reglamento Europeo sobre Protección de datos.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-bold">Derecho de oposición</span>: Derecho a
            oponerse a un determinado tratamiento basado en el consentimiento
            del interesado.
          </li>
          <li>
            <span className="font-bold">Derecho de limitación</span>: Derecho a
            obtener la limitación del tratamiento de los datos cuando se de
            alguno de los siguientes supuestos:
            <ul className="list-disc mx-8">
              <li>
                Cuando el interesado impugne la exactitud de los datos
                personales, durante un plazo que permita a la empresa verificar
                la exactitud de los mismos.
              </li>
              <li>
                Cuando el tratamiento sea ilícito y el interesado se oponga a la
                supresión de los datos.
              </li>
              <li>
                Cuando la empresa ya no necesite los datos para los fines para
                los que fueron recabados, pero el interesado los necesite para
                la formulación, el ejercicio o la defensa de reclamaciones.
              </li>
              <li>
                Cuando el interesado se haya opuesto al tratamiento mientras se
                verifica si los motivos legítimos de la empresa prevalecen sobre
                los del interesado.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-bold">Derecho a la portabilidad</span>:
            Derecho a obtener los datos en un formato estructurado, de uso común
            y de lectura mecánica, y a transmitirlos a otro responsable del
            tratamiento cuando:
            <ul className="list-disc mx-8">
              <li>El tratamiento esté basado en el consentimiento.</li>
              <li>El tratamiento se efectúe por medios automatizados</li>
            </ul>
          </li>
          <li>
            <span className="font-bold">
              Derecho a presentar una reclamación
            </span>
            : Derecho a presentar una reclamación ante la autoridad de control
            competente
          </li>
        </ul>
        <p className="whitespace-pre-wrap pb-4">
          Los interesados podrán ejercitar los derechos indicados, dirigiéndose
          a Mfisio Schedula S.L., mediante escrito, remitido a la siguiente
          dirección: C/ Emilio Ferrari, 10. Bajo izq. 28017 Madrid (Madrid)
          indicando en la línea de Asunto el derecho que desea ejercitar.
        </p>
        <p className="whitespace-pre-wrap pb-6">
          En este sentido Mfisio Schedula S.L. atenderá su solicitud a la mayor
          brevedad posible y teniendo en cuenta los plazos previstos en la
          normativa en materia de protección de datos.
        </p>

        <h2 className="text-xl pb-4 font-bold">Seguridad</h2>
        <p className="whitespace-pre-wrap pb-4">
          Las medidas de seguridad adoptadas por Mfisio Schedula S.L. son
          aquellas requeridas, de conformidad con lo establecido en el artículo
          32 del RGPD. En este sentido, Mfisio Schedula S.L., teniendo en cuenta
          el estado de la técnica, los costes de aplicación y la naturaleza, el
          alcance, el contexto y los fines del tratamiento, así como los riesgos
          de probabilidad y gravedad variables para los derechos y las
          libertades de las personas físicas, tiene establecidas las medidas
          técnicas y organizativas apropiadas para garantizar el nivel de
          seguridad adecuado al riesgo existente.
        </p>
        <p className="whitespace-pre-wrap pb-2">
          En todo caso, Mfisio Schedula S.L. tiene implementados los mecanismos
          suficientes para:
        </p>
        <ul className="list-disc pb-6 mx-8">
          <li>
            Garantizar la confidencialidad, integridad, disponibilidad y
            resiliencia permanentes de los sistemas y servicios de tratamiento.
          </li>
          <li>
            Restaurar la disponibilidad y el acceso a los datos personales de
            forma rápida, en caso de incidente físico o técnico.
          </li>
          <li>
            Verificar, evaluar y valorar, de forma regular, la eficacia de las
            medidas técnicas y organizativas implantadas para garantizar la
            seguridad del tratamiento.
          </li>
          <li>Pseudonimizar y cifrar los datos personales, en su caso.</li>
        </ul>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
