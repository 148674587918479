import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormSelector = ({ customKey, register, config, errors }) => {
  return (
    <div key={customKey} className={`form-control ${config.classname}`}>
      {config.label && (
        <label htmlFor={config.name} className="label-text">
          {config.label}{" "}
          {config.info && (
            <div className="tooltip" data-tip={config.info}>
              <FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
            </div>
          )}
        </label>
      )}
      <div className={`${errors && "tooltip"}`} data-tip={errors?.message}>
        <select
          className={`select select-primary text-left w-full ${errors ? "select-error" : "select-primary"
            } disabled:bg-disabled-bg disabled:text-disabled-text`}
          disabled={config.disabled}
          defaultValue={config.defaultValue}
          name={config.name}
          id={config.name}
          {...register(config.name, config.validators)}
        >
          {config.options.map((elem) => {
            return (
              <option key={elem.key} value={elem.key}>
                {elem.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FormSelector;
