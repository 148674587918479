import { useRef, useState } from "react";

const faqList = [
    {
        question: "¿Puedo utilizar MiFisio Agenda en dispositivos Android o iOS?",
        answer: <div className="space-y-2 leading-relaxed">
            Claro, MiFisio Agenda está disponible en versión web para cualquier dispositivo. Además tenemos aplicaciones de Windows, Android e iOS para que accedas desde tu dispositivo favorito, o desde todos a la vez.
        </div>,
    },
    {
        question: "¿Puedo tener algún coste adicional e inesperado?",
        answer: <div className="space-y-2 leading-relaxed">
            No, todo está incluido en un único pago mensual, sin letra pequeña. Como fisioterapeutas nuestro objetivo es faciliarte la vida, y hemos decidido empezar por el precio.
        </div>,
    },
    {
        question: "¿Puedo importar los datos de los pacientes, sesiones y bonos desde otra plataforma?",
        answer: <div className="space-y-2 leading-relaxed">
            ¡Por supuesto! <a className="link" href="mailto:soporte@mifisioagenda.com">Ponte en contacto con nosotros</a> y nosotros nos encargaremos de todo el proceso.
        </div>,
    },
    {
        question: "¿Cómo gestiona MiFisio Agenda la facturación?",
        answer: <div className="space-y-2 leading-relaxed">
            Al realizar un pago se generan automáticamente los albaranes y, con un solo click, puedes crear la factura correspondiente. Una vez hecho esto puedes descargar o enviar la factura al paciente. Además puedes descargar listas de facturas y albaranes aplicando distintos filtros. MiFisio Agenda cumple con la normativa vigente en la creación y emisión de facturas electrónicas.
        </div>,
    },
    {
        question: "¿Qué datos del paciente puedo almacenar en MiFisio Agenda?",
        answer: <div className="space-y-2 leading-relaxed">
            En MiFisio Agenda puedes almacenar la información del cliente, añadir notas y comentarios, y subir archivos como consentiemientos informados, pruebas médicas o historias clínicas.
        </div>,
    },
    {
        question: "¿Puedo automatizar los recordatorios de citas?",
        answer: <div className="space-y-2 leading-relaxed">
            Los recordatorios de cita se envían vía Whatsapp. Por el momento desde la propia sesión puedes mandar un recordatorio al paciente, pero estamos trabajando en ofrecer una automatización completa.
        </div>,
    },
    {
        question: "¿Pueden reservar online los pacientes?",
        answer: <div className="space-y-2 leading-relaxed">
            Sí, puedes seleccionar qué fisioterapeutas y qué tipos de sesión quieres que aparezcan en las reservas online. Además, una vez recibes una solicitud de reserva puedes aceptarla o comunicarte con tu paciente a través de la plataforma.
        </div>
    },   
    {
        question: "Tengo otra pregunta",
        answer: <div className="space-y-2 leading-relaxed">
            Sin problemas, contacta con nosotros en <a className="link" href="mailto:soporte@mifisioagenda.com">soporte@mifisioagenda.com</a>
        </div>
    },
];

const FaqItem = ({ item }) => {
    const accordion = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <li>
            <button
                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
                aria-expanded={isOpen}
            >
                <span
                    className={`flex-1 text-base-content ${isOpen ? "text-secondary" : ""}`}
                >
                    {item?.question}
                </span>
                <svg
                    className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center transition duration-200 ease-out ${isOpen && "rotate-180"}`}
                    />
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && "rotate-180 hidden"}`}
                    />
                </svg>
            </button>

            <div
                ref={accordion}
                className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
                style={
                    isOpen
                        ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
                        : { maxHeight: 0, opacity: 0 }
                }
            >
                <div className="pb-5 leading-relaxed">{item?.answer}</div>
            </div>
        </li>
    );
};

const FAQ = () => {
    return (
        <section className="bg-base-200" id="faq">
            <div className="py-24 px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
                <div className="flex flex-col text-left basis-1/3">
                    <p className="font-bold text-4xl text-secondary mb-8">FAQ</p>
                    <p className="sm:text-4xl text-2xl font-bold text-base-content">
                        Preguntas frecuentes
                    </p>
                </div>

                <ul className="basis-2/3">
                    {faqList.map((item, i) => (
                        <FaqItem key={i} item={item} />
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default FAQ;