import { useMatch, Link } from "react-router-dom";
import { getDynamicLink } from "../../../auxiliar/auxFunctions";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";

const Footer = ({ customDisplay = false }) => {
  const landing = useMatch("/landing-page");
  const agenda = useMatch("/agenda") 
  const reservationAgenda = useMatch("/reservation/agenda");
  const reservation = useMatch("/reservation/*");
  const params = useQueryParams();

  if (customDisplay || (!landing && !agenda && !reservationAgenda))
    return (
      <footer className="footer footer-center py-3 bg-base-300 text-base-content rounded">
        <div>
          <nav className="grid grid-flow-col gap-4">
            <Link
              className="link link-hover"
              to={getDynamicLink(
                reservation ? "/reservation" : "",
                "about-us",
                params
              )}
            >
              Sobre nosotros
            </Link>
            <Link
              className="link link-hover"
              to="mailto:soporte@mifisioagenda.com"
            >
              Contacto
            </Link>
            <Link
              className="link link-hover"
              to={getDynamicLink(
                reservation ? "/reservation" : "",
                "privacy-policy",
                params
              )}
            >
              Política de privacidad
            </Link>
            <Link
              className="link link-hover"
              to={getDynamicLink(
                reservation ? "/reservation" : "",
                "health-check",
                params
              )}
            >
              Estado de la aplicación
            </Link>
          </nav>

          <aside>
            <p>
              Copyright © 2023 - Todos los derechos reservados por MFISIO SCHEDULA SL
            </p>
          </aside>

          <nav>
            <div className="grid grid-flow-col gap-4">
              <a href="https://www.youtube.com/@mifisioagenda" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current">
                  <path
                    d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/mifisio-agenda" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current">
                  <path
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path>
                </svg>
              </a>
            </div>
          </nav>
        </div>
      </footer>
    );

  return null;
};

export default Footer;
