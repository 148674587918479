import axios from "axios";

export const backendHealthCheck = (setActiveBackend) => {
	return async () => {
		return axios.get(`/api/health_check/backend`).then((response) => {
			if (response.status === 200) {
				setActiveBackend(true);
			}
		});
	};
};

export const databaseHealthCheck = (setActiveDatabase) => {
	return async () => {
		return axios.get(`/api/health_check/database`).then((response) => {
			if (response.status === 200) {
				setActiveDatabase(true);
			}
		});
	};
};
