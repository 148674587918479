import axios from "axios";

export const getAllPhysiotherapists = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/employees/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getPublicPhysiotherapists = (params) => {
	return async () => {
		return axios
			.get(`/api/anonymous/employees`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getPhysiotherapist = (physiotherapistId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(physiotherapistId)) {
			navigate(`/unknown?entity=physio&id=${physiotherapistId}`);
			return;
		}

		return axios
			.get(`/api/managers/employees/${physiotherapistId}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=physio&id=${physiotherapistId}`);
			});
	};
};

export const postPhysiotherapist = (data, clinicId) => {
	return async (...args) => {
		const { navigate } = args[2];
		const { add_schedule, ...reqData } = data

		return axios
			.post("/api/managers/employees/", { ...reqData, clinic_id: clinicId })
			.then(() => {
				navigate("/physiotherapist");
			})
			.catch(() => { });
	};
};

export const putPhysiotherapist = (data, physioId, disableEdit) => {
	return async () => {
		return axios
			.put(`/api/managers/employees/${physioId}`, { new_info: data })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deletePhysiotherapist = (physioId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/employees/${physioId}`)
			.then(() => {
				navigate("/physiotherapist");
			})
			.catch(() => { });
	};
};

export const putPhysiotherapistSchedule = (
	physiotherapistId,
	data,
	setUpdateDisabled,
	setDisabled
) => {
	let req_data = JSON.parse(JSON.stringify(data));

	return async () => {
		return axios
			.put(
				`/api/managers/employees/${physiotherapistId}/update_schedule`,
				req_data
			)
			.then(() => {
				setDisabled(true);
				setUpdateDisabled(false);
			})
			.catch(() => {
				setUpdateDisabled(false);
			});
	};
};
