import { Link } from "react-router-dom";

const InfoBookings = () => {
    return (
        <section className="max-w-7xl mx-auto bg-base-100 grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-10">
            <div className="flex flex-col gap-10 lg:gap-14 text-center items-center">
                <h1 className="font-extrabold text-4xl lg:text-6xl tracking-tight text-primary">
                    Reservas Online
                </h1>
                <div>
                    <img src={require("../../../media/icon/icon_125.png")} alt={"Logo"} />
                </div>
            </div>
            <div className="md:col-span-2">
                <div className="flex my-5 md:my-20 justify-center">
                    <div className="bg-base-200 shadow-xl rounded-lg p-10">
                        <p className="text-secondary text-2xl my-5">
                            ¿Qué es esto?
                        </p>

                        <p className=" text-base lg:text-lg my-3">
                            Suponemos que si has llegado aquí es porque tu clínica de confianza
                            te ha mandado un enlace para que realices una reserva online. Si ya sabes
                            como va, no hace falta que sigas leyendo, pero en los próximos parráfos hay
                            una pequeña explicación de como funciona este servicio.
                        </p>

                        <p className=" text-base lg:text-lg my-3">
                            La idea principal es que los fisioterapeutas y los pacientes puedan interactuar
                            de una forma rápida y sin complicaciones de forma remota. Para ello ponemos
                            a tu disposición diferentes pantallas que te permiten saber todo lo que necesitas:
                        </p>


                        <p className="text-base lg:text-lg my-3 px-3">
                            <ul className="list-disc">
                                <li>
                                    Agenda: Aquí puedes ver las horas disponibles y solicitar una cita introduciendo tus
                                    datos de contacto. Una vez lo hagas, la clínica tendrá que confirmarla y te llegará
                                    una notificación una vez lo hagan.
                                </li>
                                <li>
                                    Sesión: En el caso de que estes accediendo desde el enlace de una sesión ya solicitada
                                    podrás consultar los datos de la misma en esta pantalla.
                                </li>
                                <li>
                                    Clínica: La información relevante de la clínica en la que estás solicitando la sesión
                                    aparecerá aqui, incluyendo dirección y datos de contacto.
                                </li>
                            </ul>
                        </p>

                        <a className="text-base lg:text-lg my-3">
                            Si tienes cualquier sugerencia, has encontrado un error o quieres ponerte
                            en contacto con nosotros puedes mandar un mensaje a
                            <a href={"mailto:info@mifisioagenda.com"}>info@mifisioagenda.com</a>. Si
                            eres un fisioterapeuta y quieres saber más sobre nosotros haz click {" "}
                            <Link to="/info" className="link">aquí</Link>.
                        </a>

                    </div>
                </div>
            </div>
        </section >
    )
}

export default InfoBookings;