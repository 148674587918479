import { isValid } from "date-fns";

export const isDefined = (value) => {
	return value !== "" && value !== undefined && value !== null;
};

export const checkMailFormat = (email) => {
	const valid = email.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi);
	if (!valid) {
		return "El correo introducido no es válido";
	}
};

export const checkPhoneNumberFormat = (number) => {
	if (!isDefined(number)) return;

	const valid = !(number.trim().length > 15 || !number.match(/^[+]?\d+$/));
	if (!valid) {
		return "El número de teléfono no es válido";
	}
};

export const checkDNIFormat = (dni) => {
	//const valid = !(dni.trim().length !== 9 || !dni.match(/^[a-z0-9]+$/i));
	const valid = dni.trim().length > 5 && dni.trim().length < 20

	if (!valid) {
		return "El DNI introducido no es válido";
	}
};

export const checkCIFFormat = (cif) => {
	//const valid = !(cif.trim().length !== 9 || !cif.match(/^[a-z0-9]+$/i));
	const valid = cif.trim().length > 5 && cif.trim().length < 20

	if (!valid) {
		return "El CIF introducido no es válido";
	}
};

export const checkFile = (filesList) => {
	if (filesList.length > 1) {
		return "Solo puedes subir un archivo";
	}

	const file = filesList[0];
	if (file.size / 1024 / 1024 > 25) {
		return "El archivo es demasiado grande";
	}
};

export const checkLogo = (filesList) => {
	if (filesList.length > 1) {
		return "Solo puedes subir un archivo";
	}

	const file = filesList[0];
	if (file.type === "application/jpg" || file.type === "application/png") {
		return "El archivo tiene que ser una imagen en formato .jpg o .png";
	} else if (file.size / 1024 / 1024 > 25) {
		return "El archivo es demasiado grande";
	}
};

export const maxLength = (max) => (value) => {
	if (!isDefined(value)) return;

	if (value.length > max) {
		return `El máximo de caracteres aceptado es ${max}`;
	}
};

export const checkDateOrRange = (dateRange) => {
	const validDate = !isDefined(dateRange.length) && isValid(dateRange)
	const validRange = isDefined(dateRange.length) && isValid(dateRange[0]) && isValid(dateRange[1])

	if (validDate) return "date"
	if (validRange) return "range"
	return
}

export const timeOrder = (start, end) => {
	if (start === "" || end === "") return;
	if (new Date(`1970-01-01T${start}Z`) > new Date(`1970-01-01T${end}Z`)) {
		return "La hora de salida no puede ser menor a la de entrada";
	}
}
