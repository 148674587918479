import { useNavigate } from "react-router-dom";

import invoice_example from "../../media/Tutorial/invoice_example.png";
import { handleSubmit } from "../../auxiliar/auxFunctions";

const InvoiceTemplateTutorial = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="max-w-7xl mx-auto bg-base-100 grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-16 lg:gap-20 px-4 py-8 lg:py-10">
        <div className="flex flex-col gap-4 lg:gap-8 text-center items-center">
          <h1 className="font-extrabold text-4xl lg:text-6xl tracking-tight text-primary">
            Factura
          </h1>
          <div>
            <img src={require("../../media/Tutorial/invoice_example.png")} alt={"Logo"} />
          </div>
        </div>
        <div className="md:col-span-2">
          <div className="flex my-5 md:my-10 justify-center">
            <div className="bg-base-200 shadow-xl rounded-lg p-10">
              <h3 className="text-secondary text-2xl my-5">
                Tutoriales - Plantilla de Factura
              </h3>

              <p className="text-base lg:text-lg my-3">
                MiFisio Agenda permite generar y enviar facturas a los pacientes,
                pero una configuración incial es recomendable. En este tutorial se
                explica cómo personalizar la plantilla que ofrecemos por defecto.
              </p>

              <p className="text-base lg:text-lg my-3">
                Como siempre, el equipo de MiFisio Agenda está a tu disposición, por
                lo que si tienes cualquier tipo de duda o sugerencia, no dudes en
                contactar con nosotros.
              </p>

              <p className="text-base lg:text-lg my-3">
                En la imagen se muestra un ejemplo de la factura que se puede generar con
                MiFisio Agenda y los elementos personalizables que se pueden modificar.
                Como se puede observar los elementos que se pueden modificar son:
              </p>

              <p className="text-base lg:text-lg my-3 px-3">
                <ul className="list-disc">
                  <li>
                    Logo: Una vez se haya guardadp un logo para la clínica, este se mostrará
                    en la parte superior izquierda de la factura automáticamente.
                  </li>
                  <li>
                    Texto 1: Permite poner un pequeño texto en vertical en el márgen izquierdo
                    de la página.
                  </li>
                  <li>
                    Texto 2: Permite incluir un texto de hasta 1000 caracteres en la parte inferior
                    de la página.
                  </li>
                  <li>
                    Texto 3: Permite incluir un pequeño texto en el pie de página.
                  </li>
                </ul>
              </p>

            </div>
          </div>
        </div>
      </section>

      <div className="flex max-w-6xl mx-auto mt-6 mb-12 justify-center">
        <div className="bg-base-200 shadow-xl rounded-lg p-10">
          <h3 className="text-secondary text-2xl my-5">
            Plantilla de Factura - Avanzado
          </h3>

          <p className="text-base lg:text-lg my-3">
            Para una mayor personalización, se pueden incluir diferentes etiquetas
            en el texto que se muestra en la factura. Estas etiquetas permiten
            modificar el formato del texto, como ponerlo en negrita, cursiva,
            subrayado o añadir saltos de línea.
          </p>

          <p className="text-base lg:text-lg my-3">
            El intérprete de etiquetas utiliza HTML, por lo que puedes utilizar cualquier
            etiqueta HTML para modificar el texto. Por si no estás familiarizado con HTML,
            aquí tienes una lista de las etiquetas que puedes utilizar:
          </p>

          <p className="text-base lg:text-lg my-3 px-3">
            <ul className="list-disc">
              <li>
                Utiliza la etiqueta &lt;strong&gt;&lt;/strong&gt; para incluir texto
                en negrita. Por ejemplo, el texto "&lt;strong&gt;Texto en negrita&lt;/strong&gt;"
                producirá el siguiente resultado: <strong>Texto en negrita</strong>.
              </li>
              <li>
                Utiliza la etiqueta &lt;u&gt;&lt;/u&gt; para incluir texto subrayado.
                Por ejemplo, el texto "&lt;u&gt;Texto subrayado&lt;/u&gt;" producirá el siguiente
                resultado: <u>Texto subrayado</u>.
              </li>
              <li>
                Utiliza la etiqueta &lt;i&gt;&lt;/i&gt; para incluir texto en cursiva.
                Por ejemplo el texto "&lt;i&gt;Texto en cursiva&lt;/i&gt;" producirá el siguiente
                resultado: <i>Texto en cursiva</i>.
              </li>
            </ul>
          </p>

          <p className="text-base lg:text-lg my-3">
            Estas etiquetas se pueden combinar, por ejemplo, el texto
            "&lt;strong&gt;&lt;u&gt;Texto en negrita y
            subrayado&lt;/u&gt;&lt;/strong&gt;" producirá el siguiente resultado: {" "}
            <strong>
              <u>Texto en negrita y subrayado</u>
            </strong>
            .
          </p>
        </div>
      </div>
    </>
  )

  return (
    <div className={"container my-5"}>

      <p>
        Para una mayor personalización, se pueden incluir diferentes etiquetas
        en el texto:
      </p>
      <ol>

      </ol>
      <p>
        Estas etiquetas se pueden combinar, por ejemplo, el texto
        "&lt;strong&gt;&lt;u&gt;Texto en negrita y
        subrayado&lt;/u&gt;&lt;/strong&gt;" producirá el siguiente resultado:
        <strong>
          <u>Texto en negrita y subrayado</u>
        </strong>
        .
      </p>

      <button className="btn btn-dark" onClick={handleSubmit(navigate, -1)}>
        Atrás
      </button>
    </div>
  );
};

export default InvoiceTemplateTutorial;
