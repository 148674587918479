import axios from "axios";
import { pick } from "lodash";

import { dispatchMessageSave } from "../store/reducers/messageReducer";
import { getDynamicLink } from "../auxiliar/auxFunctions";


export const postUnconfirmedSession = (data, closeTooltip) => {
	return async () => {
		const reqData = pick(data, [
			'clinic_id', 'patient_name', 'patient_surnames', 'patient_email', 'patient_phone_number',
			'employee_id', 'session_type_id', 'start_datetime', 'finish_datetime', 'session_reason'
		]);

		return axios.post('/api/anonymous/unconfirmed_session', reqData)
			.then(() => {
				closeTooltip()
			}).catch(() => { })
	}
}


export const getUnconfirmedSession = (sessionCode) => {
	return async () => {
		return axios.get(`/api/managers/unconfirmed_sessions/reservation/${sessionCode}`)
			.then(response => {
				return response.data.content
			}).catch(() => { })
	}
}


export const deleteUnconfirmedSession = (sessionCode, queryParams) => {
	return async (...args) => {
		const dispatch = args[0];
		const { navigate } = args[2];

		return axios.delete(`/api/managers/unconfirmed_sessions/reservation/${sessionCode}`)
			.then(() => {
				dispatchMessageSave(dispatch, {
					msg: "La solicitud de sesión ha sido eliminada con éxito.",
					type: "success",
				});
				navigate(getDynamicLink(
					"/reservation",
					"info",
					queryParams
				));
			}).catch(() => { })
	}
}


export const getUnconfirmedSessionByID = (sessionId) => {
	return async () => {
		return axios.get(`/api/managers/unconfirmed_sessions/${sessionId}`)
			.then(response => {
				const sessionData = response.data.content.session_type_data
				return {
					...response.data.content,
					session_type_data: !sessionData.endsWith('.0') ? sessionData : sessionData.slice(0, -2)
				}
			}).catch(() => { })
	}
}


export const rejectUnconfirmedSession = (unconfirmedSessionID, close) => {
	return async () => {
		return axios.delete(`/api/managers/unconfirmed_sessions/${unconfirmedSessionID}`)
			.then(() => {
				close()
			}).catch(() => { })
	}
}


export const acceptUnconfirmedSession = (data, unconfirmedSessionID, close) => {
	const session = pick(data, [
		'clinic_id', 'unconfirmed_session_id', 'employee_id', 'session_type_id', 'comment', 'session_reason',
	]);

	const time = pick(data, ["start_datetime", "finish_datetime"]);

	let patient = {};
	if (data.patient_id !== "new") {
		patient = pick(data, ["patient_id"])
	} else {
		patient = {
			patient_id: "new",
			new_patient_info: {
				clinic_id: data.clinic_id,
				name: data.patient_name,
				surnames: data.patient_surnames,
				email: data.patient_email,
				phone_number: data.patient_phone_number,
			}
		}
	}

	return async () => {
		return axios.post(`/api/managers/unconfirmed_sessions/${unconfirmedSessionID}/accept`, { ...session, ...patient, ...time })
			.then(() => {
				close()
			}).catch(() => { })
	}
}