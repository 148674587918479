import axios from "axios";
import { pick } from "lodash";

export const getAllClasses = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/group_sessions/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getClass = (classId) => {
	return async () => {
		return axios
			.get(`/api/managers/group_sessions/${classId}`)
			.then((response) => {
				return {
					...response.data.content,
					date: response.data.content.start_datetime.substring(0, 10),
				};
			})
			.catch(() => { });
	};
};

export const postClass = (
	data,
	closeTooltip,
) => {
	// build the request data
	const session = pick(data, ["clinic_id", "employee_id", "group_session_type_id", "comments", "recurrent"]);

	let schedule = {}
	if (data.recurrent) {
		schedule = pick(data, ["repeat_monday", "repeat_tuesday", "repeat_wednesday", "repeat_thursday", "repeat_friday",
			"repeat_saturday", "repeat_sunday", "start_hour", "finish_hour", "first_date", "last_date"]);
	} else {
		schedule = pick(data, ["start_datetime", "finish_datetime"]);
	}

	const reqData = { ...session, ...schedule };

	// make the request
	return async () => {
		return axios
			.post("/api/managers/group_sessions/", reqData)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const putClass = (
	data,
	classId,
	closeTooltip,
) => {
	const classData = pick(data, ["start_datetime", "finish_datetime", "employee_id", "comments"]);

	return async () => {
		return axios
			.put(`/api/managers/group_sessions/${classId}`, { new_info: classData })
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const deleteClass = (
	classId,
	closeTooltip,
	deleteRecurrent
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/group_sessions/${classId}?deleteRecurrent=${deleteRecurrent}`
			)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

