import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PaginatorComponent from "../../../components/general/auxiliar/PaginatorComponent";
import WhatsAppReminder from "../../../components/general/auxiliar/WhatsAppReminder";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import {
    handleSubmit,
    handleSubmitWithDispatch,
} from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { downloadPatients, getAllPatients } from "../../../actions/patient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const OverviewPatients = () => {
    const { register, watch, errors } = useFormWithDisable();
    const writtenFilter = {
        name: watch("name") || "",
        surnames: watch("surnames") || "",
        email: watch("email") || "",
        phone_number: watch("phone_number") || "",
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: clinicId } = useSelector((state) => state.activeClinic);
    const [patients, setPatients] = useState({ waiting: true });
    const [filter, setFilter] = useState({
        clinic_id: clinicId,
        page: 1,
        per_page: 10,
        order: "name",
    });

    const disabled = usePaginationFilterEffects(filter, writtenFilter, getAllPatients, setPatients, setFilter);

    const formConfig = {
        name: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Nombre",
            placeholder: "Nombre",
            name: "name",
        },
        surnames: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Apellidos",
            placeholder: "Apellidos",
            name: "surnames",
        },
        email: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Correo Electrónico",
            placeholder: "paciente@email.com",
            name: "email",
        },
        phone_number: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Número de teléfono",
            placeholder: "+34 XXX XXX XXX",
            name: "phone_number",
        },
    };

    return (
        <div className="sm:container p-3 mx-auto my-5">
            <div className="flex justify-between items-center">
                <h2 className="text-primary text-2xl">Pacientes</h2>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit(navigate, "/patient/register")}
                >
                    Nuevo paciente
                </button>
            </div>


            <form className="mt-3 mb-6">
                <p className="text-primary text-xl">Filtros</p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>
            </form>
            {patients.waiting ? (
                <div className="flex items-center justify-center">
                    <span className="loading loading-dots loading-lg" />
                </div>
            ) : !isDefined(patients.order) ||
                !isDefined(patients.content) ||
                patients.order.length <= 0 ? (
                <p className="lg:text-lg my-3">
                    No hay pacientes registrados, añade el primero haciendo click en el
                    botón de arriba.
                </p>
            ) : (
                <div className="overflow-x-auto overflow-y-hidden">
                    <div className="flex justify-between">
                        <p className="text-primary text-xl">
                            {patients.pagination.total_items} Pacientes encontrados
                        </p>
                        <button
                            className="btn btn-primary btn-sm mx-5"
                            onClick={handleSubmitWithDispatch(
                                dispatch,
                                downloadPatients,
                                {
                                    ...filter,
                                    ...writtenFilter
                                }
                            )}
                        >
                            Descargar{" "}
                            <>
                                {" "}
                                <div
                                    className="tooltip tooltip-left flex items-center opacity-100"
                                    data-tip={
                                        "Se descargarán los pacientes resultantes de los filtros aplicados"
                                    }
                                >
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                </div>
                            </>
                        </button>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th className="hidden xs:table-cell">Apellidos</th>
                                <th className="hidden md:table-cell">Correo</th>
                                <th className="hidden sm:table-cell">Teléfono</th>
                                <th />
                            </tr>
                        </thead>

                        <tbody>
                            {patients.order.map((patientId) => {
                                const patient = patients.content && patients.content[patientId];

                                return (
                                    <tr key={patientId}>
                                        <td className="xs:hidden">{`${patient.name} ${patient.surnames}`}</td>
                                        <td className="hidden xs:table-cell">{patient.name}</td>
                                        <td className="hidden xs:table-cell">{patient.surnames}</td>
                                        <td className="hidden md:table-cell">{patient.email}</td>
                                        <td className="hidden sm:table-cell">
                                            <div className="flex">
                                                <span>{patient.phone_number}</span>
                                                <WhatsAppReminder link={patient.link} />
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={handleSubmit(
                                                    navigate,
                                                    `/patient/${patientId}`
                                                )}
                                            >
                                                Detalles
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <form className="mt-5 flex justify-between">
                <PaginatorComponent
                    pagination={patients.pagination}
                    setFilter={setFilter}
                    filter={filter}
                    disabled={disabled}
                />
            </form>
        </div>
    );
};

export default OverviewPatients;
