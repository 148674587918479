import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";

import CustomForm from "../../../general/form/CustomForm";
import OverviewPatientsInClass from "./classAux/OverviewPatientsInClass";
import PaymentInClass from "./classAux/PaymentInClass";
import ModalMultiOption from "../../../general/auxiliar/modal/ModalMultiOption";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import {
  getClass,
  putClass,
  deleteClass,
} from "../../../../actions/class";
import { postPatientAttendsClass, deletePatientFromClass, putPatientAttendsClass } from "../../../../actions/patientAttendsClass";
import { isDefined, maxLength } from "../../../../auxiliar/formatValidators";

const EditClass = ({
  closeTooltip,
  orgClassId,
}) => {
  const dispatch = useDispatch();
  const modalDualId = "edit-class-dual-modal";

  const {
    register,
    setValue,
    watch,
    resetForm,
    isSubmitting,
    fetchFormValues,
    originalValues,
    errors,
    handleSubmit,
    handleSubmitWithoutValidation,
  } = useFormWithDisable();

  const activeClinic = useSelector((state) => state.activeClinic);

  const [classId, setClassId] = useState(orgClassId);
  const [classType, setClassType] = useState({
    classTypeId: undefined,
    sessionDuration: 60,
    price: 0,
    maxParticipants: 0,
    reload: false,
  })
  const [operation, setOperation] = useState({
    patient_id: undefined,
    operation: undefined,
    update: false,
  });

  const comments = watch("comments");
  const startDatetime = watch("start_datetime");
  const classData = watch("group_session_type_data");

  const modalHandler = (operation) => (data) => {
    if (originalValues?.next_group_session_id && (operation !== "deletePatient" || data.recurrent)) {
      setOperation({ patient_id: data.patient_id, operation, update: operation.update });
      document.getElementById(modalDualId)?.showModal();
      return;
    }

    if (operation === "addPatient") {
      dispatchAddPatientToClass(false, data);
    } else if (operation === "deletePatient") {
      dispatch(deletePatientFromClass(classId, data.patient_id, cleanAfterOperation));
    } else if (operation === "deleteClass") {
      dispatch(deleteClass(classId, handleClose, false));
    }
  }

  const dispatchAddPatientToClass = (recursive, data) => {
    dispatch(postPatientAttendsClass(
      {
        clinic_id: activeClinic.id,
        group_session_id: classId,
        patient_id: data?.patient_id || operation.patient_id,
        addToRecSessions: recursive,
        new_patient_info: operation.patient_id === 'new' ? {
          name: watch("patient_name"),
          surnames: watch("patient_surnames"),
          email: watch("patient_email"),
          phone_number: watch("patient_phone_number"),
        } : undefined
      },
      cleanAfterOperation
    ));
  };

  const cleanAfterOperation = () => {
    setOperation({ patient_id: undefined, operation: undefined, update: !operation.update });
    document.getElementById(modalDualId)?.close();
    setValue("patient_name", "");
    setValue("patient_surnames", "");
    setValue("patient_email", "");
    setValue("patient_phone_number", "");
    setValue("patient_id", null);
  };

  const handleClose = () => {
    closeTooltip();
    setOperation({ patient_id: undefined, operation: undefined, update: operation.update });
    resetForm();
  };

  // Sets the classType params and if it is not defined, forces a reload
  useEffect(() => {
    if (!isDefined(classData)) {
      setTimeout(() => setClassType({
        classTypeId: undefined,
        sessionDuration: 60,
        price: 0,
        maxParticipants: 0,
        reload: !classType.reload,
      }), 200);
      return;
    }

    // save metadata
    const classId = classData?.split("-")[0];
    const sessionDuration = parseInt(classData.split("-")[1]);
    const price = parseFloat(classData.split("-")[2]);
    const maxParticipants = parseInt(classData.split("-")[3]);

    setClassType({
      classTypeId: classId,
      sessionDuration,
      price,
      maxParticipants,
      reload: classType.reload,
    });
  }, [classData, classType.reload]);

  useEffect(() => {
    if (isDefined(startDatetime)) {
      let startingTime = new Date(startDatetime).getTime();
      if (isNaN(startingTime)) startingTime = new Date().getTime();

      setValue("finish_datetime", format(
        new Date(startingTime + classType.sessionDuration * 60000),
        "yyyy-MM-dd'T'HH:mm"
      ).toString())
    }
  }, [classType.sessionDuration, startDatetime]);

  useEffect(() => {
    fetchFormValues(getClass, classId);
    setOperation({ patient_id: undefined, operation: undefined, update: operation.update });
  }, [classId]);

  const loadNewSession = (id) => {
    resetForm();
    setClassId(id);
  };

  // Form configuration
  const sessionFormConfig = {
    class_title: {
      condition: true,
      type: "title",
      text: "Clase",
      classname: "text-primary text-lg col-span-1 md:col-span-2",
    },
    employee_id: {
      condition: true,
      disabled: new Date(originalValues?.start_datetime) < new Date(),
      defaultValue: originalValues?.employee_id,
      type: "physio_selector",
      label: "Fisioterapeuta *",
      name: "employee_id",
    },
    group_session_type_data: {
      condition: true,
      disabled: true,
      defaultValue: originalValues?.group_session_type_id,
      type: "group_session_type_selector_with_metadata",
      label: "Tipo de Clase",
      name: "group_session_type_data",
    },
    start_datetime: {
      condition: true,
      disabled: new Date(originalValues?.start_datetime) < new Date(),
      type: "datetime-local",
      label: "Fecha y hora de inicio *",
      name: "start_datetime",
      validators: {
        required: "Elegir una fecha y hora de inicio es obligatorio",
        validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
      },
    },
    finish_datetime: {
      condition: true,
      disabled: true,
      type: "datetime-local",
      label: "Fecha y hora de fin",
      name: "finish_datetime",
      validators: {
        required: "Elegir una fecha y hora de fin es obligatorio",
        validate: (v) => isDefined(v) || "Elegir una fecha y hora de fin es obligatorio",
      },
    },
    comments: {
      condition: true,
      disabled: false,
      name: "comments",
      label: `Comentario (${comments?.length || 0}/10000)`,
      type: "textarea",
      validators: {
        validate: maxLength(10000),
      },
    },
  };

  const multiModalConfig = {
    addPatient: {
      title: "Añadir paciente",
      text: "¿Desea añadir al paciente a una clase o a todas las clases futuras?",
      options: [
        {
          text: "Esta clase",
          onClick: handleSubmitWithoutValidation(dispatchAddPatientToClass, false)
        },
        {
          text: "Clases futuras",
          onClick: handleSubmitWithoutValidation(dispatchAddPatientToClass, true)
        }
      ]
    },
    deletePatient: {
      title: "Eliminar paciente de la sesión",
      text: "¿Desea eliminar el paciente de una clase o de todas las clases futuras?",
      options: [
        {
          text: "Esta clase",
          onClick: handleSubmitWithoutValidation(deletePatientFromClass, classId, operation.patient_id, cleanAfterOperation, false)
        },
        {
          text: "Clases futuras",
          onClick: handleSubmitWithoutValidation(deletePatientFromClass, classId, operation.patient_id, cleanAfterOperation, true)
        }
      ]
    },
    deleteClass: {
      title: "¿Estás seguro de que quieres eliminar la clase?",
      text: "No podremos recuperar la información más adelante.",
      options: [
        {
          text: "Esta clase",
          onClick: handleSubmitWithoutValidation(deleteClass, classId, handleClose, false)
        },
        {
          text: "Clases futuras",
          onClick: handleSubmitWithoutValidation(deleteClass, classId, handleClose, true)
        }
      ]
    }
  }

  const patientFormConfig = {
    patient_selector: {
      condition: true,
      type: "patient_selector",
      watch: watch,
      filter: {
        clinic_id: activeClinic.id,
        per_page: 5,
        order: "name",
        exclude_group_session_id: classId,
      },
      setValue: setValue,
      onClientSelect: modalHandler("addPatient"),
      onClientRegister: handleSubmit(modalHandler("addPatient")),
    }
  };

  if (!isDefined(originalValues) || !isDefined(originalValues?.start_datetime)) return null;

  return (
    <div>
      { /* Title and buttons */}
      <div className="flex justify-between">
        <h2 className="text-primary text-2xl">
          Editar Clase
        </h2>
        <div className="mt-2">
          <div className={`join ${(originalValues?.previous_group_session_id || originalValues?.next_group_session_id) ? "mx-2" : "mx-0"}`}>
            {isDefined(originalValues?.previous_group_session_id) && (
              <button
                className={"join-item btn btn-primary btn-sm"}
                onClick={handleSubmitWithoutValidation(loadNewSession, originalValues?.previous_group_session_id)}
              >
                «
              </button>
            )}
            {isDefined(originalValues?.next_group_session_id) && (
              <button
                className={"join-item btn btn-primary btn-sm"}
                onClick={handleSubmitWithoutValidation(loadNewSession, originalValues?.next_group_session_id)}
              >
                »
              </button>
            )}
          </div>
        </div>
      </div>

      { /* Class form */}
      <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
        <CustomForm
          formConfig={sessionFormConfig}
          errors={errors}
          register={register}
        />
      </form>

      { /* Patient list */}
      <OverviewPatientsInClass
        classId={classId}
        operation={operation}
        setOperation={setOperation}
        maxParticipants={classType.maxParticipants}
        isSubmitting={isSubmitting}
        onAddPatientClick={handleSubmitWithoutValidation(setOperation, { patient_id: undefined, operation: "addPatient", update: operation.update })}
        onCancelAddPatientClick={handleSubmitWithoutValidation(setOperation, { patient_id: undefined, operation: undefined, update: operation.update })}
        onDeletePatientFromClass={modalHandler("deletePatient")}
        onPaymentClick={(patientId, metadata) => handleSubmitWithoutValidation(setOperation, { patient_id: patientId, operation: "pay", update: operation.update, metadata })}
      />

      { /* Add patient form */}
      {operation.operation === "addPatient" && (
        <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
          <CustomForm
            formConfig={patientFormConfig}
            errors={errors}
            register={register}
          />
        </form>
      )}

      { /* Payment */}
      {operation.operation === "pay" && <PaymentInClass
        classId={classId}
        metadata={operation.metadata}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        isSubmitting={isSubmitting}
        onCancelPaymentClick={handleSubmitWithoutValidation(setOperation, { patient_id: undefined, operation: undefined, update: operation.update })}
        onConfirmPaymentClick={handleSubmit(putPatientAttendsClass, cleanAfterOperation)}
      />}

      { /* Buttons */}
      <div className="mt-5 flex space-x-2 sm:space-x-4">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmit(putClass, classId, handleClose)}
          disabled={isSubmitting}
        >
          Guardar
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={handleSubmitWithoutValidation(handleClose)}
          disabled={isSubmitting}
        >
          Cancelar
        </button>
        <button
          className="btn btn-error btn-sm"
          onClick={handleSubmitWithoutValidation(modalHandler, "deleteClass")}
          disabled={isSubmitting}
        >
          Eliminar clase
        </button>
      </div>

      { /* Modals */}
      <ModalMultiOption
        id={modalDualId}
        disabled={isSubmitting}
        title={multiModalConfig[operation.operation]?.title}
        text={multiModalConfig[operation.operation]?.text}
        options={multiModalConfig[operation.operation]?.options || []}
      />
    </div>
  );
};

export default EditClass;
