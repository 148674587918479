import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { devToolsEnhancer } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { authReducer } from "./reducers/authReducer";
import { messageReducer } from "./reducers/messageReducer";
import { activeClinicReducer } from "./reducers/activeClinicReducer";
import { themeReducer } from "./reducers/themeReducer";
import { cookiesReducer } from "./reducers/cookiesReducer";
import { persistedFiltersReducer } from "./reducers/persistedFiltersReducer";

const reducers = combineReducers({
	auth: authReducer,
	message: messageReducer,
	activeClinic: activeClinicReducer,
	themeReducer: themeReducer,
	cookiesReducer: cookiesReducer,
	persistedFilters: persistedFiltersReducer,
});

const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let store;
let persistor;

export const getStore = (navigate) => {
	if (!store) {
		store = configureStore({
			reducer: persistedReducer,
			middleware: (getDefaultMiddleware) =>
				getDefaultMiddleware({
					thunk: { extraArgument: { navigate } },
					serializableCheck: false,
				}).concat(thunk),
			devTools: [devToolsEnhancer({ realtime: true })]
		});
	}

	if (!persistor) {
		persistor = persistStore(store);
	}

	return { store, persistor };
};
