import { useNavigate } from "react-router-dom";

import useQueryParams from "../../auxiliar/customHooks/useQueryParams";
import { handleSubmit } from "../../auxiliar/auxFunctions";

const NotFound = () => {
    const navigate = useNavigate();
    const params = useQueryParams();
    const entity = params.get("entity");

    const texts = {
        default: {
            title: "Página no encontrada",
            message: "La página a la que estás intentando acceder no existe."
        },
        clinic: {
            title: "Clínica no encontrada",
            message: "La clinica a la que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        physio: {
            title: "Fisioterapeuta no encontrado",
            message: "El fisioterapeuta al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        class_type: {
            title: "Tipo de clase no encontrado",
            message: "El tipo de clase al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        session_type: {
            title: "Tipo de sesión no encontrado",
            message: "El tipo de sesión al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        voucher: {
            title: "Tipo de bono no encontrado",
            message: "El tipo de bono al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        patient: {
            title: "Paciente no encontrado",
            message: "El paciente al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        acquired_voucher: {
            title: "Bono adquirido no encontrado",
            message: "El bono adquirido al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        cash_register: {
            title: "Albarán no encontrado",
            message: "El albarán al que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        invoice: {
            title: "Factura no encontrada",
            message: "La factura a la que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        task: {
            title: "Tarea no encontrada",
            message: "La tarea a la que estás intentando acceder no existe o no tienes permisos de acceso."
        },
        unconfirmed_session: {
            title: "Sesión no encontrada",
            message: "La sesión a la que estás intentando acceder no existe o no tienes permisos de acceso. Si crees que se trata de un error ponte en contacto con tu clínica."
        },
    }

    const t = texts[entity] || texts.default;

    return <div className="max-w-4xl m-10 md:my-20 p-10 mx-auto bg-base-100 text-center bg-base-200 shadow-xl rounded-lg">
        <h1 className="text-6xl sm:text-9xl text-bold text-primary">404</h1>
        <p className="text-4xl my-5 text-primary">
            Opps! {t.title}
        </p>
        <p className="text-xl my-5 text">
            {t.message}
        </p>

        {entity !== "unconfirmedSession" && <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(navigate, -2)}
        >
            Atrás
        </button>}
    </div>
}

export default NotFound;