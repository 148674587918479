import { persistedFilters } from "./types";

export const persistedFiltersReducer = (state = {}, action) => {
	switch (action.type) {
		case persistedFilters.save:
			return {
				...state,
				[action.payload.key]: {
					...state[action.payload.key],
					...action.payload.payload,
				}
			};
		case persistedFilters.cleanKey:
			return {
				...state,
				[action.payload.key]: {},
			};
		case persistedFilters.clean:
			return {};
		default:
			return state;
	}
};

export const dispatchPersistedFiltersSave = (dispatch, key, payload) => {
	dispatch({
		type: persistedFilters.save,
		payload: {
			key: key,
			payload: payload,
		}
	});
};

export const dispatchPersistedFiltersCleanKey = (dispatch, key) => {
	dispatch({
		type: persistedFilters.cleanKey,
		payload: {
			key: key,
		}
	});
};

export const dispatchPersistedFiltersClean = (dispatch) => {
	dispatch({
		type: persistedFilters.clean,
	});
};
