import axios from "axios";

import { isDefined } from "../auxiliar/formatValidators";

export const getAllAcquiredVoucher = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/acquired_vouchers/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getAcquiredVoucher = (acquiredVoucherId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(acquiredVoucherId)) {
			navigate(`/unknown?entity=acquired_voucher&id=${acquiredVoucherId}`);
			return;
		}

		return axios
			.get(`/api/managers/acquired_vouchers/${acquiredVoucherId}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=acquired_voucher&id=${acquiredVoucherId}`);
			});
	};
};

export const postAcquiredVoucher = (data, activeClinicId, patientId) => {
	data.clinic_id = activeClinicId;
	data.patient_id = patientId;
	data.remaining_sessions = parseInt(data.remaining_sessions);
	data.discount = isDefined(data.discount) ? data.discount : 0;
	data.paid = isDefined(data.paid) ? parseFloat(data.paid) : 0;
	if (data.payment_method === "select") delete data.payment_method;
	delete data.voucher_data

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/acquired_vouchers/", data)
			.then((res) => {
				navigate(
					`/acquired-voucher/${res.data.content.acquired_voucher_id}/?patient=${patientId}`
				);
			})
			.catch(() => { });
	};
};

export const putAcquiredVoucher = (data, acquiredVoucherId, disableEdit) => {
	return async () => {
		return axios
			.put(`/api/managers/acquired_vouchers/${acquiredVoucherId}`, {
				new_info: data,
				acquired_voucher_id: acquiredVoucherId,
			})
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteAcquiredVoucher = (acquiredVoucherId, patientId) => {
	return async (...args) => {
		const { navigate } = args[2];
		return axios
			.delete(`/api/managers/acquired_vouchers/${acquiredVoucherId}`)
			.then(() => {
				navigate(`/patient/${patientId}`);
			})
			.catch(() => { });
	};
};
