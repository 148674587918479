import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormInput = ({ customKey, register, config, errors }) => {
  return (
    <div key={customKey} className="form-control">
      {config.label && (
        <label htmlFor={config.name} className="label-text">
          {config.label}{" "}
          {config.info && (
            <div className="tooltip" data-tip={config.info}>
              <FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
            </div>
          )}
        </label>
      )}

      <div className={`${errors && "tooltip"}`} data-tip={errors?.message}>
        <input
          className={`input input-bordered text-left w-full ${errors ? "input-error" : "input-primary"
            } disabled:bg-disabled-bg disabled:text-disabled-text placeholder-disabled-text`}
          type={config.type}
          id={config.name}
          placeholder={config.disabled ? "" : config.placeholder}
          disabled={config.disabled}
          defaultValue={config.defaultValue}
          autoComplete={config.autocomplete ? config.autocomplete : "off"}
          min={config.minValue}
          max={config.maxValue}
          {...register(config.name, config.validators )}
        />
      </div>
    </div>
  );
};

export default FormInput;
