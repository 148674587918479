import axios from "axios";
import { dispatchLogout } from "../store/reducers/authReducer";

export const getManager = (id) => {
	return async () => {
		return axios
			.get(`/api/managers/managers/${id}`)
			.then((response) => {
				return response.data.content;
			})
			.catch(() => { });
	};
};

export const putManager = (data, id, disableForm) => {
	const req_data = JSON.parse(JSON.stringify({ new_info: data }));

	return async () => {
		return axios
			.put(`/api/managers/managers/${id}`, req_data)
			.then(() => {
				disableForm();
			})
			.catch(() => { });
	};
};

export const putManagerPassword = (data, id, close) => {
	const req_data = {
		password: data.password,
		new_info: {
			password: data.new_info_password,
			password_rep: data.new_info_password_rep,
		},
	};

	return async () => {
		return axios
			.put(`/api/managers/managers/${id}`, req_data)
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

export const deleteManager = (id) => {
	return async (dispatch) => {
		return axios
			.delete(`/api/managers/managers/${id}`)
			.then(() => {
				dispatchLogout(dispatch);
			})
			.catch(() => { });
	};
};

export const validateManager = (id, code) => {
	return async () => {
		return axios
			.post(`/api/managers/validate_email/${id}`, { validation_code: code })
			.then(() => { })
			.catch(() => { });
	};
};

export const resetPassword = (code) => {
	return async () => {
		return axios
			.post(`/api/managers/reset_password`, { code: code })
			.then(() => { })
			.catch(() => { });
	};
};
