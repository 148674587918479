import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormSelector from "./FormSelector";
import { getAllClassTypes } from "../../../actions/classTypes";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const CustomClassTypeSelector = ({ metadata, customKey, register, config, errors }) => {
  const dispatch = useDispatch();
  const clinicId = useSelector((state) => state.activeClinic.id);
  const [classTypes, setClassTypes] = useState({ content: {}, order: [], waiting: true });

  useEffect(() => {
    fetchData(
      dispatch,
      getAllClassTypes,
      {
        clinic_id: clinicId,
        order: "name",
        per_page: 1000,
        page: 1,
        ...(config.defaultValue && !["select"].includes(config.defaultValue) && { selected: config.defaultValue }),
      },
      setClassTypes
    );
  }, []);

  const checkClassTypeSelected = (value) => {
    if (!isDefined(value) || value === "select") {
      return "El tipo de clase es obligatorio";
    }
  };

  const getDefault = () => {
    if (isDefined(config.defaultValue) && metadata) {
      const t = classTypes.content[config.defaultValue];
      return `${config.defaultValue}-${t.session_duration}-${t.price}-${t.number_participants}`;
    }

    return config?.defaultValue?.toString() || "select";
  }

  const options = [
    ...(config.defaultValue ? [] :
      [{ key: "select", label: "Selecciona un tipo de clase" }]
    ),
    ...(classTypes?.order
      ? classTypes?.order?.map((id) => {
        const t = classTypes.content[id];
        return {
          key: metadata
            ? `${id}-${t.session_duration}-${t.price}-${t.number_participants}`
            : id.toString(),
          label: classTypes?.content?.[id]?.display_name,
        }
      })
      : []),
  ];

  if (classTypes.waiting)
    return <div key={customKey + '-tmp'}>
      <FormSelector
        customKey={customKey + '-tmp'}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled,
          type: "selector",
          name: config.name + '-tmp',
          label: config.label,
          info: config.info,
          options: [{ key: config.defaultValue || options[0]?.key, label: "Cargando tipos de clase..." }],
          defaultValue: "loading",
        }}
        errors={undefined}
      />
    </div>;
    
  else if (!metadata || !config.disabled)
    return (
      <FormSelector
        customKey={customKey}
        register={register}
        config={{
          condition: config.condition,
          disabled: config.disabled,
          type: "selector",
          name: config.name,
          label: config.label,
          info: config.info,
          options: options,
          classname: config.classname,
          defaultValue: getDefault(),
          validators: { validate: checkClassTypeSelected },
        }}
        errors={errors}
      />
    );

  else
    return (
      <>
        <FormSelector
          customKey={customKey}
          register={register}
          config={{
            condition: true,
            disabled: false,
            type: "selector",
            name: config.name,
            label: config.label,
            info: config.info,
            options: options,
            classname: "hidden",
            defaultValue: getDefault(),
            validators: { validate: checkClassTypeSelected },
          }}
          errors={errors}
        />
        <FormSelector
          customKey={customKey + "-aux"}
          register={register}
          config={{
            condition: config.condition,
            disabled: config.disabled,
            type: "selector",
            name: config.name + "-aux",
            label: config.label,
            info: config.info,
            options: options,
            classname: config.classname,
            defaultValue: getDefault(),
          }}
          errors={errors}
        />
      </>
    )
};

export default CustomClassTypeSelector;
