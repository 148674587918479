import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DisplayCashRegister from "../../../components/cashRegister/DisplayCashRegister";
import DisplayInvoice from "../../../components/cashRegister/DisplayInvoice";
import OverviewCharges from "../../../components/charge/OverviewCharges";
import DisplayPatientInCashRegister from "../../../components/cashRegister/DisplayPatientInCashRegister";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";
import DisplaySessionInCashRegister from "../../../components/cashRegister/DisplaySessionInCashRegister";
import DisplayClassInCashRegister from "../../../components/cashRegister/DisplayClassInCashRegister";
import DisplayAcquiredVoucherInCashRegister from "../../../components/cashRegister/DisplayAcquiredVoucherInCashRegister";
import { getCashRegister } from "../../../actions/cashRegister";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const CashRegisterCard = ({}) => {
  const openTooltipEdit = useRef(null);

  const { cash_register_id: cashRegisterId } = useParams();
  const dispatch = useDispatch();

  const [cashRegister, setCashRegister] = useState();
  const [boolRefresh, setBoolRefresh] = useState(false);

  useEffect(() => {
    fetchData(dispatch, getCashRegister, cashRegisterId, setCashRegister);
  }, [cashRegisterId, boolRefresh]);

  const refresh = () => {
    setBoolRefresh(!boolRefresh);
  };

  return (
    <div>
      <DisplayCashRegister data={cashRegister} refresh={refresh} />

      {isDefined(cashRegister?.invoice_id) && (
        <DisplayInvoice invoiceId={cashRegister?.invoice_id} />
      )}

      <OverviewCharges
        cashRegisterId={cashRegisterId}
        refresh={refresh}
        boolRefresh={boolRefresh}
        cashRegisterData={cashRegister}
      />

      <DisplayPatientInCashRegister patientId={cashRegister?.patient_id} />

      {cashRegister?.type === "session" && (
        <DisplaySessionInCashRegister
          sessionId={cashRegister?.session_id}
          openTooltipEdit={openTooltipEdit}
          boolRefresh={boolRefresh}
        />
      )}

      {cashRegister?.type === "group_session" && (
        <DisplayClassInCashRegister
          classId={cashRegister?.group_session_id}
          openTooltipEdit={openTooltipEdit}
          boolRefresh={boolRefresh}
        />
      )}

      {cashRegister?.type === "acquired_voucher" && (
        <DisplayAcquiredVoucherInCashRegister
          acquiredVoucherId={cashRegister?.acquired_voucher_id}
          openTooltipEdit={openTooltipEdit}
        />
      )}

      <EditSessionPopup childFunc={openTooltipEdit} refresh={refresh} />
    </div>
  );
};

export default CashRegisterCard;
