import CustomForm from "../general/form/CustomForm";
import ModalConfirm from "../general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { getTask, putTask, deleteTask } from "../../actions/task";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EditTask = ({ taskId }) => {
    const modalId = "delete-task-modal";
    const {
        register,
        handleSubmit,
        handleSubmitWithoutValidation,
        fetchFormValues,
        resetFetchedValues,
        openModal,
        watch,
        errors,
        isSubmitting,
    } = useFormWithDisable(modalId);

    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const description = watch("description");

    const formConfig = {
        topic: {
            condition: true,
            disabled: !isEditing,
            type: "text",
            label: "Tema *",
            placeholder: "Tema",
            name: "topic",
            validators: { required: "El tema es obligatorio" },
        },
        date: {
            condition: true,
            disabled: !isEditing,
            type: "date",
            label: "Fecha *",
            name: "date",
            validators: { required: "La fecha es obligatoria" },
        },
        priority: {
            condition: true,
            disabled: !isEditing,
            type: "number",
            label: "Prioridad *",
            placeholder: "Ejemplo: 5",
            name: "priority",
            info: "La prioridad es un número entero que indica la importancia de la tarea, te permite ordenarlas una vez creadas.",
            validators: { required: "La prioridad es obligatoria" },
            minValue: 0,
        },
        color: {
            condition: true,
            disabled: !isEditing,
            type: "color_selector",
            label: "Color *",
            info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este fisioterapeuta.",
            name: "color",
        },
        description: {
            condition: true,
            disabled: !isEditing,
            type: "textarea",
            label: `Descripción de la tarea (${description?.length || 0}/1000)`,
            placeholder: "...",
            name: "description",
            validators: {
                maxLength: {
                    value: 1000,
                    message: "La descripción es demasiado larga",
                },
            },
        },
        finished: {
            condition: true,
            disabled: !isEditing,
            info: "Indica si la tarea ya está finalizada.",
            label: "Finalizada",
            type: "checkbox",
            name: "finished",
        },
    };

    useEffect(() => {
        if (taskId) fetchFormValues(getTask, taskId);
    }, [taskId]);

    useEffect(() => {
        if (!isEditing && taskId) resetFetchedValues();
    }, [isEditing]);

    return (
        <div className="sm:container p-3 mx-auto my-5">
            <h2 className="text-primary text-2xl">Tarea</h2>
            <form className="mt-5 mb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>

                <div className="mt-5 flex space-x-2 sm:space-x-4">
                    {!isEditing ? (
                        <>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={handleSubmitWithoutValidation(setIsEditing, true)}
                                disabled={isSubmitting}
                            >
                                Editar
                            </button>
                            <button
                                className="btn btn-error btn-sm"
                                onClick={openModal}
                                disabled={isSubmitting}
                            >
                                Eliminar
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={handleSubmitWithoutValidation(navigate, -1)}
                                disabled={isSubmitting}
                            >
                                Atrás
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={handleSubmit(
                                    putTask,
                                    taskId,
                                    handleSubmitWithoutValidation(setIsEditing, false)
                                )}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                                disabled={isSubmitting}
                            >
                                Cancelar
                            </button>
                        </>
                    )}
                </div>
            </form>

            <ModalConfirm
                id={modalId}
                title="¿Estás seguro de que quieres eliminar los datos de la tarea?"
                text="No podremos recuperar la información más adelante."
                onConfirm={handleSubmitWithoutValidation(deleteTask, taskId)}
            />
        </div>
    );
};

export default EditTask;