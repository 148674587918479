import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { dispatchMessageRemove } from "../../../../store/reducers/messageReducer";

const MessagesDisplay = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.message);

  return (
    <div className="flex flex-col space-y-4 z-50 opacity-100 pointer-events-none absolute top-4 left-1/2 transform -translate-x-1/2 overflow-hidden max-h-[80vh]">
      {messages &&
        messages.map((message) => (
          <div key={message.id} className="toast-top toast-center">
            <div className="justify-between pointer-events-auto">
              <div
                className={`alert alert-${message.type} flex justify-between`}
              >
                {message.msg}
                <button
                  onClick={() => dispatchMessageRemove(dispatch, message.id)}
                >
                  <FontAwesomeIcon icon={faClose} className="text-right" />
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  // necessary for CSS compilation
  return <div className="alert-success alert-error alert-info"></div>;
};

export default MessagesDisplay;
