import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "../../../general/form/CustomForm";
import ModalConfirm from "../../../general/auxiliar/modal/ModalConfirm";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import WhatsAppReminder from "../../../general/auxiliar/WhatsAppReminder";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import {
	postPatientAuthorizedAcquiredVoucher,
	deletePatientAuthorizedAcquiredVoucher,
} from "../../../../actions/patientAuthorizedAcquiredVoucher";
import {
	handleSubmit,
	handleSubmitWithDispatch,
} from "../../../../auxiliar/auxFunctions";
import { isDefined } from "../../../../auxiliar/formatValidators";
import { getAllPatients } from "../../../../actions/patient";

const OverviewPatientsInAcquiredVoucher = ({ active }) => {
	const modalId = "delete-patient-voucher-modal";
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		register,
		watch,
		setValue,
		errors,
	} = useFormWithDisable();

	const activeClinicId = useSelector((state) => state.activeClinic.id);
	const { acquired_voucher_id: acquiredVoucherId } = useParams();
	const [addingPatient, setAddingPatient] = useState(false);
	const patient = watch('patient_id');

	const onPatientAdd = (patient) => {
		console.log(patient);
		dispatch(postPatientAuthorizedAcquiredVoucher(
			patient,
			activeClinicId,
			acquiredVoucherId,
			handleSubmit(setAddingPatient, false)
		))
	}

	const formConfig = {
		patient_selector: {
			condition: addingPatient,
			type: "patient_selector",
			watch: watch,
			filter: {
				clinic_id: activeClinicId,
				exclude_acquired_voucher_id: acquiredVoucherId,
				page: 1,
				per_page: 5,
				order: "name",
			},
			setValue: setValue,
			onClientSelect: onPatientAdd,
			keyword: "Añadir"
		}
	};


	const [patients, setPatients] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: activeClinicId,
		acquired_voucher_id: acquiredVoucherId,
		page: 1,
		per_page: 5,
		order: "name",
	});

	const [refreshBool, setRefreshBool] = useState(false);
	const refresh = () => {
		if (
			filter.page > 1 &&
			patients?.order?.length === 1 &&
			!patients?.pagination?.has_next
		) {
			setFilter({ ...filter, page: filter.page - 1 });
		}
		setRefreshBool(!refreshBool);
	};

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllPatients,
		setPatients,
		setFilter,
		refreshBool,
		addingPatient
	);

	const [deleteId, setDeleteId] = useState();
	const deletePatient = (id) => {
		setDeleteId(id);
		document.getElementById(modalId)?.showModal();
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<span className="flex gap-3">
				<h2 className="text-primary text-2xl">Pacientes</h2>{" "}
				<div
					className="tooltip flex items-center"
					data-tip={"El comprador original no se puede eliminar"}
				>
					<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
				</div>
			</span>

			{patients.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(patients.order) ||
				!isDefined(patients.content) ||
				patients.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					Se ha producido un error al cargar los pacientes. Por favor, inténtelo
					de nuevo.
				</p>
			) : (
				<div className="overflow-x-auto overflow-y-hidden">
					<table className="table">
						<thead>
							<tr>
								<th>Nombre</th>
								<th className="hidden md:table-cell">Correo</th>
								<th className="hidden sm:table-cell">Teléfono</th>
								<th />
							</tr>
						</thead>

						<tbody>
							{patients.order.map((patientId) => {
								const patient = patients.content && patients.content[patientId];

								return (
									<tr key={patientId}>
										<td>{`${patient.name} ${patient.surnames}`}</td>
										<td className="hidden md:table-cell">{patient.email}</td>
										<td className="hidden sm:table-cell">
											<div className="flex">
												<span>{patient.phone_number}</span>
												<WhatsAppReminder link={patient.link} />
											</div>
										</td>
										<td className="text-center">
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={handleSubmit(
													navigate,
													`/patient/${patientId}`
												)}
											>
												Detalles
											</button>
										</td>
										<td className="text-center items-center">
											<button
												type="button"
												className={`btn btn-primary btn-sm ${patient.role === "Owner" && "disabled"}`}
												disabled={patient.role === "Owner"}
												onClick={handleSubmit(deletePatient, patientId)}
											>
												Eliminar
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}


			<div className={`mt-5 flex ${addingPatient ? "justify-end" : "justify-between"}`}>
				{active && !addingPatient && (
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(setAddingPatient, true)}
						>
							Añadir paciente
						</button>
					</div>
				)}

				<PaginatorComponent
					pagination={patients.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</div>

			<div className="mx-auto my-3">
				<CustomForm
					formConfig={formConfig}
					errors={errors}
					register={register}
				/>
			</div>

			<div className="mt-5 flex gap-3">
				{active && addingPatient && patient === 'new' && (
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(onPatientAdd, {
								patient_id: "new",
								name: watch("patient_name") || "",
								surnames: watch("patient_surnames") || "",
								email: watch("patient_email") || "",
								phone_number: watch("patient_phone_number") || "",
							})}
						>
							Confirmar
						</button>
					</div>
				)}
				{active && addingPatient && (
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(setAddingPatient, false)}
						>
							Cancelar
						</button>
					</div>
				)}
			</div>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar el paciente del bono?"
				text="Las sesiones ya pagadas utilizando este bono se mantrendrán, pero a partir de ahora no se podrán pagar nuevas sesiones de este paciente con este bono."
				onConfirm={handleSubmitWithDispatch(
					dispatch,
					deletePatientAuthorizedAcquiredVoucher,
					deleteId,
					acquiredVoucherId,
					modalId,
					refresh
				)}
			/>
		</div>
	);
};

export default OverviewPatientsInAcquiredVoucher;
