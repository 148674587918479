import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import DisplayCashRegister from "../../../components/cashRegister/DisplayCashRegister";
import VoucherDisplay from "../../../components/services/voucher/voucher/VoucherDisplay";
import OverviewPatientsInAcquiredVoucher from "../../../components/services/voucher/acquiredVoucher/OverviewPatientsInAcquiredVoucher";
import OverviewSessionsInAcquiredVoucher from "../../../components/services/voucher/acquiredVoucher/OverviewSessionsInAcquiredVoucher";
import OverviewClassesInPatient from "../../../components/services/voucher/acquiredVoucher/OverviewClassesInPatient";
import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {
  getAcquiredVoucher,
  putAcquiredVoucher,
  deleteAcquiredVoucher,
} from "../../../actions/acquiredVoucher";
import { getCashRegister } from "../../../actions/cashRegister";
import { fetchData } from "../../../auxiliar/auxFunctions";

const AcquiredVoucherCard = () => {
  const modalId = "delete-acquired-voucher-modal";
  const {
    register,
    handleSubmit,
    handleSubmitWithoutValidation,
    fetchFormValues,
    resetFetchedValues,
    openModal,
    watch,
    errors,
    isSubmitting,
  } = useFormWithDisable(modalId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const type = watch("group_indiv");
  const voucherTypeId = watch("voucher_type_id");
  const dueDate = watch("due_date");
  const deletable = watch("deletable");
  const cashRegisterId = watch("cash_register_id");
  const { acquired_voucher_id: acquiredVoucherId } = useParams();
  const queryParams = useQueryParams();

  const formConfig = {
    remaining_sessions: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Sesiones restantes",
      name: "remaining_sessions",
    },
    due_date: {
      condition: true,
      disabled: !isEditing,
      type: "date",
      label: "Fecha de caducidad *",
      name: "due_date",
      validators: {
        required: "La fecha de caducidad es obligatoria",
        validate: (v) =>
          new Date(v) > new Date() ||
          "La fecha de caducidad debe ser posterior a la fecha actual",
      },
    },
  };

  const [cashRegister, setCashRegister] = useState({});
  const [refreshCashRegister, setRefreshCashRegister] = useState(false);

  useEffect(() => {
    if (acquiredVoucherId)
      fetchFormValues(getAcquiredVoucher, acquiredVoucherId);
  }, [acquiredVoucherId]);

  useEffect(() => {
    if (!isEditing && acquiredVoucherId) resetFetchedValues();
  }, [isEditing]);

  useEffect(() => {
    if (cashRegisterId)
      fetchData(dispatch, getCashRegister, cashRegisterId, setCashRegister);
  }, [cashRegisterId, refreshCashRegister]);

  return (
    <div>
      <VoucherDisplay voucherTypeId={voucherTypeId} />

      <div className="sm:container p-3 mx-auto my-5">
        <h2 className="text-primary text-2xl">Editar bono adquirido</h2>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <CustomForm
              formConfig={formConfig}
              errors={errors}
              register={register}
            />
          </div>

          <div className="mt-5 flex space-x-2 sm:space-x-4">
            {!isEditing ? (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(setIsEditing, true)}
                  disabled={isSubmitting}
                >
                  Editar
                </button>
                {deletable && (
                  <button
                    className="btn btn-error btn-sm"
                    onClick={openModal}
                    disabled={isSubmitting}
                  >
                    Eliminar
                  </button>
                )}
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(navigate, -1)}
                  disabled={isSubmitting}
                >
                  Atrás
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmit(
                    putAcquiredVoucher,
                    acquiredVoucherId,
                    handleSubmitWithoutValidation(setIsEditing, false)
                  )}
                  disabled={isSubmitting}
                >
                  Guardar
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitWithoutValidation(setIsEditing, false)}
                  disabled={isSubmitting}
                >
                  Cancelar
                </button>
              </>
            )}
          </div>
        </form>
      </div>

      <OverviewPatientsInAcquiredVoucher
        active={new Date().getTime() <= new Date(dueDate).getTime()}
      />

      {type === "individual" && (
        <OverviewSessionsInAcquiredVoucher
          acquiredVoucherId={acquiredVoucherId}
        />
      )}

      {type === "group" && (
        <OverviewClassesInPatient
          acquiredVoucherId={acquiredVoucherId}
        />
      )}

      <DisplayCashRegister data={cashRegister} mode="light" refresh={() => setRefreshCashRegister(!refreshCashRegister)} />

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar todos los datos del bono adquirido?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithoutValidation(
          deleteAcquiredVoucher,
          acquiredVoucherId,
          queryParams.get("patient")
        )}
      />
    </div>
  );
};
export default AcquiredVoucherCard;
