import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../../../components/general/form/CustomForm";
import { postVoucher } from "../../../actions/voucher";
import {
  priceValidators,
  sessionsNumberValidators,
} from "../../../auxiliar/useFormValidators";

const RegisterVoucher = () => {
  const {
    register,
    watch,
    handleSubmit,
    handleSubmitWithoutValidation,
    errors,
    isSubmitting,
  } = useFormWithDisable();

  const navigate = useNavigate();
  const { id: clinicId } = useSelector((state) => state.activeClinic);
  const type = watch("group_indiv");

  const formConfig = {
    group_indiv: {
      condition: true,
      disabled: false,
      type: "selector",
      name: "group_indiv",
      label: "Tipo de bono *",
      options: [
        { key: "individual", label: "Sesiones individuales" },
        { key: "group", label: "Clases" },
      ],
      defaultValue: "individual",
      validators: { required: "El tipo de bono es obligatorio" },
    },
    session_type_id: {
      condition: type === "individual" || type === undefined,
      disabled: false,
      type: "session_type_selector",
      name: "session_type_id",
      label: "Tipo de sesión *",
    },
    group_session_type_id: {
      condition: type === "group",
      disabled: false,
      type: "group_session_type_selector",
      name: "group_session_type_id",
      label: "Tipo de clase *",
    },
    name: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Nombre *",
      placeholder: "Nombre",
      name: "name",
      validators: { required: "El nombre es obligatorio" },
    },
    total_sessions: {
      condition: true,
      disabled: false,
      type: "number",
      label: "Número de sesiones *",
      placeholder: "Ejemplo: 10",
      name: "total_sessions",
      validators: sessionsNumberValidators,
      minValue: 1,
    },
    price: {
      condition: true,
      disabled: false,
      type: "number",
      label: "Precio del bono *",
      placeholder: "Ejemplo: 100",
      name: "price",
      info: "Precio del bono en euros",
      validators: priceValidators,
      minValue: 0,
    },
    months_validity: {
      condition: true,
      disabled: false,
      type: "number",
      label: "Meses de validez *",
      placeholder: "Ejemplo: 12",
      name: "months_validity",
      info: "Desde el día de la compra, cuando hayan pasado estos meses, el bono del paciente expirará.",
      minValue: 1,
      validators: {
        required: "Los meses de validez son obligatorios.",
        pattern: {
          value: /^\-{0,1}\d+?$/,
          message:
            "El número de meses de validez debe ser un número sin decimales",
        },
        validate: (v) =>
          Number(v) >= 0 || "Los meses de validez deben ser mayor que 0",
      },
    },
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Registro de Tipo de Bono</h2>
      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(postVoucher, clinicId)}
            disabled={isSubmitting}
          >
            Confirmar
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmitWithoutValidation(navigate, "/services")}
            disabled={isSubmitting}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterVoucher;
