import { Link } from "react-router-dom";

const Legal = () => {
  return (
    <main className="max-w-3xl my-5 md:mx-auto">
      <div className="p-5">
        <h1 className="text-3xl pb-6 font-bold">
          Aviso Legal de MiFisio Agenda
        </h1>
        <p className="whitespace-pre-wrap pb-4">
          En este documento se incluye el aviso legal de la aplicación web.
          También le recomendamos leer la{" "}
          <Link to="/privacy-policy" className="link">
            política de privacidad
          </Link>{" "}
          y el{" "}
          <Link to="/cookies-policy" className="link">
            política de cookies
          </Link>
          .
        </p>

        <h2 className="text-xl pb-4 font-bold">Información General</h2>
        <p className="whitespace-pre-wrap pb-4">
          El titular del sitio web www.mifisioagenda.com es Mfisio Schedula
          S.L., con NIF B10584274 y domicilio sito en Calle C/ Emilio Ferrari,
          10. Bajo izq., 28017, Madrid, (Madrid). La entidad se encuentra
          inscrita en el Registro Mercantil.
        </p>
        <p className="whitespace-pre-wrap pb-6">
          Con ánimo de que el uso del sitio web se ajuste a criterios de
          transparencia, claridad y sencillez, Mfisio Schedula S.L. informa al
          usuario que cualquier sugerencia, duda o consulta sobre el presente
          Aviso Legal o cualquier otro documento informativo del sitio web será
          recibida y solucionada contactando con Mfisio Schedula S.L., a través
          de la dirección de correo electrónica: info@mifisioagenda.com
        </p>

        <h2 className="text-xl pb-4 font-bold">Propiedad Intelectual</h2>
        <p className="whitespace-pre-wrap pb-6">
          Todos los contenidos que se muestran en el presente sitio web, y en
          especial diseños, textos, imágenes, logos, iconos, nombres
          comerciales, marcas o cualquier otra información susceptible de
          utilización industrial y/o comercial están protegidos por los
          correspondientes derechos de autor, no permitiendo su reproducción,
          transmisión o registro de información salvo autorización previa del
          titular, Mfisio Schedula S.L.. No obstante, el usuario podrá utilizar
          información facilitada para gestionar su pedido y los datos de
          contacto correspondientes.
        </p>

        <h2 className="text-xl pb-4 font-bold">Enlaces</h2>
        <p className="whitespace-pre-wrap pb-6">
          Mfisio Schedula S.L. no asume ninguna responsabilidad sobre los
          enlaces hacía otros sitios o páginas web que, en su caso, pudieran
          incluirse en el mismo, ya que no tiene ningún tipo de control sobre
          los mismos, por lo que el usuario accede bajo su exclusiva
          responsabilidad al contenido y en las condiciones de uso que rijan en
          los mismos.
        </p>
      </div>
    </main>
  );
};

export default Legal;
