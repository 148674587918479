const values = [
    {
        title: "Digitalización Sencilla",
        definition: "Facilitamos la transición digital con soluciones accesibles, intuitivas y beneficiosas para una gestión eficiente, y siempre para ayudar a nuestros clientes."
    },
    {
        title: "Compromiso con la Excelencia",
        definition: "Nos esforzamos por ofrecer soluciones de alta calidad y superar expectativas para impulsar el éxito de los fisioterapeutas en cualquier entorno profesional."
    },
    {
        title: "Mejora Continua",
        definition: "Nos impulsa una fuerte pasión por evolucionar y mejorar constantemente. Estamos dedicados a perfeccionar nuestras soluciones y llegar más allá del límite."
    },
    {
        title: "Enfoque Personalizado",
        definition: "Adoptamos un enfoque personalizado para entender las necesidades únicas de cada fisioterapeuta, adaptarnos a ellas y proponer soluciones."
    },
    {
        title: "Colaboración",
        definition: "Trabajamos de cerca con la comunidad de fisioterapeutas, priorizando la transparencia y la comunicación abierta, lo que nos permite entender el sector."
    },
    {
        title: "Impacto Positivo",
        definition: "Nuestro enfoque se centra en generar un impacto positivo real, mejorando la eficiencia y calidad en la práctica de la fisioterapia en el mundo real."
    }
]

const Values = () => {
    return <section className="p-4 bg-base-100 items-center justify-center px-8 md:px-28 py-24">
        <p className="mb-8 text-center text-3xl font-bold text-secondary">Nuestros Valores</p>

        <ul className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16 my-20">
            {values.map((value, index) => (
                <li key={index} className="flex bg-base-200 shadow-xl rounded-lg p-4 sm:p-8">
                    <div className="px-4 text-5xl font-extralight text-secondary hidden sm:flex">
                        0{index + 1}.
                    </div>
                    <div>
                        <div className="text-3xl text-secondary">{value.title}</div>
                        <p className="py-2 text-xl">{value.definition}</p>
                    </div>
                </li>
            ))}
        </ul>
    </section>
}

export default Values;