import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import RegisterChargePopup from "../../../components/charge/RegisterChargePopUp";
import PaginatorComponent from "../../../components/general/auxiliar/PaginatorComponent";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import {
  handleSubmit,
  handleSubmitWithDispatch,
} from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import {
  getAllCashRegisters,
  downloadCashRegisters,
} from "../../../actions/cashRegister";
import {
  downloadInvoice,
  sendInvoiceEmail,
  postInvoice,
} from "../../../actions/invoice";

const OverviewCashRegister = () => {
  const { register, watch, errors } = useFormWithDisable();
  const writtenFilter = {
    patient_name: watch("patient_name") || "",
    patient_surnames: watch("patient_surnames") || "",
    employee_id: watch("employee_id") || "all",
    payment_method: watch("payment_method") || "all",
    temporality: watch("temporality") || "all",
    period: watch("period") || "days",
    duration: watch("duration") || "",
    starting_date: watch("starting_date") || "",
    finishing_date: watch("finishing_date") || "",
    unpaid: watch("unpaid") || false,
    has_invoice: watch("has_invoice") || false,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: clinicId } = useSelector((state) => state.activeClinic);
  const [cashRegisters, setCashRegisters] = useState({ waiting: true });
  const [refreshBool, setRefreshBool] = useState(false);
  const [filter, setFilter] = useState({
    clinic_id: clinicId,
    page: 1,
    per_page: 10,
    order: "issue_date",
  });

  const disabled = usePaginationFilterEffects(filter, writtenFilter, getAllCashRegisters, setCashRegisters, setFilter);

  const formConfig = {
    patient_name: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Nombre del paciente",
      placeholder: "Nombre",
      name: "patient_name",
    },
    patient_surnames: {
      condition: true,
      disabled: false,
      type: "text",
      label: "Apellidos del paciente",
      placeholder: "Apellidos",
      name: "patient_surnames",
    },
    employee_id: {
      condition: true,
      disabled: false,
      allowAll: true,
      type: "physio_selector",
      label: "Fisioterapeuta",
      name: "employee_id",
    },
    payment_method: {
      condition: true,
      disabled: false,
      allowAll: true,
      type: "payment_method_selector",
      label: "Método de pago",
      name: "payment_method",
    },
    temporality: {
      condition: true,
      disabled: false,
      type: "selector",
      name: "temporality",
      label: "Temporalidad",
      options: [
        { key: "all", label: "Todas" },
        { key: "current", label: "Actuales" },
        { key: "future", label: "Posteriores a la fecha actual" },
        { key: "past", label: "Anteriores a la fecha actual" },
        { key: "custom", label: "Filtro personalizado" },
      ],
      defaultValue: "all",
    },
    period: {
      condition: ["future", "past", "current"].includes(
        writtenFilter.temporality
      ),
      disabled: false,
      type: "selector",
      name: "period",
      label: "Periodo temporal",
      options: [
        { key: "days", label: "Día" },
        { key: "weeks", label: "Semana" },
        { key: "months", label: "Mes" },
        { key: "years", label: "Año" },
      ],
      defaultValue: "days",
    },
    duration: {
      condition: ["future", "past"].includes(writtenFilter.temporality),
      disabled: false,
      type: "number",
      label: "Duración",
      placeholder: "Ilimitado",
      name: "duration",
      info: "Número de días, semanas, meses o años que quieres que dure el filtro. Si no lo rellenas, se tomará como ilimitado y se ignorará el periodo temporal",
      minValue: 1,
    },
    starting_date: {
      condition: writtenFilter.temporality === "custom",
      disabled: false,
      type: "date",
      label: "Fecha incial",
      name: "starting_date",
    },
    finishing_date: {
      condition: writtenFilter.temporality === "custom",
      disabled: false,
      type: "date",
      label: "Fecha final",
      name: "finishing_date",
    },
    dummy: {
      condition: writtenFilter.temporality !== "current",
      type: "dummy",
    },
    unpaid: {
      condition: true,
      disabled: false,
      label: "Excluir pagados",
      info: "Si lo seleccionas, solo se mostrarán los albaranes que todavía no han sido pagados completamente",
      type: "checkbox",
      name: "unpaid",
    },
    has_invoice: {
      condition: true,
      disabled: false,
      label: "Factura asociada",
      info: "Si lo seleccionas, solo se mostrarán los albaranes con factura ya creada",
      type: "checkbox",
      name: "has_invoice",
    },
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Albaranes</h2>

      <form className="mt-3 mb-6">
        <p className="text-primary text-xl">Filtros</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>
      </form>
      {cashRegisters.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(cashRegisters.order) ||
        !isDefined(cashRegisters.content) ||
        cashRegisters.order.length <= 0 ? (
        <p className="lg:text-lg my-3">No hay albaranes disponibles.</p>
      ) : (
        <div className="overflow-x-auto lg:overflow-x-visible overflow-y-auto lg:overflow-y-visible">
          <div className="flex justify-between">
            <p className="text-primary text-xl">
              {cashRegisters.pagination.total_items}{" "}
              {cashRegisters.pagination.total_items === 1
                ? "Albarán"
                : "Albaranes"}
            </p>
            <button
              className="btn btn-primary btn-sm mx-5"
              onClick={handleSubmitWithDispatch(
                dispatch,
                downloadCashRegisters,
                {
                  ...filter,
                  ...writtenFilter,
                  advanced_temporality:
                    isDefined(writtenFilter.temporality) &&
                    writtenFilter.temporality !== "all",
                }
              )}
            >
              Descargar{" "}
              <>
                {" "}
                <div
                  className="tooltip tooltip-left flex items-center opacity-100"
                  data-tip={
                    "Se descargarán los albaranes resultantes de los filtros aplicados"
                  }
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </div>
              </>
            </button>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Paciente</th>
                <th className="hidden sm:table-cell">Fecha</th>
                <th className="hidden md:table-cell">Precio</th>
                <th>Deuda</th>
                <th className="hidden lg:table-cell">Tipo</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {cashRegisters.order.map((id) => {
                const cashRegister =
                  cashRegisters.content && cashRegisters.content[id];

                return (
                  <tr key={id}>
                    <td className="max-w-xs">{cashRegister.name_patient}</td>
                    <td className="hidden sm:table-cell">
                      {cashRegister.issue_date}
                    </td>
                    <td className="hidden md:table-cell">
                      {parseFloat(cashRegister.final_price).toFixed(2)}€
                    </td>
                    <td>
                      {parseFloat(cashRegister.unpaid) > 0 ? parseFloat(cashRegister.unpaid).toFixed(2) + "€" : "Pagado"}
                    </td>
                    <td className="hidden lg:table-cell max-w-xs">
                      {cashRegister.name}
                    </td>
                    <td className="text-center hidden lg:table-cell">
                      <div className="dropdown dropdown-end">
                        <div
                          tabIndex={0}
                          role="button"
                          className="btn btn-primary btn-sm"
                        >
                          Opciones{" "}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="text-base-100"
                          />
                        </div>
                        <ul
                          tabIndex={0}
                          className="dropdown-content z-40 menu p-2 shadow bg-base-300 rounded-box w-52"
                        >
                          <li
                            onClick={handleSubmit(
                              navigate,
                              `/cash-register/${id}`
                            )}
                          >
                            <a>Detalles</a>
                          </li>
                          {cashRegister.unpaid > 0 && (
                            <li>
                              <RegisterChargePopup
                                cashRegisterData={cashRegister}
                                refresh={handleSubmit(
                                  setRefreshBool,
                                  !refreshBool
                                )}
                                trigger={<a>Cobrar</a>}
                              />
                            </li>
                          )}
                          {!isDefined(cashRegister.invoice_id) && (
                            <li>
                              <button
                                type="button"
                                className="disabled:cursor-not-allowed disabled:text-disabled-text disabled:bg-disabled-bg"
                                disabled={!cashRegister.is_invoice_possible}
                                onClick={handleSubmitWithDispatch(
                                  dispatch,
                                  postInvoice,
                                  clinicId,
                                  id,
                                  handleSubmit(setRefreshBool, !refreshBool)
                                )}
                              >
                                Crear Factura
                                {!cashRegister.is_invoice_possible && (
                                  <>
                                    {" "}
                                    <div
                                      className="tooltip flex items-center opacity-100"
                                      data-tip={
                                        isDefined(cashRegister.session_id)
                                          ? "Solo se puede crear una factura si el paciente ha asistido a la sesión"
                                          : "Solo se puede crear una factura si la clase ya ha tenido lugar"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="text-primary"
                                      />
                                    </div>
                                  </>
                                )}
                              </button>
                            </li>
                          )}
                          {isDefined(cashRegister.invoice_id) && (
                            <>
                              <li
                                onClick={handleSubmitWithDispatch(
                                  dispatch,
                                  downloadInvoice,
                                  cashRegister.invoice_id
                                )}
                              >
                                <a>Descargar Factura</a>
                              </li>
                              <li
                                onClick={handleSubmitWithDispatch(
                                  dispatch,
                                  sendInvoiceEmail,
                                  cashRegister.invoice_id
                                )}
                              >
                                <a>
                                  Enviar Factura{" "}
                                  <div
                                    className="tooltip flex items-center"
                                    data-tip={
                                      "La factura se enviará al correo asociado al paciente"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircleInfo}
                                      className="text-primary"
                                    />
                                  </div>
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </td>
                    <td className="text-center lg:hidden">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit(navigate, `/cash-register/${id}`)}
                      >
                        Detalles
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-5 flex justify-between">
        <PaginatorComponent
          pagination={cashRegisters.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OverviewCashRegister;
