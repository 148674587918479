import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faNewspaper } from "@fortawesome/free-solid-svg-icons";

const MissionVision = () => {
  return (
    <section className="mx-auto bg-base-200 items-center justify-center gap-16 lg:gap-20 px-8 lg:px-28 py-24">
      <div className="bg-base-100 shadow-xl rounded-lg grid grid-cols-1 lg:grid-cols-4 my-10 px-8 lg:px-14 py-4">
        <div className="col-span-4 lg:col-span-3 items-center my-4 lg:my-10">
          <div>
            <p className="text-primary text-5xl text-bold my-5 text-center lg:text-right">
              Misión
            </p>

            <h2 className="text-center lg:text-right text-base lg:text-xl my-3">
              En MiFisio Agenda, nuestra misión es simplificar la vida de los
              fisioterapeutas mediante la digitalización del día a día. Nos
              comprometemos a proporcionar soluciones innovadoras y fáciles de
              usar que optimicen la gestión de clínicas de fisioterapia,
              permitiendo a los profesionales concentrarse en lo más importante:
              la atención de calidad a sus pacientes.
            </h2>
          </div>
        </div>
        <div className="col-span-1 hidden lg:flex items-center justify-center">
          <FontAwesomeIcon
            icon={faNewspaper}
            className="text-primary text-9xl"
          />
        </div>
      </div>
      <div className="bg-base-100 shadow-xl rounded-lg grid grid-cols-1 lg:grid-cols-4 my-10 px-8 lg:px-14 py-4">
        <div className="col-span-1 hidden lg:flex items-center justify-center">
          <FontAwesomeIcon icon={faEye} className="text-primary text-9xl" />
        </div>
        <div className="col-span-4 lg:col-span-3 items-center my-4 lg:my-10">
          <div>
            <p className="text-primary text-5xl text-bold my-5 text-center lg:text-left">
              Visión
            </p>

            <h2 className="text-center lg:text-left text-base lg:text-xl my-3">
              Nos esforzamos por convertirnos en un referente del sector,
              adaptándonos de manera continua a las necesidades cambiantes de
              los fisioterapeutas. Visualizamos un futuro donde MiFisio Agenda
              sea reconocido por su excelencia en la transformación digital de
              la gestión de clínicas de fisioterapia en España. Trabajamos para
              ser la elección preferida de los profesionales, brindándoles las
              herramientas necesarias para alcanzar el máximo potencial en su
              práctica diaria.{" "}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
