import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { postAcquiredVoucher } from "../../../actions/acquiredVoucher";
import { getPatientDiscount } from "../../../actions/patient";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { sessionsNumberValidators } from "../../../auxiliar/useFormValidators";
import {
	discountValidators,
	paymentValidators,
} from "../../../auxiliar/useFormValidators";

const AcquireVoucher = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		watch,
		setValue,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const queryParams = useQueryParams();
	const activeClinicId = useSelector((state) => state.activeClinic.id);

	const voucherData = watch("voucher_data");
	const discount = watch("discount");
	const initialPrice = watch("initial_price");
	const finalPrice = watch("final_price");
	const paid = watch("paid");

	const formConfig = {
		voucher_data: {
			condition: true,
			disabled: false,
			type: "voucher_selector_with_metadata",
			label: "Tipo de bono *",
			name: "voucher_data",
		},
		employee_id: {
			condition: true,
			disabled: false,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id",
		},
		remaining_sessions: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Sesiones restantes *",
			placeholder: "Ejemplo: 10",
			name: "remaining_sessions",
			validators: sessionsNumberValidators,
			minValue: 1
		},
		due_date: {
			condition: true,
			disabled: false,
			type: "date",
			label: "Fecha de caducidad *",
			name: "due_date",
			validate: (v) =>
				new Date(v) > new Date() ||
				"La fecha de caducidad debe ser posterior a la fecha actual",
		},
		initial_price: {
			condition: true,
			disabled: true,
			type: "number",
			label: "Precio inicial",
			name: "initial_price",
			info: "Precio del bono en euros antes de aplicar el descuento",
		},
		final_price: {
			condition: true,
			disabled: true,
			type: "number",
			label: "Precio final",
			name: "final_price",
			info: "Precio del bono en euros después de aplicar el descuento",
		},
		discount: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Porcentaje de descuento",
			placeholder: "Ejemplo: 10",
			name: "discount",
			info: "Si no se introduce un valor, se guardará como 0.",
			minValue: 0,
			maxValue: 100,
			validators: discountValidators,
		},
		paid: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Cantidad pagada",
			name: "paid",
			info: "Cantidad abonada en el momento de la compra. Si no se ha hecho un pago, deja el campo vacío.",
			placeholder: "Ejemplo: 10",
			minValue: 0,
			validators: {
				...paymentValidators,
				validate: (v) => {
					const match = paymentValidators.validate(v);
					if (typeof match === "string") return match;
					else if (!isDefined(v) || parseFloat(v) === 0) return true;
					else if (parseFloat(v) > parseFloat(finalPrice)) return "El pago no puede ser mayor que el precio final";
				},
			},
		},
		payment_method: {
			condition: true,
			disabled: false,
			type: "payment_method_selector",
			label: "Método de pago",
			name: "payment_method",
			validators: {
				validate: (v) =>
					!isDefined(paid) ||
					paid === "0" ||
					v !== "select" ||
					"Elegir un método de pago es obligatorio si se ha realizado un pago",
			},
		},
	};

	useEffect(() => {
		fetchData(
			dispatch,
			getPatientDiscount,
			queryParams.get("patient"),
			(r) => setValue("discount", r?.discount || 0)
		)
	}, []);

	useEffect(() => {
		if (isDefined(voucherData) && voucherData?.split("-").length === 4) {
			const [voucherId, remainingSessions, monthsVal, price] =
				voucherData?.split("-");

			const dueDate = new Date();
			dueDate.setMonth(dueDate.getMonth() + parseInt(monthsVal));
			const d = parseInt(discount) > 100 || parseInt(discount) < 0 ? 0 : parseInt(discount)

			setValue("voucher_type_id", voucherId);
			setValue("remaining_sessions", remainingSessions);
			setValue("due_date", dueDate.toISOString().slice(0, 10));
			setValue("initial_price", price || 0);
			setValue("final_price", (price * (1 - (d || 0) / 100)).toFixed(2));
		} else {
			setValue("voucher_type_id", undefined);
			setValue("remaining_sessions", 0);
			setValue("due_date", undefined);
			setValue("initial_price", 0);
			setValue("final_price", 0);
		}
	}, [voucherData]);

	useEffect(() => {
		if (isDefined(initialPrice) && isDefined(discount)) {
			const d = parseInt(discount) > 100 || parseInt(discount) < 0 ? 0 : parseInt(discount)

			setValue(
				"final_price",
				(initialPrice * (1 - d / 100)).toFixed(2) || 0
			);
		}
	}, [discount]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Adquirir bono</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(
							postAcquiredVoucher,
							activeClinicId,
							queryParams.get("patient")
						)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(
							navigate,
							`/patient/${queryParams.get("patient")}`
						)}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
};

export default AcquireVoucher;
