import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import PaginatorComponent from "../../general/auxiliar/PaginatorComponent";
import ModalConfirm from "../../general/auxiliar/modal/ModalConfirm";
import UploadDocumentPopup from "./UploadDocumentPopup";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllDocuments, deleteDocument } from "../../../actions/document";
import {
  handleSubmit,
  handleSubmitWithDispatch,
} from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const OverviewDocuments = () => {
  const modalId = "delete-document-modal";

  const dispatch = useDispatch();
  const { patient_id: patientId } = useParams();
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);

  const [documents, setDocuments] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    per_page: 5,
    page: 1,
    clinic_id: activeClinicId,
    patient_id: patientId,
    order: "upload_date",
  });

  const [refreshBool, setRefreshBool] = useState(false);
  const refresh = () => {
    if (
      filter.page > 1 &&
      documents?.order?.length === 1 &&
      !documents?.pagination?.has_next
    ) {
      setFilter({ ...filter, page: filter.page - 1 });
    }
    setRefreshBool(!refreshBool);
  };

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllDocuments,
    setDocuments,
    setFilter,
    refreshBool
  );

  const [deleteId, setDeleteId] = useState();
  const deleteDoc = (id) => {
    setDeleteId(id);
    document.getElementById(modalId)?.showModal();
  };

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Documentos</h2>

      {documents.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(documents.order) ||
        !isDefined(documents.content) ||
        documents.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay documentos guardados para este paciente, añade el primero
          haciendo click en el botón de abajo.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th className="hidden md:table-cell">Fecha</th>
                <th className="hidden lg:table-cell">descripción</th>
                <th />
                <th />
              </tr>
            </thead>

            <tbody>
              {documents.order.map((id) => {
                const doc = documents.content && documents.content[id];

                return (
                  <tr key={id}>
                    <td>{doc.file_name}</td>
                    <td className="hidden md:table-cell">{doc.upload_date}</td>
                    <td className="hidden lg:table-cell">{doc.description}</td>
                    <td>
                      <a href={doc.download_url} download={doc.file_name}>
                        <button type="button" className="btn btn-primary btn-sm">
                          Descargar
                        </button>
                      </a>
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit(deleteDoc, id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-5 flex justify-between">
        <UploadDocumentPopup refresh={refresh} />

        <PaginatorComponent
          pagination={documents.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </div>

      <ModalConfirm
        id={modalId}
        title="¿Estás seguro de que quieres eliminar el documento?"
        text="No podremos recuperar la información más adelante."
        onConfirm={handleSubmitWithDispatch(
          dispatch,
          deleteDocument,
          deleteId,
          modalId,
          refresh
        )}
      />
    </div>
  );
};

export default OverviewDocuments;
