const NotAllowed = () => {
    return <div className="max-w-4xl m-10 md:my-20 p-10 mx-auto bg-base-100 text-center bg-base-200 shadow-xl rounded-lg">
        <h1 className="text-6xl sm:text-9xl text-bold text-primary">403</h1>
        
        <p className="text-4xl my-5 text-primary">
            Opps! Acceso no autorizado
        </p>

        <p className="text-xl my-5 text">
            La página a la que estas intentando acceder es privada. Inicia sesión con una cuenta de mánager para acceder a ella.
        </p>
    </div>
}

export default NotAllowed;