import axios from "axios";

export const getAllSessionTypes = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/session_types/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getPublicSessionTypes = (params) => {
	return async () => {
		return axios
			.get(`/api/anonymous/session_types`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getSessionType = (sessionTypeId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(sessionTypeId)) {
			navigate(`/unknown?entity=session_type&id=${sessionTypeId}`);
			return;
		}

		return axios
			.get(`/api/managers/session_types/${sessionTypeId}`)
			.then((response) => {
				return {
					...response.data.content,
					price: Number(response.data.content.price).toFixed(2),
				};
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=session_type&id=${sessionTypeId}`);
			});
	};
};

export const postSessionType = (data, clinicId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/session_types/", { ...data, clinic_id: clinicId })
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};

export const putSessionType = (data, sessionTypeId, disableEdit) => {
	return async () => {
		return axios
			.put(`/api/managers/session_types/${sessionTypeId}`, { new_info: data })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteSessionType = (sessionTypeId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/session_types/${sessionTypeId}`)
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};
