import { useMatch } from "react-router-dom";

import ThemeSelector from '../../components/general/navbar/ThemeSelector';
import Hero from '../../components/general/landingPage/Hero';
import Pricing from '../../components/general/landingPage/Pricing';
import Testimonials from '../../components/general/landingPage/Testimonials';
import FAQ from '../../components/general/landingPage/FAQ';
import CTA from '../../components/general/landingPage/CTA';


const LandingPage = () => {
    const landingPage = useMatch("/landing-page")

    return <div id="top">
        {landingPage && <div className="flex justify-end p-5">
            <ThemeSelector />
        </div>}
        {landingPage && <Hero />}
        <Pricing />
        <Testimonials />
        <FAQ />
        <CTA />
    </div>
}

export default LandingPage;