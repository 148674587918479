import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../general/form/CustomForm";
import { putManagerPassword } from "../../actions/manager";

const UpdateManagerPassword = ({ id, close }) => {
  const { register, handleSubmit, watch, errors, isSubmitting } =
    useFormWithDisable();

  const newPassword = watch("new_info_password");

  const formConfig = {
    new_info_password: {
      condition: true,
      disabled: false,
      type: "password",
      label: "Nueva contraseña *",
      placeholder: "********",
      name: "new_info_password",
      validators: {
        required: "La nueva contraseña es obligatoria",
        minLength: {
          value: 8,
          message: "La nueva contraseña debe tener al menos 8 caracteres",
        },
      },
    },
    new_info_password_rep: {
      condition: true,
      disabled: false,
      type: "password",
      label: "Repite la nueva contraseña *",
      placeholder: "********",
      name: "new_info_password_rep",
      validators: {
        required: "La confirmación de la nueva contraseña es obligatoria",
        minLength: {
          value: 8,
          message: "La nueva contraseña debe tener al menos 8 caracteres",
        },
        validate: (value) =>
          value === newPassword || "Las contraseñas no son iguales",
      },
    },
    password: {
      condition: true,
      disabled: false,
      type: "password",
      label: "Contraseña actual *",
      placeholder: "********",
      name: "password",
      validators: {
        required: "La contraseña actual es obligatoria",
      },
    },
  };

  return (
    <div className="my-3">
      <h3 className="text-primary text-xl">Cambiar contraseña</h3>
      <form className="mt-5 mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <CustomForm
            formConfig={formConfig}
            errors={errors}
            register={register}
          />
        </div>

        <div className="mt-5 flex space-x-2 sm:space-x-4">
          <button
            className="btn btn-primary btn-sm"
            onClick={close}
            disabled={isSubmitting}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(putManagerPassword, id, close)}
            disabled={isSubmitting}
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateManagerPassword;
