import axios from "axios";

import { dispatchMessageSave } from "../store/reducers/messageReducer";

export const getInvoice = (invoiceId) => {
    return async () => {
        return axios
            .get(`/api/managers/invoices/${invoiceId}`)
            .then((response) => {
                return {
                    ...response.data.content,
                    total_paid: parseFloat(response.data.content.total_paid).toFixed(2),
                };
            })
            .catch(() => { });
    };
};

export const postInvoice = (clinicId, cashRegisterId, refresh = () => { }) => {
    return async () => {
        return axios
            .post("/api/managers/invoices/", {
                clinic_id: clinicId,
                cash_register_id: cashRegisterId,
            })
            .then(() => {
                refresh();
            })
            .catch(() => { });
    };
};

export const downloadInvoice = (invoiceId) => {
    return async (dispatch) => {
        return axios
            .get(`/api/managers/invoices/download_invoice/${invoiceId}`, {
                responseType: "blob",
            })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", response.headers["x-filename"]);
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                dispatchMessageSave(dispatch, {
                    msg: "Descarga iniciada correctamente",
                    type: "info",
                });
            })
            .catch(() => { });
    };
};

export const sendInvoiceEmail = (invoiceId) => {
    return async (dispatch) => {
        return axios
            .get(`/api/managers/invoices/send_invoice_email/${invoiceId}`)
            .then(() => {
                dispatchMessageSave(dispatch, {
                    msg: "La factura se ha enviado al email correctamente",
                    type: "info",
                });
            })
            .catch(() => { });
    };
};

export const getInvoiceTemplate = (clinicId) => {
    return async () => {
        return axios
            .get(`/api/managers/clinics/${clinicId}/invoice_template`)
            .then((response) => {
                return response.data.content;
            })
            .catch(() => { });
    };
};

export const putInvoiceTemplate = (data, clinicId, close) => {
    return async () => {
        return axios
            .put(`/api/managers/clinics/update_invoice_template/${clinicId}`, {
                new_info: data,
            })
            .then(() => {
                close();
            })
            .catch(() => { });
    };
};

export const downloadInvoiceTemplate = (data, clinicId) => {
    return async () => {
        return axios
            .post(
                `/api/managers/invoices/example_template/${clinicId}`,
                { data },
                { responseType: "blob" }
            )
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", response.headers["x-filename"]);
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(() => { });
    };
};
