import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import CustomForm from "../../../general/form/CustomForm";
import ModalConfirm from "../../../general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { isDefined, maxLength } from "../../../../auxiliar/formatValidators";
import { discountValidators, paymentValidators, priceValidators } from "../../../../auxiliar/useFormValidators";
import { deleteSession, getEditSession, putSession } from "../../../../actions/session";

const EditSession = ({
	closeTooltip,
	orgSessionId,
	openTooltipRegister
}) => {
	const modalId = "delete-session-modal";
	const {
		register,
		setValue,
		watch,
		resetForm,
		isSubmitting,
		fetchFormValues,
		originalValues,
		errors,
		handleSubmit,
		handleSubmitWithoutValidation,
		openModal
	} = useFormWithDisable(modalId);

	const activeClinic = useSelector((state) => state.activeClinic);

	const comments = watch("comment");
	const sessionReason = watch("session_reason");
	const paymentTypeNew = watch("payment_type_new");
	const sessionTypeId = watch("session_type_id");
	const displayAll = watch("display_all")
	const debt = watch("debt");
	const paid = watch("paid");
	const discount = watch("discount");
	const startDatetime = watch("start_datetime");
	const sessionTypeData = watch("session_type_data");

	const formConfig = {
		session_title: {
			condition: true,
			type: "title",
			text: "Sesión",
			classname: "text-primary text-lg col-span-1 md:col-span-2",
		},
		start_datetime: {
			condition: true,
			disabled: originalValues.attended,
			type: "datetime-local",
			label: "Fecha y hora de inicio *",
			name: "start_datetime",
			validators: {
				required: "Elegir una fecha y hora de inicio es obligatorio",
				validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
			},
		},
		finish_datetime: {
			condition: true,
			disabled: true,
			type: "datetime-local",
			label: "Fecha y hora de fin *",
			name: "finish_datetime",
			validators: {
				required: "Elegir una fecha y hora de fin es obligatorio",
				validate: (v) => isDefined(v) || "Elegir una fecha y hora de fin es obligatorio",
			},
		},
		employee_id: {
			condition: true,
			disabled: originalValues.attended,
			defaultValue: originalValues.employee_id,
			type: "physio_selector",
			label: "Fisioterapeuta",
			name: "employee_id",
		},
		session_type_data: {
			condition: true,
			disabled: originalValues.attended || isDefined(originalValues.payment_type),
			defaultValue: originalValues.session_type_id,
			type: "session_type_selector_with_metadata",
			label: "Tipo de sesión",
			name: "session_type_data",
		},
		comment: {
			condition: true,
			disabled: false,
			name: "comment",
			label: `Comentario (${comments?.length || 0}/10000)`,
			type: "textarea",
			validators: {
				validate: maxLength(10000),
			},
		},
		session_reason: {
			condition: true,
			disabled: false,
			name: "session_reason",
			label: `Motivo de consulta (${sessionReason?.length || 0}/1000)`,
			type: "textarea",
			validators: {
				validate: maxLength(1000),
			},
		},
		attended: {
			condition: true,
			disabled: false,
			type: "checkbox",
			label: "Asistido",
			name: "attended",
		},
		// patient
		patient_selector: {
			condition: true,
			type: "patient_selector",
			watch: watch,
			filter: {
				clinic_id: activeClinic.id,
				per_page: 5,
				order: "name",
				session_id: orgSessionId,
				patient_id: originalValues?.patient_id,
			},
			setValue: setValue,
			onClientSelect: (data) => {
				setValue("patient_id", data.patient_id);
				setValue("patient_name", data.name);
				setValue("patient_surnames", data.surnames);
				setValue("patient_email", data.email);
				setValue("patient_phone_number", data.phone_number);
			},
		},
		// payment
		payment_title: {
			condition: true,
			type: "title",
			text: "Pago",
			classname: "text-primary text-lg col-span-1 md:col-span-2",
		},
		payment_type_new: {
			condition: true,
			disabled: isDefined(originalValues.payment_type),
			type: "selector",
			name: "payment_type_new",
			label: "Metodo de pago",
			options: [
				{ key: "cash_register", label: "Pago simple" },
				{ key: "voucher", label: "Bono adquirido" },
			],
			classname: "col-span-1 md:col-span-2",
			defaultValue: isDefined(originalValues.payment_type) ? originalValues.payment_type : "cash_register"
		},
		// cash register payment
		initial_price: {
			condition: originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register",
			disabled: true,
			type: "number",
			label: "Precio inicial",
			name: "initial_price",
			info: "Precio de la sesión en euros antes de aplicar el descuento",
		},
		final_price: {
			condition: originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register",
			disabled: true,
			type: "number",
			label: "Precio final",
			name: "final_price",
			info: "Precio de la sesión en euros después de aplicar el descuento",
			placeholder: "0",
			validators: priceValidators,
		},
		discount: {
			condition: originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register",
			disabled: originalValues.payment_type === "cash_register",
			type: "number",
			label: "Porcentaje de descuento",
			placeholder: "Ejemplo: 10",
			name: "discount",
			info: "Si no se introduce un valor, se guardará como 0.",
			validators: discountValidators,
			minValue: 0,
			maxValue: 100
		},
		debt: {
			condition: originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register",
			disabled: true,
			type: "number",
			label: "Por pagar",
			name: "debt",
			info: "Cantidad pendiente de pago en euros",
		},
		paid: {
			condition: (originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register")
				&& parseFloat(originalValues.debt) > 0,
			disabled: false,
			type: "number",
			label: "Cantidad pagada",
			name: "paid",
			info: "Cantidad abonada en euros.",
			placeholder: "Ejemplo: 0",
			minValue: 0,
			validators: {
				...paymentValidators,
				validate: (v) => {
					const match = paymentValidators.validate(v);
					if (typeof match === "string") return match;
					else if (!isDefined(v) || parseFloat(v) === 0) return true;
					else if (parseFloat(v) > parseFloat(debt)) return "El pago no puede ser mayor que la deuda";
				},
			},
		},
		payment_method: {
			condition: (originalValues.payment_type === "cash_register" || paymentTypeNew === "cash_register")
				&& parseFloat(originalValues.debt) > 0,
			disabled: false,
			type: "payment_method_selector",
			label: "Método de pago",
			name: "payment_method",
			validators: {
				validate: (v) =>
					!isDefined(paid) ||
					parseFloat(paid) === 0 ||
					v !== "select" ||
					"Elegir un método de pago es obligatorio si se ha realizado un pago",
			},
		},
		// acquired voucher payment
		acquired_voucher_id: {
			condition: originalValues.payment_type === "voucher" || paymentTypeNew === "voucher",
			disabled: isDefined(originalValues.payment_type),
			classname: "col-span-1 md:col-span-2",
			type: "acquired_voucher_selector",
			label: "Bono",
			name: "acquired_voucher_id",
			setValue: setValue,
			patientId: originalValues.patient_id,
			defaultValue: originalValues.acquired_voucher_id,
			displayAll: displayAll,
			sessionTypeId: sessionTypeId
		},
		display_all: {
			condition: paymentTypeNew === "voucher" && !isDefined(originalValues.payment_type),
			disabled: isDefined(originalValues.payment_type),
			classname: "col-span-1 md:col-span-2",
			type: "checkbox",
			label: "Mostrar bonos de todos los tipos de sesión",
			name: "display_all",
		},
	};

	// Sets the final price based on the discount
	useEffect(() => {
		if (!isDefined(sessionTypeData) || !isDefined(discount)) return;

		const d = parseInt(discount) > 100 || parseInt(discount) < 0 ? 0 : parseInt(discount)

		const initialPrice = Number(sessionTypeData?.split("-")[2] || originalValues.initial_price).toFixed(2);
		const finalPrice = (parseFloat(initialPrice) - (parseFloat(initialPrice) * parseInt(d) / 100)).toFixed(2);
		setValue("final_price", finalPrice)

		if (!isDefined(originalValues.payment_type)) {
			setValue("initial_price", initialPrice)
			setValue("debt", finalPrice)
		}
	}, [discount, sessionTypeData])

	// Sets the finish date and hour based on the session type duration
	useEffect(() => {
		if (!isDefined(sessionTypeData)) return;

		const sessionTypeId = sessionTypeData.split("-")[0];
		if (isDefined(sessionTypeId)) {
			setValue("session_type_id", sessionTypeId)
		}

		const sessionDuration = sessionTypeData.split("-")[1] || 60;

		if (isDefined(startDatetime)) {
			let startingTime = new Date(startDatetime).getTime();
			if (isNaN(startingTime)) startingTime = new Date().getTime();

			setValue("finish_datetime", format(
				new Date(startingTime + sessionDuration * 60000),
				"yyyy-MM-dd'T'HH:mm"
			).toString())
		}
	}, [sessionTypeData, startDatetime]);

	const [sessionId, setSession] = useState(orgSessionId);
	const [deletingIndividual, setDeletingIndividual] = useState(true);

	useEffect(() => {
		fetchFormValues(getEditSession, sessionId);
	}, [sessionId]);

	const loadNewSession = (id) => {
		resetForm();
		setSession(id);
	};

	const handleClose = () => {
		closeTooltip();
		resetForm();
	}

	const handleOpenModal = (deletingIndividual) => {
		setDeletingIndividual(deletingIndividual);
		openModal();
	}

	if (!isDefined(originalValues) || !isDefined(originalValues.start_datetime)) return null;

	const onCopyHandler = (...args) => {
		closeTooltip()
		openTooltipRegister?.current(...args)
	}

	return (
		<div>
			<div className="flex justify-between">
				<h2 className="text-primary text-2xl">
					Editar Sesión
				</h2>
				<div className="mt-2">
					<button
						className={`btn btn-primary btn-sm ${(originalValues.previous_session_id || originalValues.next_session_id) ? "mx-2" : "mx-0"}`}
						onClick={handleSubmitWithoutValidation(onCopyHandler, {
							startAt: originalValues.start_datetime,
							endAt: originalValues.finish_datetime,
							employee_id: originalValues.employee_id,
							patient_id: originalValues.patient_id,
							session_type_id: originalValues.session_type_id,
						}, true)}
					>
						Copiar
					</button>
					<div className={`join ${(originalValues.previous_session_id || originalValues.next_session_id) ? "mx-2" : "mx-0"}`}>
						{isDefined(originalValues.previous_session_id) && (
							<button
								className={"join-item btn btn-primary btn-sm"}
								onClick={handleSubmitWithoutValidation(loadNewSession, originalValues.previous_session_id)}
							>
								«
							</button>
						)}
						{isDefined(originalValues.next_session_id) && (
							<button
								className={"join-item btn btn-primary btn-sm"}
								onClick={handleSubmitWithoutValidation(loadNewSession, originalValues.next_session_id)}
							>
								»
							</button>
						)}
					</div>
				</div>
			</div>

			<form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
				<CustomForm
					formConfig={formConfig}
					errors={errors}
					register={register}
				/>
			</form>

			<div className="mt-5 flex space-x-2 sm:space-x-4">
				<button
					className="btn btn-primary btn-sm"
					onClick={handleSubmit(putSession, sessionId, handleClose, originalValues.attended)}
					disabled={isSubmitting}
				>
					Guardar
				</button>
				<button
					className="btn btn-primary btn-sm"
					onClick={handleSubmitWithoutValidation(handleClose)}
					disabled={isSubmitting}
				>
					Cancelar
				</button>
				<button
					className="btn btn-error btn-sm"
					onClick={handleSubmitWithoutValidation(handleOpenModal, true)}
					disabled={isSubmitting}
				>
					Eliminar
				</button>
				{isDefined(originalValues.next_session_id) && <button
					className="btn btn-error btn-sm"
					onClick={handleSubmitWithoutValidation(handleOpenModal, false)}
					disabled={isSubmitting}
				>
					Eliminar todas
				</button>}
			</div>

			<ModalConfirm
				id={modalId}
				title={deletingIndividual ?
					"¿Estás seguro de que quieres eliminar la sesión?" :
					"¿Estás seguro de que quieres eliminar la sesión y las sesiones futuras?"
				}
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithoutValidation(deleteSession, sessionId, handleClose, !deletingIndividual)}
			/>
		</div>
	);
};

export default EditSession;
