import { cookiesTypes } from "./types";

/*
	This reducer is used to save the cookies in the store and manage 
	the cookies consent. It is a bit more complex than the other reducers
	bacause it has to manage the cookies object and the accepted boolean.

	For that reason, the data structure looks like this:
		{
			cookies: { cookieName: cookieValue, ...} 
			accepted: boolean
		}
*/

export const cookiesReducer = (state = {}, action) => {
	switch (action.type) {
		case cookiesTypes.save:
			return { ...state, cookies: action.payload.cookies };
		case cookiesTypes.clean:
			return { ...state, cookies: {} };
		case cookiesTypes.accept:
			return { ...state, accepted: true };
		default:
			return state;
	}
};

export const dispatchCookiesSave = (dispatch, cookies) => {
	dispatch({
		type: cookiesTypes.save,
		payload: {
			cookies: cookies,
		},
	});
};

export const dispatchCookiesClean = (dispatch) => {
	dispatch({
		type: cookiesTypes.clean,
	});
};

export const dispatchCookiesAccept = (dispatch) => {
	dispatch({
		type: cookiesTypes.accept,
	});
};
