import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ThemeSelector from "./ThemeSelector";
import ClinicSelectorDropdown from "./ClinicSelectorDropdown";
import { logout } from "../../../actions/auth";
import { handleSubmitWithDispatch, handleSubmit } from "../../../auxiliar/auxFunctions";

const NavbarLoggedIn = () => {
	const dispatch = useDispatch();
	const userType = useSelector((state) => state.auth.user_type);
	const clinicID = useSelector((state) => state.activeClinic.id);

	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	const handleLinkClick = () => {
		setIsNavbarOpen(false);
	};

	return (
		<div className="navbar bg-base-300">
			<div className="navbar-start">
				<div className="dropdown">
					<div
						tabIndex={0}
						role="button"
						className="btn btn-ghost xl:hidden my-0 p-0"
						onClick={handleSubmit(setIsNavbarOpen, !isNavbarOpen)}
					>
						<div tabIndex={0} role="button" className="btn btn-ghost xl:hidden">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h8m-8 6h16"
								/>
							</svg>
						</div>
					</div>
					<ul
						tabIndex={0}
						className={`menu menu-sm dropdown-content mt-3 z-40 p-2 shadow bg-base-300 rounded-box w-52 ${isNavbarOpen ? 'block' : 'hidden'}`}
					>
						{/* General endpoints */}
						<li>
							<Link to="/agenda" onClick={handleLinkClick}>Agenda</Link>
						</li>
						<li>
							<Link to="/physiotherapist" onClick={handleLinkClick}>Fisios</Link>
						</li>
						<li>
							<Link to="/services" onClick={handleLinkClick}>Servicios</Link>
						</li>
						<li>
							<Link to="/patient" onClick={handleLinkClick}>Pacientes</Link>
						</li>

						{/* Manager pages */}
						{userType === "manager" && (
							<>
								<li>
									<Link to="/cash-register" onClick={handleLinkClick}>Facturación</Link>
								</li>
								<li>
									<Link to="/clinic" onClick={handleLinkClick}>Clínicas</Link>
								</li>
								<li>
									<Link to="/profile" onClick={handleLinkClick}>Perfil</Link>
								</li>
							</>
						)}

						{/* Clinic pages */}
						{userType === "clinic" && (
							<>
								<li>
									<Link to={`/clinic/${clinicID}`} onClick={handleLinkClick}>Clínica</Link>
								</li>
							</>
						)}

						{/* General pages */}
						<li>
							<Link to="/task" onClick={handleLinkClick}>Tareas</Link>
						</li>
					</ul>
				</div>

				<Link className="btn btn-ghost text-xl hidden xl:flex" to="/agenda">
					MiFisio
				</Link>

				<div className="hidden sm:flex">
					<ClinicSelectorDropdown />
				</div>
			</div>
			<div className="navbar-center">
				<ul className="menu menu-horizontal px-1 hidden xl:flex">
					{/* General endpoints */}
					<li>
						<Link to="/agenda">Agenda</Link>
					</li>
					<li>
						<Link to="/physiotherapist">Fisios</Link>
					</li>
					<li>
						<Link to="/services">Servicios</Link>
					</li>
					<li>
						<Link to="/patient">Pacientes</Link>
					</li>

					{/* Manager pages */}
					{userType === "manager" && (
						<>
							<li>
								<Link to="/cash-register">Facturación</Link>
							</li>
							<li>
								<Link to="/clinic">Clínicas</Link>
							</li>
							<li>
								<Link to="/profile">Perfil</Link>
							</li>
						</>
					)}

					{/* Clinic pages */}
					{userType === "clinic" && (
						<>
							<li>
								<Link to={`/clinic/${clinicID}`}>Clínica</Link>
							</li>
						</>
					)}

					{/* General pages */}
					<li>
						<Link to="/task">Tareas</Link>
					</li>
				</ul>

				<Link className="btn btn-ghost text-xl flex xl:hidden" to="/agenda">
					MiFisio
				</Link>
			</div>
			<div className="navbar-end gap-4">
				<ThemeSelector />

				<button
					className="btn btn-ghost"
					onClick={handleSubmitWithDispatch(dispatch, logout)}
				>
					Log out
				</button>
			</div>
		</div>
	);
};

export default NavbarLoggedIn;
