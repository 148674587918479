import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import {
  handleSubmit,
  handleSubmitWithDispatch,
} from "../../auxiliar/auxFunctions";
import { postInvoice } from "../../actions/invoice";
import RegisterChargePopup from "../charge/RegisterChargePopUp";
import { isDefined } from "../../auxiliar/formatValidators";

const DisplayCashRegister = ({ data, mode = "full", refresh = () => { } }) => {
  const { register, setValue, resetForm, watch, errors } = useFormWithDisable();

  const invoiceId = watch("invoice_id");
  const invoicePossible = watch("is_invoice_possible");
  const sessionId = watch("session_id");

  const formConfig = {
    initial_price: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Precio inicial",
      name: "initial_price",
    },
    discount: {
      condition: true,
      disabled: true,
      type: "text",
      label: "Descuento",
      name: "discount",
    },
    final_price: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Precio final",
      name: "final_price",
    },
    unpaid: {
      condition: true,
      disabled: true,
      type: "number",
      label: "Por pagar",
      name: "unpaid",
    },
    issue_date: {
      condition: true,
      disabled: true,
      type: "date",
      label: "Fecha emisión",
      name: "issue_date",
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeClinicId = useSelector((state) => state.activeClinic.id);

  useEffect(() => {
    if (data) {
      resetForm();
      for (const [key, value] of Object.entries(data)) {
        if (
          key === "initial_price" ||
          key === "final_price" ||
          key === "unpaid"
        ) {
          setValue(key, parseFloat(value).toFixed(2));
        } else if (key === "discount") {
          setValue(key, `${parseInt(value).toFixed(0)}%`);
        } else {
          setValue(key, value);
        }
      }
    }
  }, [data]);

  return (
    <div
      className={
        mode === "popup"
          ? "p-3 mx-auto"
          : "sm:container p-3 mx-auto my-5"
      }
    >
      <h2 className="text-primary text-2xl flex">Albarán {!isDefined(invoiceId) && !invoicePossible && (
        <>
          {" "}
          <div
            className="tooltip tooltip-right flex items-center opacity-100 text-xs"
            data-tip={
              isDefined(sessionId)
                ? "Solo se puede crear una factura si el paciente ha asistido a la sesión"
                : "Solo se puede crear una factura si la clase ya ha tenido lugar"
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="text-primary"
            />
          </div>
        </>
      )}</h2>
      <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
        <CustomForm
          formConfig={formConfig}
          errors={errors}
          register={register}
        />
      </form>

      <div className="mt-5 flex space-x-2 sm:space-x-4">
        {parseFloat(data?.unpaid) > 0 && mode !== "popup" && (
          <RegisterChargePopup cashRegisterData={data} refresh={refresh} />
        )}
        {mode === "full" && (
          <>
            {
              !isDefined(invoiceId) && <button
                className="btn btn-primary btn-sm"
                disabled={!invoicePossible}
                onClick={handleSubmitWithDispatch(
                  dispatch,
                  postInvoice,
                  activeClinicId,
                  data?.cash_register_id,
                  refresh
                )}
              >
                Crear Factura
              </button>
            }

            <button
              className="btn btn-primary btn-sm"
              onClick={handleSubmit(navigate, -1)}
            >
              Atrás
            </button>
          </>
        )}
        {mode === "light" && (
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit(
              navigate,
              `/cash-register/${data.cash_register_id}`
            )}
          >
            Detalles
          </button>
        )}
      </div>
    </div>
  );
};

export default DisplayCashRegister;
