import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { fetchData, debounce } from '../auxFunctions';

// Necessary to block the paginator buttons while fetching data
const usePaginationFilterEffects = (filter, writtenFilter, fetcher, setData, setFilter, ...args) => {
	const dispatch = useDispatch();

	const [initialFetched, setInitialFetched] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [debouncedFilter, setDebouncedFilter] = useState(writtenFilter);

	const processChange = useMemo(() => debounce((newFilter) => setDebouncedFilter(newFilter), 300), []);

	useEffect(() => {
		processChange(writtenFilter);
	}, [JSON.stringify(writtenFilter)]);

	const getData = async () => {
		setDisabled(true);
		await fetchData(
			dispatch,
			fetcher,
			{ ...filter, ...debouncedFilter },
			setData
		);
		setDisabled(false);
	}

	useEffect(() => {
		if (filter?.page > 1) {
			setFilter({
				...filter,
				page: 1,
			});
		} else {
			if (!initialFetched) setInitialFetched(true);
			getData();
		}
	}, [debouncedFilter, ...args]);

	useEffect(() => {
		if (initialFetched)
			getData();
	}, [
		filter,
		dispatch,
		setData,
	]);

	return disabled;
};

export default usePaginationFilterEffects;