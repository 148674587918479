import { useNavigate } from "react-router-dom";

import { handleSubmit } from "../../../auxiliar/auxFunctions";

const PaymentError = () => {
  const navigate = useNavigate();

  return (
    <section className="max-w-7xl mx-auto bg-base-100 grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-10">
      <div className="flex flex-col gap-10 lg:gap-14 text-center items-center">
        <div>
          <img src={require("../../../media/icon/icon_125.png")} alt={"Logo"} />
        </div>
      </div>

      <div className="md:col-span-2">
        <div className="flex my-5 md:my-20 justify-center">
          <div className="bg-base-200 shadow-xl rounded-lg p-10">
            <p className="text-primary text-2xl text-center my-5">
              Se ha producido un error al procesar el pago
            </p>

            <p className="text-center text-base lg:text-lg my-3">
              Durante el proceso de cobro se ha producido un error. Por favor,
              inténtalo de nuevo y si el error persiste contacta con nosotros en {""}
              <a href="mailto:soporte@mifisioagenda.com">soporte@mifisioagenda.com</a>.
            </p>

            <p className="text-center text-base lg:text-lg my-3">
              El cobro mensual se realiza el día 1 de cada mes y si este falla, se reintenta
              el día 4. En el caso de volver a fallar, la clínica se bloqueará hasta que se
              realice el pago, por lo que no podrá seguir utilizando MiFisio Agenda.
            </p>

            <p className="text-center text-base lg:text-lg my-3">
              Recuerda que puedes añadir un nuevo método de pago desde la pestaña "clínica", que el
              equipo de MiFisio Agenda estará encantado de ayudarte en todo lo que necesites.
              Muchas gracias por confiar en nosotros.
            </p>

            <div className="text-center">
              <button className="btn btn-primary" onClick={handleSubmit(navigate, "/clinic")}>
                Mis Clínicas
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentError;
