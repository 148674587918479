import { useState } from "react";
import { Link } from "react-router-dom";

import ThemeSelector from "./ThemeSelector";
import { handleSubmit } from "../../../auxiliar/auxFunctions";

const NavbarLoggedOut = () => {

	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	const handleLinkClick = () => {
		setIsNavbarOpen(false);
	};

	return (
		<div className="navbar bg-base-300">
			<div className="navbar-start">
				<div className="dropdown">
					<div
						tabIndex={0}
						role="button"
						className="btn btn-ghost lg:hidden"
						onClick={handleSubmit(setIsNavbarOpen, !isNavbarOpen)}
					>
						<div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h8m-8 6h16"
								/>
							</svg>
						</div>
					</div>
					<ul
						tabIndex={0}
						className={`menu menu-sm dropdown-content mt-3 z-40 p-2 shadow bg-base-300 rounded-box w-52 ${isNavbarOpen ? 'block' : 'hidden'}`}
					>
						<li>
							<Link to="/login" onClick={handleLinkClick}>Iniciar sesión</Link>
						</li>
						<li>
							<Link to="/register" onClick={handleLinkClick}>Registro</Link>
						</li>
						<li>
							<Link to="/info" onClick={handleLinkClick}>Información</Link>
						</li>
					</ul>
				</div>

				<Link className="btn btn-ghost text-xl hidden lg:flex" to="/login">
					MiFisio
				</Link>
			</div>
			<div className="navbar-center">
				<ul className="menu menu-horizontal px-1 hidden lg:flex">
					<li>
						<Link to="/login">Iniciar sesión</Link>
					</li>
					<li>
						<Link to="/register">Registro</Link>
					</li>
					<li>
						<Link to="/info">Información</Link>
					</li>
				</ul>

				<Link className="btn btn-ghost text-xl flex lg:hidden" to="/login">
					MiFisio
				</Link>
			</div>
			<div className="navbar-end">
				<ThemeSelector />
			</div>
		</div>
	);
};

export default NavbarLoggedOut;
