import { useEffect } from "react";
import CustomForm from "../../../../general/form/CustomForm";

import { isDefined } from "../../../../../auxiliar/formatValidators";
import { priceValidators, discountValidators, compulsoryPaymentValidators } from "../../../../../auxiliar/useFormValidators";

const PaymentInClass = ({
    classId,
    metadata,
    register,
    watch,
    setValue,
    errors,
    isSubmitting,
    onCancelPaymentClick,
    onConfirmPaymentClick
}) => {
    const paymentTypeNew = watch("payment_type_new");
    const discount = watch("discount");
    const displayAll = watch("display_all")
    const debt = watch("debt");
    const paymentMethod = watch("payment_method");

    // Set the initial values of the form
    useEffect(() => {
        if (metadata) {
            const d = parseInt(metadata.discount) > 100 || parseInt(metadata.discount) < 0 ? 0 : parseInt(metadata.discount)

            setValue("patient_id", metadata.patient_id);
            setValue("initial_price", parseFloat(metadata?.initial_price || 0).toFixed(2));
            setValue("discount", d);
            setValue("debt", parseFloat(metadata?.debt || 0).toFixed(2));
            setValue("acquired_voucher_id", metadata.acquired_voucher_id);
            setValue("paid", "");
            setValue("payment_method", "select");
        }
    }, [metadata])

    // Sets the final price and debt based on the discount
    useEffect(() => {
        const initialPrice = parseFloat(metadata?.initial_price || "0").toFixed(2);
        const discount = parseInt(watch("discount") || "0");
        const d = parseInt(discount) > 100 || parseInt(discount) < 0 ? 0 : parseInt(discount)

        const finalPrice = (initialPrice - (initialPrice * d / 100)).toFixed(2);
        setValue("final_price", finalPrice)

        if (!isDefined(metadata.cash_register_id)) {
            setValue("debt", finalPrice);
        }
    }, [discount])

    const formConfig = {
        // payment type
        payment_type_new: {
            condition: true,
            disabled: isDefined(metadata.cash_register_id) || isDefined(metadata.acquired_voucher_id),
            type: "selector",
            name: "payment_type_new",
            label: "Metodo de pago",
            options: [
                { key: "cash_register", label: "Pago simple" },
                { key: "voucher", label: "Bono adquirido" },
            ],
            classname: "col-span-1 md:col-span-2",
            defaultValue: isDefined(metadata.acquired_voucher_id) ? "voucher" : "cash_register"
        },
        // cash register payment
        initial_price: {
            condition: isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register",
            disabled: true,
            type: "number",
            label: "Precio inicial",
            name: "initial_price",
            info: "Precio de la sesión en euros antes de aplicar el descuento",
        },
        final_price: {
            condition: isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register",
            disabled: true,
            type: "number",
            label: "Precio final",
            name: "final_price",
            info: "Precio de la sesión en euros después de aplicar el descuento",
            placeholder: "0",
            validators: priceValidators,
        },
        discount: {
            condition: isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register",
            disabled: isDefined(metadata.cash_register_id),
            defaultValue: metadata.discount,
            type: "number",
            label: "Porcentaje de descuento",
            placeholder: "Ejemplo: 10",
            name: "discount",
            info: "Si no se introduce un valor, se guardará como 0.",
            validators: discountValidators,
            minValue: 0,
            maxValue: 100
        },
        debt: {
            condition: isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register",
            disabled: true,
            type: "number",
            label: "Por pagar",
            name: "debt",
            info: "Cantidad pendiente de pago en euros",
        },
        paid: {
            condition: (isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register")
                && parseFloat(metadata.debt) > 0,
            disabled: false,
            type: "number",
            label: "Cantidad pagada",
            name: "paid",
            info: "Cantidad abonada en euros.",
            placeholder: "Ejemplo: 10",
            minValue: 0,
            validators: compulsoryPaymentValidators(parseFloat(debt)),
        },
        payment_method: {
            condition: (isDefined(metadata.cash_register_id) || paymentTypeNew === "cash_register")
                && parseFloat(metadata.debt) > 0,
            disabled: false,
            type: "payment_method_selector",
            label: "Método de pago",
            name: "payment_method",
            validators: {
                validate: (v) => {
                    if (!isDefined(paymentMethod) || paymentMethod === "select") return "El metodo de pago es obligatorio";
                }
            },
        },
        // acquired voucher payment
        acquired_voucher_id: {
            condition: isDefined(metadata.acquired_voucher_id) || paymentTypeNew === "voucher",
            disabled: isDefined(metadata.acquired_voucher_id),
            classname: "col-span-1 md:col-span-2",
            type: "acquired_voucher_selector",
            label: "Bono",
            name: "acquired_voucher_id",
            setValue: setValue,
            patientId: metadata.patient_id,
            defaultValue: metadata.acquired_voucher_id,
            displayAll: displayAll,
            groupSessionTypeId: classId,
        },
        display_all: {
            condition: paymentTypeNew === "voucher" && !(isDefined(metadata.cash_register_id) || isDefined(metadata.acquired_voucher_id)),
            disabled: isDefined(metadata.cash_register_id) || isDefined(metadata.acquired_voucher_id),
            classname: "col-span-1 md:col-span-2",
            type: "checkbox",
            label: "Mostrar bonos de todos los tipos de sesión",
            name: "display_all",
        },
    }

    return (
        <>
            <div className="flex justify-between">
                <h4 className="text-primary text-lg mt-3">Pago</h4>

                <div>
                    <button
                        className="btn btn-primary btn-sm mt-3 mx-3"
                        onClick={onConfirmPaymentClick}
                        disabled={isSubmitting}
                    >
                        Confirmar pago
                    </button>
                    <button
                        className="btn btn-primary btn-sm mt-3"
                        onClick={onCancelPaymentClick}
                        disabled={isSubmitting}
                    >
                        Cerrar pago
                    </button>
                </div>

            </div>
            <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />
            </form>
        </>
    );
}

export default PaymentInClass;