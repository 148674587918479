import { useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from 'date-fns';

import CustomForm from "../../../general/form/CustomForm";
import RegisterUnconfirmedSession from "../popupUnconfirmedSession/RegisterUnconfirmedSession";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { acceptUnconfirmedSession, rejectUnconfirmedSession, getUnconfirmedSessionByID } from "../../../../actions/unconfirmedSession";
import { isDefined } from "../../../../auxiliar/formatValidators";

const EditUnconfirmedSession = ({
    closeTooltip,
    orgSessionId,
}) => {
    const modalId = "delete-session-modal";

    const {
        register,
        setValue,
        watch,
        isSubmitting,
        fetchFormValues,
        originalValues,
        errors,
        handleSubmit,
        handleSubmitWithoutValidation,
        resetFetchedValues,
    } = useFormWithDisable(modalId);

    const clinicId = useSelector((state) => state.activeClinic.id);
    const sessionTypeData = watch("session_type_data");
    const startDatetime = watch("start_datetime");
    const sessionReason = watch("session_reason");
    const comment = watch("comment");

    // Fetches the unconfirmed session data
    useEffect(() => {
        if (orgSessionId) fetchFormValues(getUnconfirmedSessionByID, orgSessionId);
    }, [orgSessionId]);

    // Sets the finish date and hour based on the session type duration
    useEffect(() => {
        const sessionTypeId = sessionTypeData?.split("-")[0];
        if (isDefined(sessionTypeId)) {
            setValue("session_type_id", sessionTypeId)
        }

        const sessionDuration = sessionTypeData?.split("-")[1] || 60;

        let startingTime = new Date(startDatetime).getTime();
        if (isNaN(startingTime)) startingTime = new Date().getTime();

        setValue("finish_datetime", format(
            new Date(startingTime + sessionDuration * 60000),
            "yyyy-MM-dd'T'HH:mm"
        ).toString())
    }, [sessionTypeData, startDatetime]);

    const formConfig = {
        employee_id: {
            condition: true,
            disabled: false,
            type: "physio_selector",
            label: "Fisioterapeuta *",
            name: "employee_id",
        },
        session_type_data: {
            condition: true,
            disabled: false,
            type: "session_type_selector_with_metadata",
            label: "Tipo de sesión *",
            name: "session_type_data",
            defaultValue: originalValues?.session_type_id,
        },
        start_datetime: {
            condition: true,
            disabled: false,
            type: "datetime-local",
            label: "Fecha y hora de inicio *",
            name: "start_datetime",
            validators: {
                required: "Elegir una fecha y hora de inicio es obligatorio",
            },
        },
        finish_datetime: {
            condition: true,
            disabled: true,
            type: "datetime-local",
            label: "Fecha y hora de fin *",
            name: "finish_datetime",
            validators: {
                required: "Elegir una fecha y hora de fin es obligatorio",
                validate: (v) => {
                    if (!isDefined(v)) return "Elegir una fecha y hora de fin es obligatorio"
                    if (new Date(v).getTime() <= new Date(startDatetime).getTime()) return "La fecha de fin debe ser posterior a la de inicio"
                }
            },
        },
        comment: {
            condition: true,
            disabled: false,
            type: "textarea",
            label: `Comentario de la sesión (${comment?.length || 0}/10000)`,
            placeholder: "...",
            name: "comment",
            validators: {
                maxLength: {
                    value: 10000,
                    message: "El comentario es demasiado largo",
                },
            },
        },
        session_reason: {
            condition: true,
            disabled: false,
            type: "textarea",
            label: `Motivo de la sesión (${sessionReason?.length || 0}/10000)`,
            placeholder: "...",
            name: "session_reason",
            validators: {
                maxLength: {
                    value: 10000,
                    message: "El comentario es demasiado largo",
                },
            },
        },
        patient_selector: {
            condition: true,
            type: "patient_selector",
            watch: watch,
            filter: {
                clinic_id: clinicId,
                per_page: 5,
                order: "name",
            },
            setValue: setValue,
            onClientSelect: (data) => {
                setValue("patient_id", data.patient_id);
                setValue("patient_name", data.name);
                setValue("patient_surnames", data.surnames);
                setValue("patient_email", data.email);
                setValue("patient_phone_number", data.phone_number);
            },
        }
    }

    return (
        <div className={"p-5 bg-base-100 rounded-lg"}>
            <h2 className="text-primary text-2xl">Solicitud Original</h2>

            <p className="lg:text-lg my-3">
                La información que el paciente ha proporcionado para la solicitud de la sesión se
                muestra a continuación.
            </p>

            <RegisterUnconfirmedSession
                eventData={originalValues}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                onlyDisplay={true}
            />

            <div className="flex justify-between mt-5">
                <h2 className="text-primary text-2xl">Registro de sesión</h2>

                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmitWithoutValidation(resetFetchedValues)}
                    disabled={isSubmitting}
                >
                    Reiniciar formulario
                </button>
            </div>

            <form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3"}>
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />
            </form>

            <div className="mt-5 flex space-x-2 sm:space-x-4">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit(
                        acceptUnconfirmedSession,
                        orgSessionId,
                        closeTooltip,
                    )}
                    disabled={isSubmitting}
                >
                    Aceptar
                </button>
                <button
                    className="btn btn-error btn-sm"
                    onClick={handleSubmitWithoutValidation(
                        rejectUnconfirmedSession,
                        orgSessionId,
                        closeTooltip
                    )}
                    disabled={isSubmitting}
                >
                    Rechazar
                </button>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={closeTooltip}
                    disabled={isSubmitting}
                >
                    Cerrar
                </button>
            </div>
        </div>
    )
}

export default EditUnconfirmedSession;