import axios from "axios";

import { isDefined } from "../auxiliar/formatValidators";

export const getAllPatients = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/patients/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getPatient = (patientId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(patientId)) {
			navigate(`/unknown?entity=patient&id=${patientId}`);
			return;
		}

		return axios
			.get(`/api/managers/patients/${patientId}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=patient&id=${patientId}`);
			});
	};
};

export const getPatientDiscount = (patientId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.get(`/api/managers/patients/${patientId}/discount`)
			.then((response) => {
				return response.data.content;
			})
			.catch(() => { });
	};
};

export const postPatient = (data, clinicId) => {
	data.discount = isDefined(data.discount) ? parseInt(data.discount) : 0;
	if (!isDefined(data.birth_date)) delete data.birth_date;
	if (!isDefined(data.dni)) delete data.dni;

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/patients/", {
				...data,
				clinic_id: clinicId,
			})
			.then((response) => {
				navigate(`/patient/${response.data.content.patient_id}`);
			})
			.catch(() => { });
	};
};

export const putPatient = (data, patientId, disableEdit) => {
	data.discount = isDefined(data.discount) ? parseInt(data.discount) : 0;
	if (!isDefined(data.birth_date)) delete data.birth_date;

	return async () => {
		return axios
			.put(`/api/managers/patients/${patientId}`, { new_info: data })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deletePatient = (patientId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/patients/${patientId}`)
			.then(() => {
				navigate("/patient");
			})
			.catch(() => { });
	};
};

export const downloadPatients = (params) => {
	return async () => {
		axios
			.get("/api/managers/data_export/patients", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				const href = URL.createObjectURL(response.data);

				// create "a" HTML element with href to file & click
				const link = document.createElement("a");
				link.href = href;
				link.setAttribute("download", "Pacientes.xlsx");
				document.body.appendChild(link);
				link.click();

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			});
	};
};
