import { isDefined } from "../../../auxiliar/formatValidators";

const WhatsAppReminder = ({ link }) => {
  if (!isDefined(link)) return null;

  return (
    <a href={link} target="_blank" className="mx-2">
      <img
        src={require("../../../media/whatsapp_icon.png")}
        height={20}
        alt={"WhatsApp icon"}
        className={"object-contain h-5 w-5 min-w-5 min-h-5"}
      />
    </a>
  );
};

export default WhatsAppReminder;
