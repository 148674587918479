import { useState } from "react";
import { useSelector } from "react-redux";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllSessions } from "../../../../actions/session";
import { isDefined } from "../../../../auxiliar/formatValidators";

const OverviewSessionsInAcquiredVoucher = ({ acquiredVoucherId }) => {
  const { id: activeClinicId } = useSelector((state) => state.activeClinic);
  const [sessions, setSessions] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    clinic_id: activeClinicId,
    acquired_voucher_id: acquiredVoucherId,
    order: "date",
    per_page: 5,
    page: 1,
  });

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllSessions,
    setSessions,
    setFilter
  );

  return (
    <div className="sm:container p-3 mx-auto my-5">
      <h2 className="text-primary text-2xl">Sesiones</h2>

      {sessions.waiting ? (
        <div className="flex items-center justify-center">
          <span className="loading loading-dots loading-lg" />
        </div>
      ) : !isDefined(sessions.order) ||
        !isDefined(sessions.content) ||
        sessions.order.length <= 0 ? (
        <p className="lg:text-lg my-3">
          No hay sesiones pagadas con este bono.
        </p>
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <table className="table">
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Fecha</th>
                <th className="hidden xs:table-cell">Horario</th>
                <th className="hidden md:table-cell">Fisioterapeuta</th>
                <th className="hidden sm:table-cell">Tipo</th>
              </tr>
            </thead>

            <tbody>
              {sessions.order.map((sessionId) => {
                const session = sessions.content && sessions.content[sessionId];

                return (
                  <tr
                    key={sessionId}
                    className={session.unpaid ? "bg-light-error" : ""}
                  >
                    <td>{session.patient_name}</td>
                    <td>{session.start_datetime.substring(0, 10)}</td>
                    <td className="hidden sm:table-cell">
                      {session.start_datetime.substring(11, 16)}
                      {"-"}
                      {session.finish_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden xs:table-cell sm:hidden">
                      {session.start_datetime.substring(11, 16)}
                    </td>
                    <td className="hidden md:table-cell">
                      {session.physio_name}
                    </td>
                    <td className="hidden sm:table-cell">{session.type}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-5 flex justify-between">
        <PaginatorComponent
          pagination={sessions.pagination}
          setFilter={setFilter}
          filter={filter}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OverviewSessionsInAcquiredVoucher;
