import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../../../../general/auxiliar/PaginatorComponent";
import WhatsAppReminder from "../../../../general/auxiliar/WhatsAppReminder";
import usePaginationFilterEffects from "../../../../../auxiliar/customHooks/usePaginationFilterEffects";
import { handleSubmit } from "../../../../../auxiliar/auxFunctions";
import { isDefined } from "../../../../../auxiliar/formatValidators";
import { getAllPatients } from "../../../../../actions/patient";

const OverviewPatientsInClass = ({
  classId,
  operation,
  maxParticipants,
  isSubmitting,
  onAddPatientClick,
  onCancelAddPatientClick,
  onDeletePatientFromClass,
  onPaymentClick
}) => {
  const navigate = useNavigate();

  const activeClinicId = useSelector((state) => state.activeClinic.id);

  const [patients, setPatients] = useState({ waiting: true });
  const [filter, setFilter] = useState({
    clinic_id: activeClinicId,
    group_session_id: classId,
    page: 1,
    per_page: 5,
    order: "name",
  });

  useEffect(() => {
    if (filter.group_session_id !== classId) {
      setFilter({
        clinic_id: activeClinicId,
        group_session_id: classId,
        page: 1,
        per_page: 5,
        order: "name",
      });
    } else if (
      filter.page > 1 &&
      patients?.order?.length === 1 &&
      !patients?.pagination?.has_next
    ) {
      setFilter({ ...filter, page: filter.page - 1 });
    }
  }, [classId, operation.update]);

  const disabled = usePaginationFilterEffects(
    filter,
    undefined,
    getAllPatients,
    setPatients,
    setFilter,
    operation.update
  );

  return (
    <>
      <div className="flex justify-between">
        <h4 className="text-primary text-lg mt-3">Asistentes ({!patients.waiting ? patients.order.length : "0"}/{maxParticipants})</h4>

        {(!patients.waiting && operation.operation !== "addPatient" && maxParticipants > patients.order.length) && <button
          className="btn btn-primary btn-sm mt-3"
          onClick={onAddPatientClick}
          disabled={isSubmitting}
        >
          Añadir asistente
        </button>}
        {operation.operation === "addPatient" && <button
          className="btn btn-primary btn-sm mt-3"
          onClick={onCancelAddPatientClick}
          disabled={isSubmitting}
        >
          Cancelar
        </button>}
      </div>
      <div className="mx-auto my-2">
        {patients.waiting ? (
          <div className="flex items-center justify-center">
            <span className="loading loading-dots loading-lg" />
          </div>
        ) : !isDefined(patients.order) ||
          !isDefined(patients.content) ||
          patients.order.length <= 0 ? (
          <p className="lg:text-lg my-3">
            No hay pacientes en esta clase.
          </p>
        ) : (
          <div className="overflow-x-visible overflow-y-visible">
            <table className="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th className="hidden md:table-cell">Correo</th>
                  <th className="hidden sm:table-cell">Teléfono</th>
                  <th className="hidden sm:table-cell">Deuda</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {patients.order.map((patientId) => {
                  const patient = patients.content && patients.content[patientId];

                  return (
                    <tr key={patientId}>
                      <td>{`${patient.name} ${patient.surnames}`}</td>
                      <td className="hidden md:table-cell">{patient.email}</td>
                      <td className="hidden sm:table-cell">
                        <div className="flex">
                          <span>{patient.phone_number}</span>
                          <WhatsAppReminder link={patient.link} />
                        </div>
                      </td>
                      <td className="hidden sm:table-cell">
                        {parseFloat(patient.debt) > 0 ? `${parseFloat(patient.debt).toFixed(2)}€` : "Pagado"}
                      </td>
                      <td className="text-center">
                        <div className="dropdown dropdown-end">
                          <div
                            tabIndex={0}
                            role="button"
                            className="btn btn-primary btn-sm"
                          >
                            Opciones{" "}
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className="text-base-100"
                            />
                          </div>
                          <ul
                            tabIndex={0}
                            className="dropdown-content z-40 menu p-2 shadow bg-base-300 rounded-box w-52"
                          >
                            <li
                              onClick={handleSubmit(
                                navigate,
                                `/patient/${patientId}`
                              )}
                            >
                              <a>Detalles</a>
                            </li>
                            {patient.unpaid && <li
                              onClick={onPaymentClick(patientId, patient)}
                            >
                              <a>Cobrar</a>
                            </li>}
                            <li
                              onClick={handleSubmit(onDeletePatientFromClass, { patient_id: patientId, recurrent: patient.recurrent })}
                            >
                              <a>Eliminar</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="mt-5 flex justify-end">
          <PaginatorComponent
            pagination={patients.pagination}
            setFilter={setFilter}
            filter={filter}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default OverviewPatientsInClass;
