import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";

import AppRouter from "./router/AppRouter";

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
