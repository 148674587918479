import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { handleSubmit } from "../../../auxiliar/auxFunctions";

const OptionSelector = ({ options }) => {
    return (
        <div className="flex items-center">
            <div className="flex space-x-1 rounded-lg bg-base-200 p-0.5" role="tablist" aria-orientation="horizontal">
                {
                    options && options.map((o) =>
                        <button
                            key={o.title}
                            className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 ${o.selected ? "bg-base-300 shadow" : ""}`}
                            id="headlessui-tabs-tab-:r1rg:"
                            role="tab"
                            type="button"
                            aria-selected={o.selected}
                            tabIndex={o.selected ? "0" : "-1"}
                            data-headlessui-state={o.selected ? "selected" : ""}
                            aria-controls="headlessui-tabs-panel-:r1ri:"
                            onClick={handleSubmit(o.onClick)}
                        >
                            {o.icon && <FontAwesomeIcon icon={o.icon} className="text-secondary" />}
                            {o.title && <span className="sr-only lg:not-sr-only lg:ml-2 text-primary">{o.title}</span>}
                        </button>
                    )
                }
            </div>
        </div>
    );
};

export default OptionSelector;
