import axios from "axios";
import { pick } from "lodash";

export const getAbsence = (id, dates) => {
	return async () => {
		return axios
			.get(`/api/managers/calendar_timespans/${id}`)
			.then((response) => {
				const data = response.data.content;

				return {
					...data,
					finish_datetime: dates?.finish_datetime
						? dates.finish_datetime
						: data.finish_datetime,
					start_datetime: dates?.start_datetime
						? dates.start_datetime
						: data.start_datetime,
				};
			});
	};
};

export const postAbsence = (
	data,
	closeTooltip,
) => {
	const absence = pick(data, ["clinic_id", "name", "description", "recurrent"]);

	let schedule = {}
	if (data.recurrent) {
		schedule = pick(data, ["repeat_monday", "repeat_tuesday", "repeat_wednesday", "repeat_thursday", "repeat_friday",
			"repeat_saturday", "repeat_sunday", "start_hour", "finish_hour", "first_date", "last_date"]);
	} else {
		schedule = pick(data, ["start_datetime", "finish_datetime"]);
	}

	const reqData = {
		...absence,
		...schedule,
		...data.employee_id === "all" ? {} : { employee_id: data.employee_id }
	};

	return async () => {
		return axios
			.post("/api/managers/calendar_timespans/", reqData)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const putAbsence = (
	data,
	id,
	closeTooltip,
) => {
	const reqData = {
		clinic_id: data.clinic_id,
		new_info: pick(data, ["name", "description", "recurrent", "start_datetime", "finish_datetime"])
	}

	return async () => {
		return axios
			.put(`/api/managers/calendar_timespans/${id}`, reqData)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const deleteAbsence = (
	id,
	closeTooltip,
	setUpdateDisabled,
	deleteRecurrent = false
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/calendar_timespans/${id}?deleteRecurrent=${deleteRecurrent}`
			)
			.then(() => {
				closeTooltip();
				setUpdateDisabled(false);
			})
			.catch(() => {
				setUpdateDisabled(false);
			});
	};
};
